import React from 'react'
import {Container} from 'react-bootstrap'

function ComparisonDashboard() {
  return (
    <div>
    <div className="headline home-colL">
      <span className="headline home-maing">Comparison Dashboard</span>
    </div>
    <div className="headline home-maing mt-5">
      <img
        src={process.env.PUBLIC_URL + '/assets/images/ComparisonDashboard.png'}
        alt="Catchy Headline"
        className="d-block w-100 features align-img"
      />
    </div>
    <Container className='mt-5' >
      <div>
        <span className="heading-blacksub3"> Our most powerful tool which allows you to analyze data against N # of ledgers/sub-ledgers eg. comparing income, expenses, assets, liabilities.</span>
      </div>
      </Container>
  </div>
  )
}

export default ComparisonDashboard