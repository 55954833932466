import axiosInstanceAdmin from "./axiosInstanceAdmin";

const api = axiosInstanceAdmin()

//function for getting all contacts
export const getCustomers =(abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/Customer`,config)
}

//function for getting a specific customer details
export const getCustomerById = (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.get(`/Customer/${id}`, config)
}

//function for updating a customer
export const updateCustomers = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.put(`/Customer/${id}`, data, config)
}

//function for getting all contacts
export const getCustomerSubscription =(id, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return api.get(`/UserSubscriptions?CustomerId=${id}`,config)
}

//function for updating a subscirption
export const updateSubscription = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.put(`/UserSubscriptions/${id}`, data, config)
}
