import axiosInstanceAdmin from "./axiosInstanceAdmin";

const api = axiosInstanceAdmin()

//function for getting all contacts
export const getContacts =(abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/ContactUs`,config)
}

//function for getting all feedbacks
export const getFeedback =(abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return api.get(`/Feedback`,config)
}