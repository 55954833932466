import React, { useState } from 'react'
import { Button, Col, Dropdown, Form, FormControl, Row, Spinner, Table } from 'react-bootstrap'
import { handleApiErrors } from '../../../handleApiErrors';
import { updateDashboardOrder } from '../../api/workspaceApi';
import { successToast } from '../../../HelperFile';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { dashboardIcons } from './Icons';
import { getIcon } from './GetIcon';

function SetOrder({ dashboards, closeModal, handleData }) {
    const abortConstants = useSignalAbort();
    const [isLoading, setIsLoading] = useState({});
    const [isSaving, setIsSaving] = useState(false)
    const [formErrors, setFormErrors] = useState([])
    const [orders, setOrders] = useState(dashboards || []);
    const [previousOrders, setPreviousOrders] = useState(dashboards || []);

    // Function for updating lead
    const setDashboardOrder = async (data) => {
        console.log(data);
        try {
            const result = await updateDashboardOrder(data, abortConstants.controllerConfig);
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message);
                handleData()
                closeModal()
            }
        } catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update', 'update_response', 'update_timeout', 'update_request', setIsLoading);
        }
    };

    const handleOrderChange = (index, value) => {
        let errors = [...formErrors];
        let errorMessage = "";

        if (!/^\d+$/.test(value)) {
            errorMessage = "Please enter a valid number.";
        } else if (Number(value) > dashboards?.length) {
            errorMessage = `Value should not exceed ${dashboards?.length}.`;
        } else if (Number(value) < 1) {
            errorMessage = `Value should not be below 1.`;
        }

        // Update the error for the specific input
        errors[index] = errorMessage;
        setFormErrors(errors);

        if (!errorMessage) {
            // Handle valid number case
            setPreviousOrders(orders)
            const numericValue = Number(value);
            const existingIndex = orders.findIndex(
                (item, idx) => idx !== index && item.dashboardOrder === numericValue
            );
            console.log("Existing index:", existingIndex); // Debug log

            if (existingIndex !== -1) {
                // Perform the swap if a duplicate value is found
                let updatedOrders = [...orders];
                console.log("Before swap:", updatedOrders); // Debug log

                // Perform the swap
                updatedOrders[existingIndex].dashboardOrder = previousOrders[index].dashboardOrder;
                updatedOrders[index].dashboardOrder = numericValue;

                console.log("After swap:", updatedOrders); // Debug log
                setOrders(updatedOrders.sort((a, b) => a.dashboardOrder - b.dashboardOrder));
            } else {
                // Simply update the order if no duplicate exists
                setOrders((prevOrders) =>
                    prevOrders.map((item, idx) =>
                        idx === index ? { ...item, dashboardOrder: numericValue } : item
                    ).sort((a, b) => a.dashboardOrder - b.dashboardOrder)
                );
            }
        } else {
            // Handle case when input is cleared or invalid
            setOrders((prevOrders) =>
                prevOrders.map((item, idx) =>
                    idx === index ? { ...item, dashboardOrder: "" } : item
                )
            );
        }
    };

    const handleIconChange = (index, value) => {
        setOrders((prevOrders) =>
            prevOrders.map((item, idx) =>
                idx === index ? { ...item, DashboardIcon: value } : item
            )
        );
    }

    const handleSubmit = () => {
        setIsSaving(true)
        const newDashboard = orders.map(item => ({
            ID: item.id,
            DashboardOrder: Number(item.dashboardOrder),
            DashboardIcon: item.DashboardIcon ? item.DashboardIcon : ''
        }));
        console.log(newDashboard);
        setDashboardOrder(newDashboard)
    }

    return (
        <>{orders &&
            <Row>
                <Col xs={12}>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th className="workspace-table heading workspace table width20">Order</th>
                                <th className="workspace-table heading workspace table width30">Icon</th>
                                <th className="table-style table-heading">Dashboard</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders?.map((item, index) => (
                                <tr key={index}>
                                    <td className="table-style table-font">
                                        <FormControl
                                            type="type"
                                            value={orders[index]?.dashboardOrder}
                                            onChange={(e) => handleOrderChange(index, e.target.value)}
                                        />
                                        {formErrors[index] && (
                                            <div className="form-error span-text">{formErrors[index]}</div>
                                        )}
                                    </td>
                                    <td className="table-style table-font">
                                        {/* <Form.Select aria-label="Category select menu" value={orders[index]?.DashboardIcon}  onChange={(e) => handleIconChange(index, e.target.value)}>
                                        <option>Select icon</option>
                                            {dashboardIcons.map((item)=>(
                                                 <optgroup key={item.id} label={item.category}>
                                                 {item.icons.map(icon=>(
                                                    <option key={icon.icon_id} value={icon.value}></option>
                                                 ))}
                                             </optgroup>
                                            ))}
                                        </Form.Select> */}
                                        <Dropdown>
                                            <Dropdown.Toggle style={{backgroundColor: 'white', color: '#303030', borderColor: '#303030', fontSize:'14px'}}>
                                                {item.DashboardIcon ? (
                                                    <>
                                                        {getIcon(item.DashboardIcon, '#303030')}
                                                    </>
                                                ) : (
                                                    "Select icon"
                                                )}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {dashboardIcons.map((item) => (
                                                    <div key={item.id}>
                                                        <Dropdown.Header>{item.category}</Dropdown.Header>
                                                        {item.icons.map((icon) => (
                                                            <Dropdown.Item
                                                                key={icon.icon_id}
                                                                onClick={() => handleIconChange(index, icon.value)}
                                                            >
                                                                {getIcon(icon.value, '#303030')}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </div>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {formErrors[index] && (
                                            <div className="form-error span-text">{formErrors[index]}</div>
                                        )}
                                    </td>
                                    <td className="table-style table-font">{item.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col xs={12}>
                    <div className='mt-3 d-flex justify-content-end' >
                        <Button
                            type={!isSaving ? 'submit' : null}
                            className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                            // disabled={isSaving}
                            onClick={() => handleSubmit()}
                        >
                            {isSaving ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className='me-2'>Saving...</span>
                                    <Spinner animation="border" variant="light" size='sm' />
                                </div>
                            ) : (
                                "Submit"
                            )}
                        </Button>
                        {!isSaving &&<Button className='button save mx-2' onClick={() => closeModal(false)}>
                            Cancel
                        </Button>}
                    </div>
                </Col>
            </Row>
        }</>
    )
}

export default SetOrder