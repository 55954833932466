import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'

function Why() {
    return (
        <Container fluid className=''>
            <Row className='home client-div'>
                <Row >
                    <Col xs={12}>
                        <div >
                            <span className="heading-black about-story title">
                                {" "}
                                Why Choose Us?
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12} sm={12} md={12} lg={6} >
                        <div className='headline home-subcontant about-card content'>
                            RCOR aims to provide agile and flexible services with maximum
                            efficiency by placing innovation at the heart of everything we
                            do. Our certified and qualified experts will help harness the
                            power of data management to establish authentic and
                            presentable financial statements. We commit to offering high-
                            quality services with the vision of benchmarking for support and
                            innovation, and to be able to provide a platform for
                            opportunities, growth, and entrepreneurial ideas.
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={6} className='d-flex justify-content-end' >
                        <Image src={process.env.PUBLIC_URL + "/assets/images/why.png"} rounded height={300} className='w-100' />
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}

export default Why