import React, { useState } from 'react'
import { Col, Form, Button, Row } from 'react-bootstrap'
import AddFollowup from './AddFollowup';

const initialRow ={
id:1,
date:"",
remarks:"",
status:"",
next_visit:""
}

function FollowupForm({isEdit, closeModal}) {
    const [rows, setRows] = useState([]);

    //function to add row for follow ups
    const addRow = (field) => {
        // const lastRow = rows[rows.length - 1];
        // Check if the last row's "name" field is empty or if it's the same as a previously added row
        // if (!lastRow || lastRow[field] === '') {
        //   alert('Please fill in all fields before adding a new row.');
        //   return;
        // }     
  
        const newRow = { id: rows.length + 1, date: field.date, remarks: field.remarks, status: field.status, next_visit: field.next_visit}; // Modify as needed
        setRows([...rows, newRow]);
    };

    //function to delete the added row for follow ups
    const deleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);

        // After filtering, reassign consecutive IDs to the rows
        const updatedRowsWithConsecutiveIDs = updatedRows.map((row, index) => ({
            ...row,
            id: index + 1,
        }));
        setRows(updatedRowsWithConsecutiveIDs);

    };


    return (
        <div >
            <Form className='form leadform'>
                <Row>
                    <Col xs={12} md={4} className=' '>
                        <Form.Group controlId='formBasicEmail'>
                            <Form.Label className='text-left' >Lead ID</Form.Label>
                            <Form.Control  type='type' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                    <Form.Group controlId='formBasicEmail'>
                            <Form.Label className='text-left' >Landed Date</Form.Label>
                            <Form.Control  type='type' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                    <Form.Group controlId='formBasicEmail'>
                            <Form.Label className='text-left' >Allocated Date</Form.Label>
                            <Form.Control  type='type' />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={4} >
                        <Form.Group  controlId="formName">
                        <Form.Label className='text-left' >Allocated To</Form.Label>
                            <Form.Select >
                                <option value="" disabled selected>Select</option>
                                <option value="1-10">Instagram</option>
                                <option value="11-50">LinkedIn</option>
                                <option value="51-200">Indeed</option>
                                <option value="201-500">Others</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} className=' '>
                        <Form.Group controlId='formBasicEmail'>
                            <Form.Label className='text-left' >Demo user ID</Form.Label>
                            <Form.Control  type='type' />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} className=' '>
                        <Form.Group controlId='formBasicEmail'>
                            <Form.Label className='text-left' >Demo valid to</Form.Label>
                            <Form.Control  type='type' />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12}>
                        <span>Follow Ups</span>
                    </Col>
                </Row>
                <Row>
                   <AddFollowup rows={rows} setRows={setRows} addRow={addRow} deleteRow={deleteRow}/> 
                </Row>
                <div className='pt-2 d-flex justify-content-end' >
                    <Button  type="submit" className='button save'>
                        Submit
                    </Button>
                    <Button  className='button save mx-2' onClick={()=>closeModal(false)}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </div>
    )
}

export default FollowupForm