import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

function OurCore() {
    return (
        <div>
            <Container fluid className="caption style-card mt-5">
                <Row >
                    {/* .....................#Feature name #1#............................. */}
                    <Col xs={12} className="d-flex justify-content-center ">
                        <div className="mb-5">
                            <span className="heading-black">
                                {" "}
                                Our Core Values
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Container fluid>
                        <Row className="justify-content-center home feature-card" >
                            {/* .....................#Feature name #1#............................. */}
                            <Col xs={12} sm={12} lg={3} >
                                <div className="caption home-style about-card content-height">
                                    <div>
                                        <div className="d-flex flex-column align-items-center">
                                            <div>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/innovation.png"}
                                                    alt="Core value"
                                                    className="headline home-img3"
                                                />
                                            </div>
                                            <span className="caption Text-styleCard text-center mt-2">
                                            Innovation
                                            </span>
                                        </div>
                                        <div className="headline home-subcontant2 about-card content p-1 home feature-linespace" >
                                            <span>
                                                At RCOR, we believe that innovation is
                                                the key to staying ahead in the
                                                dynamic world of technology. We
                                                continuously seek new ideas and
                                                embrace cutting-edge technologies
                                                to develop solutions that not only
                                                meet but exceed our clients'
                                                expectations.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            {/* .....................#Feature name #2#............................. */}
                            <Col xs={12} sm={12} lg={3} className="headline home-colC">
                                <div className="caption home-style about-card content-height">
                                    <div >
                                        <div className="d-flex flex-column align-items-center">
                                            <div>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/quality.png"}
                                                    alt="Core value"
                                                    className="headline home-img3"
                                                />
                                            </div>
                                            <span className="caption Text-styleCard text-center mt-2">
                                            Quality
                                            </span>
                                        </div>
                                        <div className="headline home-subcontant2 about-card content p-1 home feature-linespace">
                                            <span>
                                                We are dedicated to delivering
                                                superior software solutions that are
                                                reliable, efficient, and scalable. Our
                                                rigorous quality assurance processes
                                                ensure that every product we develop
                                                meets the highest standards of
                                                excellence, providing our clients with
                                                robust and seamless experiences.

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* .....................#Feature name #3#............................. */}
                            <Col xs={12} sm={12} lg={3} className="headline home-colC">
                                <div className="caption home-style about-card content-height">
                                    <div >
                                        <div className="d-flex flex-column align-items-center">
                                            <div>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/collaboration.png"}
                                                    alt="Core value"
                                                    className="headline home-img3"
                                                />
                                            </div>
                                            <span className="caption Text-styleCard text-center mt-2">
                                            Collaboration
                                            </span>
                                        </div>

                                        <div className="headline home-subcontant2 about-card content p-1 home feature-linespace">
                                            <span>
                                                We understand that the best results
                                                come from working together. By
                                                partnering closely with our clients, we
                                                ensure that their vision is realized
                                                through a cohesive and cooperative
                                                approach, leading to successful and
                                                impactful outcomes.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            {/* .....................#Feature name #3#............................. */}
                            <Col xs={12} sm={12} lg={3} className="headline home-colC">
                                <div className="caption home-style about-card content-height">
                                    <div >
                                        <div className="d-flex flex-column align-items-center">
                                            <div>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/integrity.png"}
                                                    alt="Core value"
                                                    className="headline home-img3"
                                                />
                                            </div>
                                            <span className="caption Text-styleCard text-center mt-2">
                                            Integrity
                                            </span>
                                        </div>

                                        <div className="headline home-subcontant2 about-card content p-1 home feature-linespace">
                                            <span>
                                                We conduct our operations with
                                                honesty, transparency, and respect
                                                for all stakeholders. Our commitment
                                                to ethical practices ensures that we
                                                build trust with our clients, partners,
                                                and employees, creating long-lasting
                                                relationships based on mutual
                                                respect and reliability.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </div>
    );
}

export default OurCore;
