import axiosInstanceAdmin from "./axiosInstanceAdmin";

let api = axiosInstanceAdmin()

export const getUserType =(abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/UserType`, config)
}

export const getUserRole =(id, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/UserType/${id}`, config)
}

export const createUserType =(roleData,abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.post(`/UserType`, roleData, config)
}

export const updateUserType =(id, roleData, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.put(`/UserType/${id}`, roleData, config)
}
export const deleteUsertype =(id, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.delete(`/UserType/${id}`, config)
}
export const getCompany =(token)=>{
    return api.get(`/UserType`)
}

export const getDesignation =(abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/designation`, config)
}

export const getUserById =(id, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/user/${id}`, config)
}

export const getUsers =(abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/user`, config)
}

export const createUser =(data, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.post(`/user`, data, config)
}

export const updateUser =(id, data, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.put(`/user/${id}`, data)
}

export const deleteUser =(id, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.delete(`/user/${id}`, config)
}

export const getActions =(id, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/Actions/${id}`, config)
}


export const changePassword =(data, token)=>{
    return api.post(`/ChangePassword`, data)
}

//get last update
export const getLastUpdateDate =(token)=>{
    return api.get(`/LastUpdate`)
}

//get menu
export const getUserMenu =(userTypeId, token)=>{
    return api.get(`/SubMenu/Permitted/${userTypeId}`)
}

//logout
export const logout =(userId)=>{
    return api.post(`/Logout/${userId}`)
}