import React, { useEffect, useState } from 'react';
import { Col, Row, Accordion, Table, Badge, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getCustomerById, getCustomerSubscription, updateSubscription } from '../../api/customerApi';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { convertDateForDisplay, successToast } from '../../../HelperFile';
import { handleApiErrors } from '../../../handleApiErrors';
import ExtendForm from './ExtendForm';
import UpgradeForm from './UpgradeForm';
import { extend } from 'dayjs';

function DetailForm({ id, isEdit, closeModal, getData }) {
    const abortConstants = useSignalAbort();
    const [showToast, setShowToast] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [customerData, setCustomerData] = useState();
    const [subscriptions, setSubscriptions] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState({});

    const toggleToast = () => setShowToast(!showToast);

    const getSubscriptions = async (id) => {
        try {
            const result = await getCustomerSubscription(id, abortConstants.controllerConfig);
            console.log(result);
            setSubscriptions(result?.data?.UserSubscriptions);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSubscriptions(id);
    }, [id]);

    useEffect(() => {
        if (isEdit) {
            const getCustomer = async () => {
                try {
                    const result = await getCustomerById(id, abortConstants.controllerConfig);
                    console.log(result);
                    setCustomerData(result?.data?.Customer);
                } catch (error) {
                    handleApiErrors(error, 'Customer', 'customer_response', 'customer_timeout', 'customer_request');
                }
            };
            getCustomer();
        }
    }, [id, isEdit]);

        //for generating key
        const generateKey = async (subscription) => {
            console.log(subscription);
            const data ={
                CustomerId: subscription.CustomerId,
                PlanId: subscription.PlanId,
                Duration: subscription.Duration,
                IsGenerateNewKey:1
            }
            console.log(data);
            try {
                const result = await updateSubscription(subscription.SubscriptionId, data,  abortConstants.controllerConfig)
                console.log(result);
                if (result) {
                    setIsSaving(false)
                    successToast(result?.data?.message)
                    setSubscriptions(result?.data?.UserSubscriptions);
                }
            }
            catch (error) {
                setIsSaving(false)
                handleApiErrors(error, 'Generate Key', 'duration_response', 'duration_timeout', 'duration_request', setIsLoading)
            }
        }

    const handleFormSelection = (formType, item) => {
        setSelectedForm(formType);
        setSelectedItem(item);
        toggleToast();
    };

    return (
        <div>
            <div className='form leadform'>
                <Row>
                    {/* Customer Details */}
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Name</span></Col>
                            <Col xs={12}><span>{customerData?.Name}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Phone</span></Col>
                            <Col xs={12}><span>{customerData?.PhoneNumber}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Company Name</span></Col>
                            <Col xs={12}><span>{customerData?.CompanyName}</span></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Email ID</span></Col>
                            <Col xs={12}><span>{customerData?.EmailId}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Company Size</span></Col>
                            <Col xs={12}><span>{customerData?.CompanySize}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Website</span></Col>
                            <Col xs={12}><span>{customerData?.Website}</span></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>City</span></Col>
                            <Col xs={12}><span>{customerData?.City}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Zipcode</span></Col>
                            <Col xs={12}><span>{customerData?.ZipCode}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Street</span></Col>
                            <Col xs={12}><span>{customerData?.Street}</span></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Industry</span></Col>
                            <Col xs={12}><span>{customerData?.IndustryName}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Subscription Status</span></Col>
                            <Col xs={12}><span>{customerData?.SubscriptionStatus}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Row>
                            <Col xs={12}><span className='label form-label'>Subdomain</span></Col>
                            <Col xs={12}><span>{customerData?.SubDomain}</span></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center mt-2'>
                    <Toast onClose={toggleToast} show={showToast}>
                        <Toast.Header>
                            <strong className="me-auto">{selectedForm === 'extend' ?'Extend Plan': 'Updrade Plan'}</strong>
                        </Toast.Header>
                        <Toast.Body>
                            {selectedForm === 'extend' && <ExtendForm subscriptionDetails={selectedItem} getData={getSubscriptions} closeModal={toggleToast} updateTable={getData}/>}
                            {selectedForm === 'upgrade' && <UpgradeForm subscriptionDetails={selectedItem} getData={getSubscriptions} closeModal={toggleToast} updateTable={getData}/>}
                        </Toast.Body>
                    </Toast>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Subscription</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <Row>
                                            <Col xs={12}>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th className="table-style table-heading">Start Date</th>
                                                            <th className="table-style table-heading">End Date</th>
                                                            <th className="table-style table-heading">Duration</th>
                                                            <th className="table-style table-heading">Licence Key</th>
                                                            <th className="table-style table-heading">Status</th>
                                                            <th className="table-style table-heading">Plan</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {subscriptions?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="table-style table-font">{convertDateForDisplay(item.StartDate)}</td>
                                                                <td className="table-style table-font">{convertDateForDisplay(item.EndDate)}</td>
                                                                <td className="table-style table-font">
                                                                    {item.Duration}&nbsp;{item.BillingCycle}&nbsp;
                                                                    {item.Name === 'Free' && item.Status === 'Active' && (
                                                                        <Badge className='common-icon' onClick={() => handleFormSelection('extend', item)} pill bg="primary">Extend</Badge>
                                                                    )}
                                                                </td>
                                                                <td className="table-style table-font">
                                                                    {item.LicenceKey}&nbsp;
                                                                    {item.Status === 'Active' && !isSaving && (
                                                                        <Badge className='common-icon' onClick={() => {setIsSaving(true); generateKey(item)}} pill bg="primary">Generate</Badge>
                                                                    )}
                                                                    </td>
                                                                <td className="table-style table-font">
                                                                    <Badge pill bg={item.Status === 'Active' ? "success" : "danger"}>{item.Status}</Badge>
                                                                </td>
                                                                <td className="table-style table-font">
                                                                    {item.Name}&nbsp;
                                                                    {item.Name === 'Free' && item.Status === 'Active' && (
                                                                        <Badge className='common-icon' onClick={() => handleFormSelection('upgrade', item)} pill bg="primary">Upgrade</Badge>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default DetailForm;
