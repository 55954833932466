import React from 'react'
import {Container} from 'react-bootstrap'


function CashAndBank() {
  return (
    <div>
    <div className="headline home-colL">
      <span className="headline home-maing">Cash & Bank Report</span>
    </div>
    <div className="headline home-maing mt-5">
      <img
        src={process.env.PUBLIC_URL + '/assets/images/CashAndBank.png'}
        alt="Catchy Headline"
        className="d-block w-100 features align-img"
      />
    </div>
    <Container className='mt-5' >
      <div>
        <span className="heading-blacksub3">Provides cash and bank transactions reports.</span>
      </div>
      </Container>
  </div>
  )
}

export default CashAndBank