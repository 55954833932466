import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Button, Form, Spinner } from "react-bootstrap";
import { getMenu, login } from '../api/authApi';
import { useSignalAbort } from '../../api/useSignalAbort';
import { handleApiErrors } from '../../handleApiErrors';
import { useNavigate } from 'react-router-dom';
import { setMenu, setToken, setUser } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import MainHome from './MainHome';

function Login() {
    const token = localStorage.getItem("intelliger_admin_token")
    const abortConstants = useSignalAbort()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [islogin, setIsLogin] = useState(false)
    const [isLoading, setIsLoading] = useState({})

    // useEffect(() => {
    //     if (token) {
    //         window.location.href = '/Admin_Home'
    //     }
    // }, [])

    const userlogin = async (userData) => {
        try {
            const response = await login(userData, abortConstants.controllerConfig)
            if (response.data?.token) {
                // localStorage.setItem("intelliger_admin_token", response.data?.token)
                // dispatch(setToken({ adminToken: response?.data?.token }))
                // dispatch(setUser({ adminUser: response?.data?.user }))
                getAllMenu(response.data?.user.usertypeid, response.data?.token, response?.data?.user)
                setIsLogin(false)
            }
        }
        catch (error) {
            setIsLogin(false)
            handleApiErrors(error, 'Login', 'login_response', 'login_timeout', 'login_request', setIsLoading)
        }
    }

    //function to get all menu permitted for logged in user
    const getAllMenu = async (userId, token, user) => {
        console.log(userId, token);
        try {
            const response = await getMenu(userId, token, abortConstants.controllerConfig)
            console.log(response);
            if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
                localStorage.setItem("intelliger_admin_token", token)
                dispatch(setToken({ adminToken: token }))
                dispatch(setUser({ adminUser: user }))
                dispatch(setMenu({ userMenu: response?.data?.SubMenu }));
                setIsLogin(false)
                 window.location.href = '/Admin_Home'
                // navigate('/Admin_Home')
            }
        }
        catch (error) {
            setIsLogin(false)
            handleApiErrors(error, 'Menu', 'menu_response', 'menu_timeout', 'menu_request', setIsLoading)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLogin(true)
        let userData = {
            "Username": username,
            "Password": password,
        }
        userlogin(userData)
    }


    return (
        <div className='d-flex align-items-center justify-content-center vh-100 login bg-image'>
            {token ?
                <MainHome /> :
                <Row >
                    <Col xs={12}>
                        {/* Creating a Card */}
                        <Card className='card'>

                            <Card.Body className='card-body'>

                                <Card.Title className='text-center' style={{ color: '#489C8F' }}>Sign in</Card.Title>
                                <Card.Text className='mt-3 text-center'>
                                    To Access RCOR Intelliger Admin Panel
                                </Card.Text>
                                <Form onSubmit={handleSubmit}>
                                    {/* Adding form controls with headings */}
                                    <Form.Group controlId='formBasicEmail'>
                                        <Form.Label className='text-left'>Username</Form.Label>
                                        <Form.Control required className='textfield' type='text' value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId='formBasicPassword'>
                                        <Form.Label className=' mt-3 text-left'>Password</Form.Label>
                                        <Form.Control required className='textfield' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="mt-4 d-flex align-items-center">
                                        <Form.Check type='checkbox' id='rememberPassword' className='me-2' />
                                        <Form.Label className='mb-0 me-3 '  >Remember Me</Form.Label>
                                    </Form.Group>

                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            {!islogin && <Button type='submit' className='login login-button mt-4 mb-4'>LOGIN</Button>}
                                            {islogin &&
                                                <Button className='button button-disable d-flex justify-content-center align-items-center mt-4 mb-4' >
                                                    <span className='me-2'>Signing</span>
                                                    <Spinner animation="border" variant="light" size='sm' />
                                                </Button>}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default Login