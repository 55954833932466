import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'

function OurMission() {
  return (
    <Container fluid >
      <Row className='home client-div'>
        <Row >
          <Col xs={12}>
            <div >
              <span className="heading-black about-story title">
                {" "}
                Our Mission
              </span>
            </div>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={12} sm={12} md={12} lg={6} >
            <div className='headline home-subcontant about-card content'>
              Our mission is to help organizations manage their business
              operations efficiently through cutting-edge software solutions.
              Our culture is built on the values of collaboration, innovation,
              and excellence. We prioritize diversity, inclusion, and equal
              opportunity for our employees. We invest in ongoing training
              and development to ensure the highest quality of work and a
              commitment to exceptional customer service.
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={6} className='d-flex justify-content-end' >
            <Image src={process.env.PUBLIC_URL + "/assets/images/mission.png"} rounded height={300} className='w-100' />
          </Col>
        </Row>
      </Row>
    </Container>
  )
}

export default OurMission