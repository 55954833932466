import axiosInstanceAdmin from "./axiosInstanceAdmin";

const api = axiosInstanceAdmin()

//function for getting all leads
export const getLead = (abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.get(`/Leads`, config)
}

//function for getting a specific lead
export const getLeadById = (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.get(`/Leads/${id}`, config)
}

//function for creating a lead
export const createLead = (data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.post(`/Leads`, data, config)
}

//function for updating a lead
export const updateLead = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.put(`/Leads/${id}`, data, config)
}

//function for updating a lead
export const deleteLead = (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.delete(`/Leads/${id}`,config)
}

//function to get all industry
export const getIndustry = async (abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/Industry`, config)
}

//function to get all status
export const getstatus = async (abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/LeadStatus`, config)
}

//function to getting lead note by criteria
export const getLeadNoteByCriteria = async (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/LeadNote/ByCriteria/${id}`, config)
}

//function for creating a lead note
export const createLeadNote = (data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.post(`/LeadNote`, data, config)
}

//function for updating a lead note
export const updateLeadNote = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.put(`/LeadNote/${id}`, data ,config)
}

//function for deleting a lead note
export const deleteLeadNote = (id, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return api.delete(`/LeadNote/${id}`,config)
}

//function for updating a lead note
export const convertLead = (id, data, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 60000, // Set the timeout for this specific request
  };
  return api.put(`/Leads/Convert/${id}`, data ,config)
}

//function to getting lead note by criteria
export const subDomainValidity = async (domain, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/SubDomain/Availability/${domain}`, config)
}

//function to getting plans for lead
export const getPlans = async (abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/SubscriptionPlan`, config)
}