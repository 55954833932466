import React from 'react'
import { Button, Modal } from 'react-bootstrap';

function ModalPopup(props) {
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        <span className='modal-title'>{props.title}</span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='m-2'>
      {props.form}
    </Modal.Body>
    {/* <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer> */}
  </Modal>
  )
}

export default ModalPopup