import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

function InvalidDomain() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hostName = queryParams.get('url');
    return (
        <div>
            <Header/>
            <div className='d-flex flex-column align-items-center justify-content-center vh-100 invalid-page bg'>
                {/* Centering the content vertically and horizontally */}
                <Row style={{marginTop: -120}}>
                    <Col xs={12} className='d-flex flex-column'>
                        {/* <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' width={121} height={45} /> */}
                        <div className='mt-1 invalid-page span-main-text'>We couldn't locate the Domain <span className='invalid-page span-main-text sub'>{hostName}</span></div><br />
                        <div>
                            <div className='mt-1 invalid-page span-sub-text'>This might be because : </div><br />
                            <div>
                                <ul>
                                    <li className='mb-2'><span className='invalid-page span-sub-text sub'>Incorrect URL :</span> Verify that the address you entered is correct.</li>
                                    <li className='mb-2'><span className='invalid-page span-sub-text sub'>Domain has expired :</span> If you are the owner, please reach out to our support team.</li>
                                    <li className='mb-2'><span className='invalid-page span-sub-text sub'>Domain not registered :</span> This domain may not be set up yet.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer/>
        </div>
    )
}

export default InvalidDomain