import React from 'react'
import SideMenu from '../components/SideMenu'
import Header from '../components/Header'
import { Outlet } from 'react-router-dom';
function MainPage() {
  return (
    <div style={{maxWidth: '1920px'}} >
      <div>
        <Header />
      </div>
      <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 200px', overflowY: 'auto' }}> {/* Fixed width for the side menu with vertical scrolling */}
          <SideMenu />
        </div>
        <div className='p-4' style={{ flex: '1', overflowY: 'auto' }}> {/* Allow the outlet to take up remaining space with vertical scrolling */}
          <Outlet />
        </div>
      </div>

    </div>
  )
}

export default MainPage