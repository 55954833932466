import axiosInstance from "./axiosInstance";

let api = axiosInstance()


export const DropUsMessage = async(data, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.post(`/ContactUs`, data, config)
  }
  