import axiosInstanceAdmin from "./axiosInstanceAdmin";

const api = axiosInstanceAdmin()

//function for getting all blogs
export const getBlog = (abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/Blogs`, config)
  }
  
  //function for getting a specific blog
  export const getBlogById = (id, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/Blogs/${id}`, config)
  }
  
  //function for creating a blog
  export const createBlog = (data, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.post(`/Blogs`, data, config)
  }
  
  //function for updating a blog
  export const updateBlog = (id, data, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.put(`/Blogs/${id}`, data, config)
  }
  
  //function for deleting a lead
  export const deleteBlog = (id, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.delete(`/Blogs/${id}`,config)
  }
  