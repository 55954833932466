import React from 'react'
import {Container} from 'react-bootstrap'

function DayBook() {
  return (
    <div>
    <div className="headline home-colL">
      <span className="headline home-maing">Day Book Report</span>
    </div>
    <div className="headline home-maing mt-5">
      <img
        src={process.env.PUBLIC_URL + '/assets/images/DayBook.png'}
        alt="Catchy Headline"
        className="d-block w-100 features align-img"
      />
    </div>
    <Container className='mt-5' >
      <div>
        <span className="heading-blacksub3">Providing an overview of financial activities on a day-to-day basis.</span>
      </div>
      </Container>
  </div>
  )
}

export default DayBook