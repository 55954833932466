import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
axios.defaults.mode='cors'
 
const API_URL = process.env.REACT_APP_BASE_URL;
function navigateToPage() {
  const newPageURL = '/logout'; // Replace with the URL of the page you want to navigate to
  window.location.href = newPageURL;
}
 
const axiosInstanceAdmin = () => {
  const token = localStorage.getItem("intelliger_admin_token")
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    withCredentials: false,
  });
 
  // Add an interceptor to set the 'Authorization' header for all requests
  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });
 
   // Add an interceptor to capture and handle response errors
   instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (token && error.response.status === 401) {
          console.log(error);
          navigateToPage()
        }
 
      } else if (error.request) {
        console.error('Server error:', error);
 
      } else {
        // console.error('Error:', error.message);
      }
      return Promise.reject(error);
    });
 
  return instance;
};
 
export default axiosInstanceAdmin;