import React, { useEffect, useState } from 'react'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { createUserType, getActions, getUserRole, updateUserType } from '../../api/userApi'
import { Button, Col, Row, Collapse, Form, Spinner } from 'react-bootstrap'
import PermittedActions from './PermittedActions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { toast } from 'react-toastify'
import { successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'

// let validActions = []
function RoleForm({ setIsNewRole, roleId, isEdit, getData }) {
  const abortConstants = useSignalAbort()
  const [actions, setActions] = useState()
  const [mainMenu, setMainMenu] = useState()
  const [roleName, setRoleName] = useState()
  const [isActive, setIsActive] = useState(1)
  const [editActions, setEditActions] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState({})

  const [openIndex, setOpenIndex] = useState(null);
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const getAllActions = async () => {
      try {
        const result = await getActions(roleId, abortConstants.controllerConfig)
        console.log(result);
        setActions(result.data.Actions)
        setMainMenu([...new Map(result.data.Actions?.map(item =>
          [item['menu_id'], item])).values()].sort((a, b) => a.menu_order - b.menu_order));
      }
      catch (error) {
        handleApiErrors(error, 'Get Actions', 'getactions_response', 'getactions_timeout', 'getactions_request', setIsLoading)
      }
    }
    const getUserRoleById = async () => {
      try {
        const result = await getUserRole(roleId, abortConstants.controllerConfig)
        console.log(result);
        setRoleName(result.data.UserType.usertypename)
        setIsActive(result.data.UserType.isactive)
        setEditActions(result.data.UserType.permitted_actions.filter(item => item.isPermit === 1).map(element => element.actions_id))
      }
      catch (error) {
        console.log(error);
      }
    }
    getAllActions()
    if (isEdit) {
      getUserRoleById()
    }
  }, [roleId, isEdit])

  const handleChange = (e, id) => {
    if (e.target.checked) {
      // If the checkbox is checked and the action ID is not in the array, add it
      if (!editActions.includes(id)) {
        setEditActions([...editActions, id]);
      }
    } else {
      // If the checkbox is unchecked, remove the action ID from the array
      setEditActions(editActions.filter(actionId => actionId !== id));
    }
    console.log(editActions);

  };

  const createRole = async (roleData) => {
    try {
      const result = await createUserType(roleData, abortConstants.controllerConfig)
      console.log(result);
      setIsSaving(false)
      setIsNewRole(false)
      successToast(result.data.message)
      getData()
    }
    catch (error) {
      setIsSaving(false)
      handleApiErrors(error, 'Add User', 'adduser_response', 'adduser_timeout', 'adduser_request', setIsLoading)
    }
  }

  const updateRole = async (roleData) => {
    try {
      console.log(roleData);
      const result = await updateUserType(roleId, roleData, abortConstants.controllerConfig)
      console.log(result);
      setIsSaving(false)
      setIsNewRole(false)
      successToast(result.data.message)
      getData()
    }
    catch (error) {
      setIsSaving(false)
      handleApiErrors(error, 'Update Role', 'editrole_response', 'editrole_timeout', 'editrole_request', setIsLoading)
    }
  }

  const handleSubmit = () => {
    setError(false)
    if (roleName) {
      setIsSaving(true)
      const stringActions = editActions.map(num => num.toString());
      const roleData = {
        "usertypename": roleName,
        "Actions": stringActions,
        "isactive": isActive,
        "createdby": 1,
      }
      console.log(roleData);

      if (editActions.length !== 0) {
        if (isEdit) {
          updateRole(roleData)
        } else {
          createRole(roleData)
        }
      } else {
        toast.error("Set Permissions", {
          autoClose: 3000
        })
      }
    } else {
      setError(true)
    }
  }

  return (
    <div>
      <Row>
        <Col xs={6}>
          <h5>Role Management</h5>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Button className='button save' onClick={() => setIsNewRole(false)}>View Roles</Button>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col xs={12}>
          <Form.Group controlId='name'>
            <Form.Label className='text-left label form-label' >Role Name<span className='asterik'> *</span></Form.Label>
            <Form.Control maxLength={100} required size='sm' type='type' name='rolename' value={roleName} onChange={(e) => { setRoleName(e.target.value) }} />
            {error && <span className='form-error span-text'>Role name reuired.</span>}
          </Form.Group>
        </Col>
      </Row>
      {mainMenu?.map((item, index) => (
        <Row key={index} className='mt-2'>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center common-icon actions action-div" onClick={() => handleToggle(index)}>
              {/* <Button
                variant="link"
                onClick={() => handleToggle(index)}
                aria-controls={`collapse-text-${index}`}
                aria-expanded={openIndex === index}
                className="text-start"
              >
                {item.menu_name}
              </Button> */}
              <span>{item.menu_name}</span>
              {openIndex === index ? (
                <FaChevronUp onClick={() => handleToggle(index)} />
              ) : (
                <FaChevronDown onClick={() => handleToggle(index)} />
              )}
            </div>
            <Collapse in={openIndex === index}>
              <div id={`collapse-text-${index}`} className="mt-2">
                <PermittedActions subMenu={actions?.filter(element => element.menu_id === item.menu_id)} handleChange={handleChange} editActions={editActions} />
              </div>
            </Collapse>
          </Col>
        </Row>
      ))}
      <Row className='mt-2'>
        <Col xs={12}>
          <Form.Group controlId='active'>
            <Form.Label className='text-left label form-label' >Is Active<span className='asterik'> *</span></Form.Label>
            <Form.Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked? 1 :0)} />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className='mt-3 d-flex justify-content-end' >
        <Button
          type={!isSaving ? 'submit' : null}
          className={`${isSaving ? 'button-loading disable' : 'button save'}`}
          // disabled={isSaving}
          onClick={() => handleSubmit()}
        >
          {isSaving ? (
            <div className="d-flex justify-content-center align-items-center">
              <span className='me-2'>Saving...</span>
              <Spinner animation="border" variant="light" size='sm' />
            </div>
          ) : (
            "Submit"
          )}
        </Button>
        <Button className='button save mx-2' onClick={() => setIsNewRole(false)}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default RoleForm