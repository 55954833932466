import axiosInstanceAdmin from "./axiosInstanceAdmin";

const api = axiosInstanceAdmin()

//function for getting all promocodes
export const getPromo = (abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/Discount`, config)
  }
  
  //function for getting a specific blog
  export const getPromoById = (id, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.get(`/Discount/${id}`, config)
  }
  
  //function for creating a blog
  export const createPromo = (data, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.post(`/Discount`, data, config)
  }
  
  //function for updating a blog
  export const updatePromo = (id, data, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.put(`/Discount/${id}`, data, config)
  }
  
  //function for deleting a lead
  export const deletePromo = (id, abortToken) => {
    const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
    return api.delete(`/Discount/${id}`,config)
  }
  