import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spinner, Pagination } from 'react-bootstrap';
import CrudActions from '../../components/CrudActions';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { deleteLead, getLead } from '../../api/leadsApi';
import { useSelector } from 'react-redux';
import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';
import Filter from '../../components/Filter';
import { getUsers } from '../../api/userApi';
import { deleteBlog, getBlog } from '../../api/blogApi';
import ModalPopup from '../../components/ModalPopup';
import { Link } from 'react-router-dom';
import PromocodeForm from './PromocodeForm';
import { deletePromo, getPromo } from '../../api/promoApi';

const initialFilterValues = {
    Name: '',
    // CompanyName: '',
    // IndustryName: '',
    // PhoneNumber: '',
    // EmailId: '',
    Date: '',
    // SourceName: '',
    Status: ''
}
function PromoCode() {
    const abortConstants = useSignalAbort();
    const user = useSelector(state => state.adminUser);
    const menu = useSelector(state => state.userMenu);
    const [promocodes, setPromocodes] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const leadsPerPage = 13; // Adjust this number to set how many leads you want per page
    const [filters, setFilters] = useState(initialFilterValues);
    const [modalShow, setModalShow] = useState(false);
    const [content, setContent] = useState();

    // Function to get all leads
    const getAllPromocode = async () => {
        setIsLoading(true);
        try {
            const response = await getPromo(abortConstants.controllerConfig);
            console.log(response);
            setPromocodes(response?.data?.Discount);
            setIsLoading(false);
        } catch (error) {
            handleApiErrors(error, 'Promocode', 'promo_response', 'promo_timeout', 'promo_request', setIsLoading);
        }
    };

    useEffect(() => {
        getAllPromocode();
    }, []);

    // Function for deleting lead
    const deletePromoCode = async (id) => {
        try {
            const result = await deletePromo(id, abortConstants.controllerConfig);
            if (result) {
                successToast(result?.data?.message);
                getAllPromocode();
            }
        } catch (error) {
            handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
        }
    };

    const handleFilterChange = (e, columnName) => {
        setFilters({
            ...filters,
            [columnName]: e.target.value
        });
    };

    const clearFilter = (columnName) => {
        setFilters({
            ...filters,
            [columnName]: ''
        });
    };

    // const getFilteredLeads = () => {
    //   return leads.filter(lead => {
    //     // const leadDate = new Date(lead.CreatedDate).toISOString().split('T')[0];
    //     return (
    //       (filters.Name === '' || lead.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
    //       // (filters.CompanyName === '' || lead.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase())) &&
    //       // (filters.IndustryName === '' || lead.IndustryName.toLowerCase().includes(filters.IndustryName.toLowerCase())) &&
    //       // (filters.PhoneNumber === '' || lead.PhoneNumber.includes(filters.PhoneNumber)) &&
    //       // (filters.EmailId === '' || lead.EmailId.toLowerCase().includes(filters.EmailId.toLowerCase())) &&
    //       (filters.Date === '' || convertDateForDisplay(lead.CreatedDate) === convertDateForDisplay(filters.Date)) &&
    //       // (filters.SourceName === '' || lead.SourceName.toLowerCase().includes(filters.SourceName.toLowerCase())) &&
    //       (filters.Status === '' || lead.StatusName.toLowerCase().includes(filters.Status.toLowerCase()))
    //     );
    //   });
    // };

    // Get current leads
    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;
    const currentLeads = promocodes?.slice(indexOfFirstLead, indexOfLastLead);

    // console.log(leads.filter(item =>convertDateForDisplay(item.CreatedDate) === '15-05-2024'));
    // Change page
    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(promocodes?.length / leadsPerPage)));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const displayContent = (item) => {
        setContent(item)
        setModalShow(true)
    };

    return (
        <div>
            <Row>
                <Col xs={6}>
                    <h5>Promo Code Management</h5>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <CrudActions crudNo={1} form={PromocodeForm} title="New Promo Code" isEdit={false} getData={getAllPromocode} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    {isLoading ? (
                        <div className="d-flex justify-content-center mt-5">
                            <Spinner animation="border" />
                        </div>
                    ) : promocodes?.length > 0 ? (
                        <>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className="table-style table-heading">Sl.No</th>
                                        <th className="table-style table-heading">Promo Code</th>
                                        <th className="table-style table-heading">Promoter</th>
                                        <th className="table-style table-heading">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentLeads?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="table-style table-font">{index+1}</td>
                                            <td className="table-style table-font">{item.code}</td>
                                            <td className="table-style table-font">{item.Description}</td>
                                            <td className="table-style table-font">
                                                <CrudActions id={item.discount_id} crudNo={2} form={PromocodeForm} title="Edit Promo Code" isEdit={true} getData={getAllPromocode} />
                                                <CrudActions id={item.discount_id} crudNo={5} getData={getAllPromocode} deleteData={deletePromoCode} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination className="justify-content-end">
                                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                                <Pagination.Item>{currentPage}</Pagination.Item>
                                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(promocodes.length / leadsPerPage)} />
                            </Pagination>
                        </>
                    ) : (
                        <h4 style={{ textAlign: 'center' }}>No data</h4>
                    )}
                </Col>
            </Row>
            <ModalPopup show={modalShow} title='Blog Content' onHide={() => setModalShow(false)} form={content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : ''} />
        </div>
    );
}

export default PromoCode;
