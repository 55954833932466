import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

const styles = {
  suggestions: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
  },
};

const AutoSearch = ({ title, suggestions, keyName, handleItemClick, selectedItem, setSelectedItem, searchTerm, setSearchTerm }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length > 0) {
      const filtered = suggestions?.filter((suggestion) =>
        suggestion[keyName]?.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion[keyName]);
    setSelectedItem(suggestion)
    setFilteredSuggestions([]);
    if (handleItemClick) {
      handleItemClick(suggestion)
    }
  };

  return (
    <>
      {suggestions && <div style={{ position: 'relative' }}>
        <Form.Control
          type="text"
          placeholder={title}
          value={searchTerm}
          onChange={handleChange}
        />
        {filteredSuggestions.length > 0 && (
          <ListGroup style={styles.suggestions}>
            {filteredSuggestions.map((suggestion, index) => (
              <ListGroup.Item
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                action
              >
                {suggestion[keyName]}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>}
    </>
  );
};

export default AutoSearch;
