export const dashboardIcons =[
    {
        id:1,
        category: 'Accounts',
        icons: [
            {icon_id: 1, icon_name: "Accounts 1", value: "accounts1"},
            {icon_id: 2, icon_name: "Accounts 2", value: "accounts2"},
            {icon_id: 3, icon_name: "Accounts 3", value: "accounts3"},
            {icon_id: 4, icon_name: "Accounts 4", value: "accounts4"},
            {icon_id: 5, icon_name: "Accounts 5", value: "accounts5"}
        ]
    },
    {
        id:2,
        category: 'Sales',
        icons: [
            {icon_id: 1, icon_name: "Sales 1", value: "sales1"},
            {icon_id: 2, icon_name: "Sales 2", value: "sales2"},
            {icon_id: 3, icon_name: "Sales 3", value: "sales3"},
            {icon_id: 4, icon_name: "Sales 4", value: "sales4"},
            {icon_id: 5, icon_name: "Sales 5", value: "sales5"}
        ]
    },
    {
        id:3,
        category: 'Inventory',
        icons: [
            {icon_id: 1, icon_name: "Inventory 1", value: "inventory1"},
            {icon_id: 2, icon_name: "Inventory 2", value: "inventory2"},
            {icon_id: 3, icon_name: "Inventory 3", value: "inventory3"},
            {icon_id: 4, icon_name: "Inventory 4", value: "inventory4"},
            {icon_id: 5, icon_name: "Inventory 5", value: "inventory5"}
        ]
    },
    {
        id:4,
        category: 'Expense',
        icons: [
            {icon_id: 1, icon_name: "Expense 1", value: "expense1"},
            {icon_id: 2, icon_name: "Expense 2", value: "expense2"},
            {icon_id: 3, icon_name: "Expense 3", value: "expense3"},
            {icon_id: 4, icon_name: "Expense 4", value: "expense4"},
            {icon_id: 5, icon_name: "Expense 5", value: "expense5"}
        ]
    },
    {
        id:5,
        category: 'Finance',
        icons: [
            {icon_id: 1, icon_name: "Finance 1", value: "finance1"},
            {icon_id: 2, icon_name: "Finance 2", value: "finance2"},
            {icon_id: 3, icon_name: "Finance 3", value: "finance3"},
            {icon_id: 4, icon_name: "Finance 4", value: "finance4"},
            {icon_id: 5, icon_name: "Finance 5", value: "finance5"}
        ]
    },
    {
        id:6,
        category: 'Socials',
        icons: [
            {icon_id: 1, icon_name: "Socials 1", value: "socials1"}
        ]
    }
]