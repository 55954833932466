import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

function WhoWeAre() {
    return (
        <Container fluid className='mt-lg-4'>
            <Row >
                <Col xs={12} className="d-flex justify-content-center mt-4">
                    <div className="mb-5">
                        <span className="heading-black">
                            {" "}
                            Who <span className="heading-blue">We Are</span>
                        </span>
                    </div>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Container fluid>
                    <Row >
                        <Col xs={12} className='d-flex justify-content-center about-div paragraph'>
                            <div className='headline home-subcontant about-div content'>
                                We are a team of experienced professionals specializing in software development and consulting services.
                                With a focus on collaboration and efficiency, our development process ensures that clients are involved
                                every step of the way. Our consulting services offer strategic guidance and advice to help clients optimize
                                their technology investments and achieve their business objectives. We prioritize building long-term
                                relationships with our clients based on trust, transparency, and delivering results.
                            </div>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center mt-3'>
                        <Button className='button home-color' onClick={() => { window.location.href = '/Demo'; }}>Request for a demo</Button>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row > 
                <Col xs={12} className="p-0" >
                <img src={process.env.PUBLIC_URL + "/assets/images/banner.png"} alt="Banner"  className='w-100'/>
                </Col>
            </Row>
        </Container>
    )
}

export default WhoWeAre