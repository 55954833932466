import { toast } from 'react-toastify';
import axios from 'axios';

export const handleApiErrors = (error, errorMessage, res_error, timeout_error, req_error, setIsLoading) => {
    if (error.response) {
        // if (error.response.status !== 401) {
            toast.error(`The response from ${errorMessage} got error  :${error.response.data.message?error.response.data.message:error.message}`, {
                autoClose: 10000, // Close the toast after 5 seconds
            });
            setIsLoading((prevIsLoading) => ({
                ...prevIsLoading,
                [res_error]: true
            }));
            console.log("response error",error);
        // }
    } else if (error.request) {
        if (error.code === 'ECONNABORTED') {
            toast.error(`The request to ${errorMessage} has timed out. Please check your internet connection and try again. If the issue persists, please contact our support team for assistance.`, {
                autoClose: 10000, // Close the toast after 5 seconds
            });
            setIsLoading((prevIsLoading) => ({
                ...prevIsLoading,
                [timeout_error]: true
            }));       
            
        }
        else if(!axios.isCancel(error)){
            toast.error(`The request to ${errorMessage} got error  :${error.message}`, {
                autoClose: 10000, // Close the toast after 5 seconds
            });
            setIsLoading((prevIsLoading) => ({
                ...prevIsLoading,
                [req_error]: true
            }));
            console.log(error);
        }
    } else {
        // console.log(error);
        console.error('Error:', error.message);
        toast.error(`Error in ${errorMessage} function  :${error.message}`, {
            autoClose: 10000, // Close the toast after 5 seconds
        });
    }
  };