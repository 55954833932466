import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';

function Footer() {
  return (
    // <div>
    //   <Navbar sticky="bottom" bg="light" data-bs-theme="light">
    //     <Container >
    //       <Row>
    //         <Col xs={12} lg={3}>
    //           <div >
    //             <img src={process.env.PUBLIC_URL + `/assets/images/RCORLogo.png`} alt="Company Logo" height={50} />
    //           </div>

    //           <div className='follow'>
    //             <h6 className='text-dark'>
    //               Follow Our Journey
    //             </h6>
    //           </div>

    //           <div className='logos mt-4'>
    //             <a href="https://www.instagram.com/rcor.co/" target="_blank" rel="noopener noreferrer" className='me-4'>
    //               <img src={process.env.PUBLIC_URL + `/assets/images/Instagram.png`} alt="Instagram Logo" height={22} width={22} />
    //             </a>
    //             <a href="https://www.linkedin.com/company/rcorco/" target="_blank" rel="noopener noreferrer" className='me-4'>
    //               <img src={process.env.PUBLIC_URL + `/assets/images/Linkidin.png`} alt="LinkedIn Logo" height={22} width={22} />
    //             </a>
    //             <a href="https://www.facebook.com/RCOR4U" target="_blank" rel="noopener noreferrer" className='me-4'>
    //               <img src={process.env.PUBLIC_URL + `/assets/images/FaceBook.png`} alt="Facebook Logo" height={22} width={22} />
    //             </a>
    //             <a href="http://www.youtube.com/@RCOR4u" target="_blank" rel="noopener noreferrer" className='me-4'>
    //               <img src={process.env.PUBLIC_URL + `/assets/images/Youtube.png`} alt="YouTube Logo" height={22} width={22} />
    //             </a>
    //             <a href="https://x.com/rcor_co" target="_blank" rel="noopener noreferrer" className='me-4'>
    //               <img src={process.env.PUBLIC_URL + `/assets/images/Twitter.png`} alt="Twitter Logo" height={22} width={22} />
    //             </a>
    //           </div>


    //           <div className='link mb-4'>
    //             <img src={process.env.PUBLIC_URL + `/assets/images/Website.png`} className='me-4' alt="Company Logo" height={18} width={18} />
    //             <a href="https://www.rcor.co/" className='common-words text-dark'>https://www.rcor.co/</a>
    //           </div>
    //         </Col>
    //         <Col></Col>
    //       </Row>
    //     </Container>
    //   </Navbar>
    //   <div>
    //     <Row>
    //       <Col xs={12}>
    //         <div className='last-section text-center text-light p-2' style={{ backgroundColor: '#489C8F' }}  >
    //           Copyrights © 2024 RCOR Technologies.All rights reserved.
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    // </div>
        <MDBFooter   className='footer-container text-center text-lg-start text-muted'>


        <section style={{ backgroundColor: '#F4FFFD' }} className='d-flex justify-content-center justify-content-lg-between pb-5'>
          <MDBContainer  className='text-center text-md-start mt-5'>
            <MDBRow className='mx-5'>
              <MDBCol md="12" lg="4" xl="3" className='ms-1'>
                <div >
              <img src={process.env.PUBLIC_URL + `/assets/images/RCORLogo.png`} alt="Company Logo" height={50}  />
              </div>

              <div className='follow'>
              <h6 className='text-dark'>
                 Follow Our Journey
                </h6>
              </div>

              <div className='logos mt-4'>
      <a href="https://www.instagram.com/rcor.co/" target="_blank" rel="noopener noreferrer" className='me-4'>
        <img src={process.env.PUBLIC_URL + `/assets/images/Instagram.png`} alt="Instagram Logo" height={22} width={22} />
      </a>
      <a href="https://www.linkedin.com/company/rcorco/" target="_blank" rel="noopener noreferrer" className='me-4'>
        <img src={process.env.PUBLIC_URL + `/assets/images/Linkidin.png`} alt="LinkedIn Logo" height={22} width={22} />
      </a>
      <a href="https://www.facebook.com/RCOR4U" target="_blank" rel="noopener noreferrer" className='me-4'>
        <img src={process.env.PUBLIC_URL + `/assets/images/FaceBook.png`} alt="Facebook Logo" height={22} width={22} />
      </a>
      <a href="http://www.youtube.com/@RCOR4u" target="_blank" rel="noopener noreferrer" className='me-4'>
        <img src={process.env.PUBLIC_URL + `/assets/images/Youtube.png`} alt="YouTube Logo" height={22} width={22} />
      </a>
      <a href="https://x.com/RCOR4u" target="_blank" rel="noopener noreferrer" className='me-4'>
        <img src={process.env.PUBLIC_URL + `/assets/images/Twitter.png`} alt="Twitter Logo" height={22} width={22} />
      </a>
    </div>


                <div className='link mb-4'>
                <img src={process.env.PUBLIC_URL + `/assets/images/Website.png`} className='me-4' alt="Company Logo" height={18} width={18}  />
                <a href="https://www.rcor.co/" className='common-words text-dark'>https://www.rcor.co/</a>
                </div>

              </MDBCol>

              <MDBCol md="12" lg="4" xl="3" className=' text-dark mx-auto mb-4'>
                <h6 className='quick-links fw-semibold mb-5'>Quick Links</h6>
                <p className='common-words common-icon ' onClick={() => { window.location.href = 'https://www.rcor.co/rcorintelliger';}}>Home</p>
                {/* <p className='common-words' onClick={() => { window.location.href = '/features';}}>Features</p> */}
                <p className='common-words common-icon ' onClick={() => { window.location.href = 'https://www.rcor.co/casestudies';}}>Blog</p>
                {/* <p className='common-words  '>Pricing</p>
                <p className='common-words  '>Case Study</p> */}
                {/* <p className='common-words' onClick={() => { window.location.href = '/resources';}}>Resources</p> */}
                <p className='common-words common-icon ' onClick={() => { window.location.href = 'https://www.rcor.co/contact-us';}}>Contact</p>
                {/* <p className='common-words'>Privacy Policy</p> */}

               </MDBCol>



              <MDBCol md="12" lg="4" xl="3" className='text-dark mx-auto mb-md-0 mb-4'>
                <h6 className='quick-links fw-semibold mb-4'>Contact Us</h6>
                <p className='common-words1 mt-5'><img src={process.env.PUBLIC_URL + `/assets/images/Phone.png`} className='me-4' alt="Company Logo" height={15} width={15}/>+91 9995857958</p>
                <p className='common-words1 mt-4'><img src={process.env.PUBLIC_URL + `/assets/images/Mail.png`} className='me-4' alt="Company Logo" height={13} width={16}/>info@rcor.co</p>
                <p className='footer-word1 mt-4'><img src={process.env.PUBLIC_URL + `/assets/images/Location.png`} className='me-4' alt="Company Logo" height={16} width={14}/>RCOR Solutions LLC</p>
                <p className='footer-words mt-1'>The Meydan Hotel, Grandstand, </p>
                <p className='footer-words mt-1'> 6th floor, Meydan Road, </p>
                <p className='footer-words mt-1'> Nad Al Sheba, Dubai, U.A.E</p>

                <br/>

                <p className='footer-word1 mt-3'><img src={process.env.PUBLIC_URL + `/assets/images/Location.png`} className='me-4' alt="Company Logo" height={16} width={14}/>RCOR Technologies Private Ltd</p>
                <p className='footer-words mt-1'>1st Floor, VCB Complex</p>
                <p className='footer-words mt-1'>Calvary Road, Poothole.P.O,</p>
                <p className='footer-words mt-1'>Thrissur Kerala, India</p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='last-section text-center text-light p-2' style={{ backgroundColor: '#489C8F' }}  >
         Copyrights © 2024 RCOR Technologies.All rights reserved.
        </div>
      </MDBFooter>
  )
}

export default Footer