import React from 'react'
import {Container} from 'react-bootstrap'

function PaymentAndReceipt() {
  return (
    <div>
    <div className="headline home-colL">
      <span className="headline home-maing">Payment & Receipt Report</span>
    </div>
    <div className="headline home-maing mt-5">
      <img
        src={process.env.PUBLIC_URL + '/assets/images/PaymentAndReceipt.png'}
        alt="Catchy Headline"
        className="d-block w-100 features align-img"
      />
    </div>
    <Container className='mt-5' >
      <div>
        <span className="heading-blacksub3">Get clear picture of your Payments and Receipts via accessing this report. </span>
      </div>
      </Container>
  </div>
  )
}

export default PaymentAndReceipt