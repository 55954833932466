import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Spinner, InputGroup, Dropdown } from 'react-bootstrap'
import { getSources } from '../../../api/SourcesApi'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { createLead, getIndustry, getLeadById, getstatus, updateLead } from '../../api/leadsApi'
import { getUsers } from '../../api/userApi'
import dayjs from 'dayjs'
import { changeFormat, successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'
import { getCountry } from '../../../api/countryApi'
import { getCustomerById, updateCustomers } from '../../api/customerApi'

function CustomerForm({ id, isEdit, closeModal, getData }) {
    const abortConstants = useSignalAbort()
    const initialValues = {
        Name: "",
        PhoneNumber: "",
        CompanyName: "",
        Description: "",
        CompanySize: "",
        EmailId: "",
        PreferredDate: "",
        Website: "",
        AnnualRevenue: null,
    }

    const validationRules = {
        Name: { required: true },
        CompanyName: { required: true },
        // Description: { required: true },
        CompanySize: { required: true },
        EmailId: { required: true, isEmail: true },
        // PreferredDate: { required: true },
        PhoneNumber: { isPhoneNumber: true, required: true },
        // Website: { required: true, isURL: true },
        AnnualRevenue: { isNumber: true },
        SourceId: { required: true },
        IndustryId: { required: true },
        CountryId: { required: true }
    };

    const [customerData, setCustomerData] = useState(initialValues)
    const [formErrors, setFormerrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [sourceSelect, setSourceSelect] = useState("")
    const [industrySelect, setIndustrySelect] = useState("");
    const [statusSelect, setStatusSelect] = useState("");
    const [userSelect, setUserSelect] = useState("");
    const [userList, setUserList] = useState()
    const [statusList, setStatusList] = useState()
    const [sourceList, setSourceList] = useState()
    const [industryList, setIndustryList] = useState()
    const [countryList, setCountryList] = useState()
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isLoading, setIsLoading] = useState({})

    //function to get list of industries
    const getAllIndustry = async () => {
        try {
            const result = await getIndustry(abortConstants.controllerConfig)
            setIndustryList(result?.data?.Industry)
            // setIndustrySelect(result?.data?.Industry[0]?.IndustryId)
        }
        catch (error) {
            handleApiErrors(error, 'Industry', 'industry_response', 'industry_timeout', 'industry_request', setIsLoading)
        }
    }

    //function to get list of status
    const getAllStatus = async () => {
        try {
            const result = await getstatus(abortConstants.controllerConfig)
            setStatusList(result?.data?.LeadStatus)
            // setStatusSelect(result?.data?.LeadStatus[0]?.Id)
        }
        catch (error) {
            handleApiErrors(error, 'Status', 'status_response', 'status_timeout', 'status_request', setIsLoading)
        }
    }

    //function to get country 
    const getCountryData = async () => {
        try {
            const result = await getCountry(abortConstants.controllerConfig);
            console.log(result?.data?.Country);
            setCountryList(result?.data?.Country);
            setSelectedCountry(result?.data?.Country[0])
        } catch (error) {
            handleApiErrors(error, 'Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
        }
    };

    useEffect(() => {
        getAllIndustry()
        getAllStatus()
        getCountryData()
    }, [])

    useEffect(() => {
        if (isEdit) {
           const getCustomer = async () => {
                try {
                    const result = await getCustomerById(id, abortConstants.controllerConfig)
                    console.log(result);
                    const customer = result?.data?.Customer
                    const countrySelected = countryList?.find(item => item.countryId === customer?.CountryId)
                    let regex = new RegExp(`^\\+?${countrySelected?.CallingCode}`);
                    setSelectedCountry(countrySelected)
                   if(countrySelected){
                    setCustomerData({...customer, PhoneNumber: customer?.PhoneNumber.replace(regex, ''),});
                   }
                    console.log(result);
                    setIndustrySelect(customer?.IndustryId)
                }
                catch (error) {
                    handleApiErrors(error, 'Customer', 'customer_response', 'customer_timeout', 'customer_request', setIsLoading)
                }
            }
            getCustomer()
        }
    }, [id, countryList])

    //for creating lead
    // const createLeads = async (data, abortToken) => {
    //     console.log(data);
    //     try {
    //         const result = await createLead(data, abortToken)
    //         if (result) {
    //             setIsSaving(false)
    //             successToast(result?.data?.message)
    //             closeModal()
    //             getData()
    //         }
    //     }
    //     catch (error) {
    //         setIsSaving(false)
    //         handleApiErrors(error, 'Add Lead', 'addlead_response', 'addlead_timeout', 'addlead_request', setIsLoading)
    //     }
    // }

    //for updating lead
    const updateCustomer = async (id, data, abortToken) => {
        console.log(data);
        try {
            const result = await updateCustomers(id, data, abortToken)
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Customer', 'customeredit_response', 'customeredit_timeout', 'customeredit_request', setIsLoading)
        }
    }

    //To set input values for form
    const handleChange = (e) => {
        // setFormerrors({})
        setCustomerData({ ...customerData, [e.target.name]: e.target.value },)
    }

    //For form validation
    const validate = (values) => {
        const errors = validateForm(values, validationRules);
        console.log(errors);
        setFormerrors(errors)
        if (Object.keys(errors).length === 0) {
            return true;
        }
        else {
            setIsSaving(false)
            return false;
        }
    }

    //form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSaving(true)
        setFormerrors({})
        const data = {
            ...customerData,
            // SourceId: Number(sourceSelect),
            IndustryId: Number(industrySelect),
            // LeadOwner: Number(userSelect),
            // Status: Number(statusSelect),
            AnnualRevenue: Number(customerData.AnnualRevenue),
            CountryId: Number(selectedCountry?.countryId),
        }
        console.log({...data, PhoneNumber: "+" + selectedCountry?.CallingCode + customerData.PhoneNumber});
        if (validate(data)) {
            updateCustomer(id, { ...data, PhoneNumber: "+" + selectedCountry?.CallingCode + customerData.PhoneNumber }, abortConstants.controllerConfig)
            // if (isEdit) {
            //     updateCustomer(id, { ...data, PhoneNumber: "+" + selectedCountry?.CallingCode + customerData.PhoneNumber }, abortConstants.controllerConfig)
            // } else {
            //     createLeads({ ...data, PhoneNumber: "+" + selectedCountry?.CallingCode + customerData.PhoneNumber }, abortConstants.controllerConfig)
            // }
        }
        setValidated(true)
    }


    return (
        <div >
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={100} required size='sm' type='type' name='Name' value={customerData.Name} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Name}</Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='phone'>
                            <Form.Label className='text-left label form-label'>Phone<span className='asterik'> *</span></Form.Label>
                            <InputGroup>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: '#F6F6F6', color: 'black', border: '1px solid #c4c3c3' }}>
                                        {selectedCountry && <span>
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                                                alt={selectedCountry.countryName}
                                                className="country-flag"
                                                style={{ marginRight: '5px' }}
                                            />
                                            +{selectedCountry.CallingCode}
                                        </span>}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {countryList?.map(item => (
                                            <Dropdown.Item key={item?.countryId} onClick={() => setSelectedCountry(item)}>
                                                <img
                                                    src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                                                    alt={item.countryName}
                                                    className="country-flag"
                                                    style={{ marginRight: '10px' }}
                                                />
                                                +{item?.CallingCode} ({item?.countryName})
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Control
                                    maxLength={selectedCountry?.MobileNumberlen}
                                    pattern="[0-9]*"
                                    required
                                    size='sm'
                                    type='tel'
                                    name='PhoneNumber'
                                    value={customerData.PhoneNumber}
                                    onChange={handleChange}
                                    // placeholder="Phone Number"
                                    aria-label="Phone number"
                                />
                            </InputGroup>
                            <Form.Control.Feedback type="invalid" className="d-block mt-1">
                                {formErrors?.PhoneNumber} {formErrors?.CountryId}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Company Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={120} required size='sm' type='type' name='CompanyName' value={customerData.CompanyName} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.CompanyName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='email'>
                            <Form.Label className='text-left label form-label' >Email ID<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='email' name='EmailId' value={customerData.EmailId} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.EmailId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='company_size'>
                            <Form.Label className='text-left label form-label' >Number of Employees<span className='asterik'> *</span></Form.Label>
                            <Form.Control required size='sm' type='type' name='CompanySize' value={customerData.CompanySize} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.CompanySize}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='website'>
                            <Form.Label className='text-left label form-label' >Website<span className='asterik'> *</span></Form.Label>
                            <Form.Control
                             size='sm' 
                            //  type='url' 
                             name='Website' 
                             value={customerData.Website} 
                             onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Website}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                <Col xs={12} md={4}>
                        <Form.Group controlId='phone'>
                            <Form.Label className='text-left label form-label' >City<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={25} required size='sm' type='type' name='City' value={customerData?.City} onChange={handleChange} />
                            {/* <span className='form-error text'>
                                {formErrors?.City}
                            </span> */}
                            <Form.Control.Feedback type="invalid">{formErrors?.City}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='name'>
                            <Form.Label className='text-left label form-label' >Zipcode<span className='asterik'> *</span></Form.Label>
                            <Form.Control
                                maxLength={8}
                                // pattern="[0-9]*"
                                required
                                size='sm'
                                type='type'
                                name='ZipCode'
                                value={customerData?.ZipCode}
                                onChange={handleChange}
                            // isInvalid={!!formErrors?.ZipCode} 
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.ZipCode}</Form.Control.Feedback>
                            {/* <span className='form-error text'>
                                {formErrors?.ZipCode}
                            </span> */}
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='company'>
                            <Form.Label className='text-left label form-label' >Street<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={25} required size='sm' type='type' name='Street' value={customerData?.Street} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Street}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12} md={4} >
                        <Form.Group controlId='tax'>
                            <Form.Label className='text-left label form-label' >TRN/GST No<span className='asterik'> *</span></Form.Label>
                            <Form.Control required maxLength={25} size='sm' type='type' name='TaxNo' value={customerData?.TaxNo} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.TaxNo}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId='revenue'>
                            <Form.Label className='text-left label form-label' >Annual Revenue<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={20} size='sm' pattern="[0-9]*" type='number' name='AnnualRevenue' value={customerData.AnnualRevenue} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.AnnualRevenue}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} >
                        <Form.Group controlId="industry">
                            <Form.Label className='text-left label form-label' >Industry<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={industrySelect} onChange={(e) => setIndustrySelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {industryList?.map(item => (
                                    <option key={item.IndustryId} value={item.IndustryId}>{item.IndustryName}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.SourceId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>                  
                    {/* <Col xs={12} md={5} >
                        <Form.Group controlId="status">
                            <Form.Label className='text-left label form-label' >Subscription Status<span className='asterik'> *</span></Form.Label>
                            <Form.Select required size='sm' value={statusSelect} onChange={(e) => setStatusSelect(e.target.value)}>
                                <option value="" disabled>Select</option>
                                {statusList?.map(item => (
                                    <option key={item.Id} value={item.Id}>{item.Name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formErrors?.Status}</Form.Control.Feedback>
                        </Form.Group>
                    </Col> */}
                    <Col xs={12} md={6} >
                        <Form.Group controlId='reason'>
                            <Form.Label className='text-left label form-label' >Subdomain</Form.Label>
                            <Form.Control readOnly size='sm' type='type' name='Reason' value={customerData.SubDomain} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-2'>
                <Col xs={12} md={6} className='ms-1'>
                    <Form.Check type='checkbox' id='default-checkbox'>
                            <Form.Check.Input type='checkbox' style={{borderColor: '#489C8F'}} checked={customerData?.IsTallyConnectorEnabled} onChange={(e)=>setCustomerData({...customerData, IsTallyConnectorEnabled: e.target.checked?1:0})}/>
                            <Form.Check.Label className='text-left label form-label'>Enable Tally Connector</Form.Check.Label>
                            {/* <Form.Control.Feedback type="invalid">
                                You did it!
                            </Form.Control.Feedback> */}
                        </Form.Check>
                    </Col>
                </Row>
                <div className='mt-3 d-flex justify-content-end' >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                    // disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </div>
    )
}

export default CustomerForm