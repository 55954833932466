import React from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';

function Subscribe() {
  return (
    <div>
      <Container fluid className="container-color2">
        <div className="heading home-marginbutton">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="headline home-colC"
          >
            <Container>
              <Row className="justify-content-center ">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className=" headline home-colC">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/Subscribe.png"
                      }
                      alt="Catchy Headline"
                      className="headline home-imgClient p-3"
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                  <div>
                    <div className="head home-price mt-5">
                      {" "}
                      <span className="headline home-main">
                        Subscribe to our Newsletter !
                      </span>{" "}
                    </div>
                    <div className="head home-priceS">
                      {" "}
                      <span className="headline home-prices">
                        Be the first to get exclusive offers and latest news.
                      </span>{" "}
                    </div>
                    <div className="head home-priceS">
                      <Form >
                        <Form.Group  className="mt-3" controlId="formBasicEmail">
                          {/* <Form.Label>Email address</Form.Label> */}
                          <Form.Control
                            type="email"
                            placeholder="Enter your email address"
                          />
                          {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                          </Form.Text> */}
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="head home-priceS">
                      <Button
                        className="button home-color m-2 w-100"
                        onClick={() => {
                          window.location.href = "/Demo";
                        }}
                      >
                        SUBSCRIBE
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </div>
      </Container>
    </div>
  );
}

export default Subscribe