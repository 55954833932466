import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

function RichTextEditor({value, handleRichTextbox}) {

    return (
        <ReactQuill
            theme="snow"
            value={value}
            onChange={handleRichTextbox}
            modules={{
                toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                ],
                clipboard: {
                    // Add a custom matchers array to define allowed HTML tags and attributes during pasting
                    matchVisual: false,
                    // matchers: [
                    //     ['a', 'href', 'title', 'target'],
                    //     ['img', 'src'],
                    //     ['video', 'src'],
                    // ],
                },
            }}
        />
        // <ReactQuill
        //     className={`Responsibility-editor ${jobDetails.ResponsibilityFocus ? 'focused' : ''}`}
        //     value={jobDetails.Responsibility || ''}
        //     onChange={(value) => setJobDetails({ ...jobDetails, Responsibility: value })}
        //     onFocus={() => setJobDetails({ ...jobDetails, ResponsibilityFocus: true })}
        //     onBlur={() => setJobDetails({ ...jobDetails, ResponsibilityFocus: false })}
        //     modules={{
        //         toolbar: [
        //             [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        //             [{ size: [] }],
        //             ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        //             [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        //             ['link', 'image', 'video'],
        //             ['clean'],
        //         ],
        //         clipboard: {
        //             // Add a custom matchers array to define allowed HTML tags and attributes during pasting
        //             matchers: [
        //                 ['a', 'href', 'title', 'target'],
        //                 ['img', 'src'],
        //                 ['video', 'src'],
        //             ],
        //         },
        //     }}
        // />
    )
}

export default RichTextEditor