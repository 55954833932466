import React, { useState } from 'react';
import { OverlayTrigger, Popover, FormControl, Button } from 'react-bootstrap';
import { CiFilter } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";

const Filter = React.forwardRef(({ columnName, handleFilterChange}, ref) => {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState('');

    const handleInputChange = (e) => {
        setValue(e.target.value);
        handleFilterChange(e, columnName);
    };

    const popover = (
        <Popover id={`popover-${columnName}`}>
            <Popover.Body>
                <span className='d-flex justify-content-end'>
                <IoMdClose onClick={() => setShow(false)} />
                </span>
                <FormControl
                    type={columnName === 'Date'? 'date' : 'text'}
                    placeholder={`Filter by ${columnName}`}
                    value={value}
                    onChange={handleInputChange}
                />
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="right"
            show={show}
            overlay={popover}
            onToggle={() => setShow(!show)}
        >
            <span ref={ref} style={{ cursor: 'pointer' }} onClick={() => setShow(!show)} className='mx-1'>
                <CiFilter />
            </span>
        </OverlayTrigger>
    );
});

export default Filter;
