import React from 'react'
import { Col, Container, Button, Row } from 'react-bootstrap'

function PriceDemo() {
  return (
    <div>
        <Container fluid className='container-color3'>
            <div>
                <Row>
                <Col xs={12} sm={6} md={6} lg={5} xl={5}>
                <div className='head home-img'>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/Demo.png"
                }
                alt="RCORIntelliger"
                className="d-block w-100"
              />
            </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={5} xl={5} className='headline home-colC'>
                <div className='container-width '>
              <div  className='head Features-price'> <span className='headline home-price'>Request a free demo of RCOR 
Intelliger today and see how we can 
transform your business</span> </div>
              <div className='heading Features-pricesub'> <span className='headline home-pricesub'>Our team members are ready to help you</span> </div>
              <div className='head home-pricebuttonsub '>
                <Button size='lg' className='button home-colordemo m-3' onClick={() => { window.location.href = '/Demo';}}> &nbsp;&nbsp; Request a demo &nbsp;&nbsp;</Button>
                <Button size='lg'className='button home-boder ml-5 'onClick={() => { window.location.href = '/contact';}} >&nbsp;&nbsp;&nbsp;&nbsp;Contact Us&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                </div> 
          </div> 
                </Col>
                </Row>
            </div>
        </Container>
    </div>
  )
}

export default PriceDemo