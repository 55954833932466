import React from 'react'
import { IoChevronBack } from "react-icons/io5";
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Image } from 'react-bootstrap'

function ReadmoreBlog() {
  const navigate = useNavigate();

  return (
    <div>
      <div className='Horizondal-Divider'>
        <Header />
      </div>
      <div>
        <Container fluid >

          <div className='back mt-5'>
            <IoChevronBack className='backicon' onClick={() => { window.location.href = '/blog'; }} />
            <span className='backtext'>Back</span>
          </div>

          <div>
            <Col xs={12} sm={12} md={12} lg={12} className="headline home-colC">
              <div className="mt-5">
                <span className="heading-black">
                  How Business Analytics Can Empower Your Organization
                  {/* Blog <span className="heading-blue">Title</span> */}
                </span>
              </div>
            </Col>
          </div>

          <div >
            <Container>
              <Col xs={12} sm={12} md={12} lg={12} className="headline home-colL">
                <div className='head home-img1'>
                  <Container>
                    <Row >
                      <Col xs={6} sm={2} md={2} lg={2} className="headline home-colC">
                        <Image src={process.env.PUBLIC_URL + "/assets/images/Anu Rekha_Photo.png"}
                          alt="image"
                          className="headline home-img4" roundedCircle/>
                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/Anu Rekha_Photo.jpg"}
                          alt="Catchy Headline"
                          className="headline home-img4" /> */}
                      </Col>
                      <Col xs={6} sm={4} md={4} lg={10} >
                        <span className='heading-green1'>Anu Rekha</span><br />
                        <span className="heading-blacksub4">May 22, 2024 - 5 min read</span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} className="headline home-colC">
                <div className='head home-img1 mb-5 pt-0'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/BlogReadmore.png"}
                    alt="Catchy Headline"
                    className="d-block w-100" />
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} className="headline home-colC">
                <div className="head home-img1">
                  <span className='heading-blacksub4 m-5 p-5'>
                    Imagine you and your friend walking to your local supermarket, craving your favorite chocolate bar,
                    only to find the shelf empty...again? Disappointing, isn’t it? Especially when it is your favorite Snickers bar!
                    This scenario might seem minor but highlights a significant problem – a lack of Business Analytics.
                  </span><br /><br />
                  <span className='heading-blacksub4 m-5 p-5'>
                    If only the supermarket analyzed customer purchase data, they could identify popular items like your chocolate bar
                    and predict when they would need restocking. This simple step could turn a frustrated customer into a loyal one. The
                    benefits of using business analytics software extend far beyond keeping your favorite chocolates on the shelves.
                    Here’s how it can empower your organization:
                  </span><br /><br />
                  <div className='d-flex flex-column'>
                    <span className='blog subtitle-text'>Data-Driven Decisions </span>
                    <span className='heading-blacksub4'>
                      Transform raw data into actionable insights, empowering you to make informed choices in your business.
                    </span>
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    <span className='blog subtitle-text'>Improved Efficiency  </span>
                    <span className='heading-blacksub4'>
                      Identify areas for improvement. This can streamline processes, eliminate bottlenecks, and save your company valuable
                      time and money.
                    </span>
                  </div>
                  <div className='d-flex flex-column  mt-3'>
                    <span className='blog subtitle-text'>Enhanced Customer Satisfaction  </span>
                    <span className='heading-blacksub4'>
                      Gain a deeper understanding of your customer’s needs and preferences. Personalize marketing campaigns and tailor
                      product offerings.                     </span>
                  </div>
                  <div className='d-flex flex-column  mt-3'>
                    <span className='blog subtitle-text'>Cost Reduction </span>
                    <span className='heading-blacksub4'>
                      Optimize inventory management, reduce waste, and make smarter budgeting decisions.
                    </span>
                  </div>
                  <div className='d-flex flex-column  mt-3'>
                    <span className='blog subtitle-text'>Increased Revenue  </span>
                    <span className='heading-blacksub4'>
                      By optimizing marketing campaigns, personalizing offerings, and improving customer satisfaction, business analytics
                      can lead to a significant boost in your bottom line.
                    </span>
                  </div>
                  <div className='mt-3'>
                    <span className='blog subtitle-text'>Business Analytics software  </span>
                    has become a game-changer across a wide
                    range of industries such as:
                  </div>
                  <ul>
                    <li className='heading-blacksub4'>Finance and Banking </li>
                    <li className='heading-blacksub4'>Retail </li>
                    <li className='heading-blacksub4'>Manufacturing </li>
                    <li className='heading-blacksub4'>Healthcare </li>
                    <li className='heading-blacksub4'>Marketing and Sales </li>
                    <li className='heading-blacksub4'>And many more. </li>
                  </ul>
                  <div className='d-flex flex-column  mt-3'>
                    <span className='blog subtitle-text'>Targeting SMEs and Tally Users in India  </span>
                    <span className='heading-blacksub4'>
                    Small and Medium Enterprises (SMEs) in India can particularly benefit from business intelligence (BI) tools. 
                    For Tally users, integrating BI platforms can enhance financial analysis, streamline operations, and improve 
                    decision-making processes. 
                    </span>
                  </div>
                  <div className='d-flex flex-column  mt-3'>
                    <span className='blog subtitle-text'>Ready to Unlock the Power of Data?  </span>
                    <span className='heading-blacksub4'>
                    Why wait? RCOR Intelliger is at your rescue! It is your cloud-powered Business Intelligence (BI) platform which 
                    transforms data into a strategic weapon. Ditch time-consuming analysis, unlock hidden trends, gain crystal-clear 
                    insights, and make data-driven decisions that propel your business to new heights. RCOR Intelliger seamlessly 
                    integrates with Tally software, making it an ideal solution for SMEs in India. Our platform provides advanced 
                    data analytics, helping you manage and interpret your business data effectively. 
                    </span>
                  </div>
                  <div className='d-flex flex-column  mt-3'>
                    <span className='blog subtitle-text'>You made it to the end? Thank you for reading!! </span>
                  </div>
                </div>
              </Col>

            </Container>
          </div>
        </Container>
      </div>
      <div className='horizontal-divider mt-5'></div>
      {/* <div >
        <Container>
          <div className='mb-5'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} className=' mt-5 headline home-colL'>
                <div className='headline home-col px-3 mb-2'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/RightArrow.png"}
                    alt="Catchy Headline"
                    className="headline home-img3"
                    onClick={() => { window.location.href = '/ReadMore'; }}
                  />
                  <Button className='custom readmore-button'
                  // onClick={() => navigate('/ReadMore')}
                  >
                    Read Previous Blog
                  </Button>

                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className=' mt-5 headline home-col'>
                <div className='headline home-col px-3 mb-2'>
                  <Button className='custom readmore-button'
                  // onClick={() => navigate('/ReadMore')}
                  >
                    Read Next Blog
                  </Button>
                  <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                    alt="Catchy Headline"
                    className="headline home-img3"
                    onClick={() => { window.location.href = '/ReadMore'; }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className='mb-5'>
            <Row >

              <Col xs={12} sm={12} md={12} lg={6} className=' headline home-colL '>
                <Card className='card boder-r'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Blog1.png"}
                    alt="Catchy Headline"
                    className="d-block w-100" />
                  <span className="heading-blacksub3 mx-3 m-1 mt-3">Blog 1</span>
                  <span className="heading-blacksub4 mx-3 m-1">Lorem ipsum dolor sit amet consectetur. Sit a id tristique at.
                    Massa ipsum cras massa sit. Risus ut ultrices est turpis tellus ...</span>
                  <div className='headline home-col px-3 mb-2'>
                    <Button className='custom readmore-button'
                    // onClick={() => navigate('/ReadMore')}
                    >
                      Read More
                    </Button>
                    <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                      alt="Catchy Headline"
                      className="headline home-img3"
                      onClick={() => { window.location.href = '/ReadMore'; }}
                    />
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className=' headline home-col '>
                <Card className='card boder-r'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Blog2.png"}
                    alt="Catchy Headline"
                    className="d-block w-100" />
                  <span className="heading-blacksub3 mx-3 m-1 mt-3">Blog 2</span>
                  <span className="heading-blacksub4 mx-3 m-1">Lorem ipsum dolor sit amet consectetur. Sit a id tristique at.
                    Massa ipsum cras massa sit. Risus ut ultrices est turpis tellus ...</span>
                  <div className='headline home-col px-3 mb-2'>
                    <Button className='custom readmore-button' >
                      Read More
                    </Button>
                    <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                      alt="Catchy Headline "
                      className="headline home-img3"
                      onClick={() => { window.location.href = '/ReadMore'; }} />
                  </div>
                </Card>
              </Col>

            </Row>
          </div>
        </Container>
      </div> */}
      <div >
        <Footer />
      </div>
    </div>
  )
}

export default ReadmoreBlog