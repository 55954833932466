import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setLogout } from '../../redux/slices/authSlice'

function SessionExpired() {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const logOut = () => {
        localStorage.removeItem("intelliger_admin_token")
        dispatch(setLogout())
        navigate('/Login')
    }

    return (
        <div className='d-flex flex-column align-items-center justify-content-center vh-100 login bg-image'>
            {/* Centering the content vertically and horizontally */}
            <Row >
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    {/* <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' width={121} height={45} /> */}
                    <span className='mt-3'>Your Session Expired</span>
                    <Button className='button save mt-2' onClick={() => logOut()}>Please Login</Button>
                    {/* <img className='mt-3 flip-animation' src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} width={114} height={100} /> */}
                </Col>
            </Row>
        </div>

    )
}

export default SessionExpired