import axios from "axios"
import axiosInstanceAdmin from "./axiosInstanceAdmin";

const API_URL = process.env.REACT_APP_BASE_URL 
const api = axiosInstanceAdmin()

// const fullURL = window.location.href; //getting url
// const parsedURL = new URL(fullURL)
// const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '') //fetch the host name

//function to call api for login

export const login =(userData, abortToken) =>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'Authority': hostName,
            // 'mode' : 'cors'
            // 'Origin': 'http://localhost:3000'
        },
      };
    return axios.post(`${API_URL}/auth`, userData, config)
}

//function to gell all menu
export const getMenu =( userTypeId, token,  abortToken) =>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'Authority': hostName,
            // 'mode' : 'cors'
            // 'Origin': 'http://localhost:3000'
        },
      };
    return axios.get(`${API_URL}/SubMenu/Permitted/${userTypeId}`, config)
}

//function for changing password
export const changePassword =(data, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.post(`/ChangePassword`, data, config)
}