import React from 'react'
import { Col, Row } from 'react-bootstrap'

function PageNotFound() {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center vh-100 login bg-image'>
    {/* Centering the content vertically and horizontally */}
    <Row >
        <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
            <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' width={121} height={45} />
            <span className='mt-1'>Sorry, the page not found</span>
            <span className='mt-1'>The link you followed probably broken</span>
            <span className='mt-1'>or the page has been removed.</span>
        </Col>
    </Row>
</div>
  )
}

export default PageNotFound