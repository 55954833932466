import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Image } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";

const MAX_FILE_SIZE_MB = 1;

const UploadImage = ({selectedImage, setSelectedImage, setSelectedImageBase64 }) => {
    const fileInputRef = useRef();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
        if (file) {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                alert(`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`);
                return;
            }

            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!allowedTypes.includes(file.type)) {
                alert('Invalid file type. Please choose a valid image file.');
                return;
            }

            // Create a new instance of FileReader
            const reader = new FileReader();
            console.log(reader.result);
            reader.onload = () => {
                try {
                    const base64Data = reader.result.split(',')[1]; // Get base64 data excluding the data URI scheme
                    setSelectedImageBase64(base64Data)
                    setSelectedImage(reader.result);
                } catch (error) {
                    console.error('Error handling image data:', error);
                    // Handle the error (e.g., show an error message to the user)
                }
            };

            reader.readAsDataURL(file);

        } else {
            setSelectedImage(null);
        }
    };

    const reader = new FileReader();

    reader.onloadend = () => {
        console.log(reader.result); // Log the result here
    };


    const handleDeleteImage = () => {
        setSelectedImage(null);
        setSelectedImageBase64(null);
        fileInputRef.current.value = null;
    };
    return (
        <Row>
            <Col xs={12}>
                <Form.Control type="file" size="sm" onChange={handleImageChange} ref={fileInputRef} />
            </Col>
            <Col xs={12} className="mt-1">
                {selectedImage &&
                    <Form.Label>
                        <Image src={selectedImage} rounded width={200} height={100} />
                        <MdDeleteOutline className="delete-icon" onClick={handleDeleteImage} />
                    </Form.Label>
                }
            </Col>
        </Row>
    );
};

export default UploadImage;