import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    adminToken: null,
    adminUser: null,
    userMenu: null
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) =>{
            state.adminUser = action.payload.adminUser           
        },
        setToken: (state, action) =>{
            state.adminToken = action.payload.adminToken
        },
        setMenu: (state, action) =>{
            state.userMenu = action.payload.userMenu
        },
        setLogout: (state) => {
            state.adminUser = null;
            state.adminToken = null;
            state.userMenu = null;
        }
    }
})

export const {setUser, setToken, setMenu, setLogout} = authSlice.actions;

export default authSlice.reducer;