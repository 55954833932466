import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    geoData: null
}

export const geoSlice = createSlice({
    name: "geo",
    initialState,
    reducers: {
        setGeoData: (state, action) =>{
            state.geoData = action.payload.geoData           
        },
        clearGeoData: (state) => {
            state.geoData = null;
        }
    }
})

export const {setGeoData, clearGeoData} = geoSlice.actions;

export default geoSlice.reducer;