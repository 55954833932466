import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Spinner } from 'react-bootstrap'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'
import { createPromo, getPromoById, updatePromo } from '../../api/promoApi'
import { getCustomers } from '../../api/customerApi'
import AutoSearch from '../../components/AutoSearch'
import { assignCustomerToWorkspace } from '../../api/workspaceApi'
import MultiAutoSearch from '../../components/MultiAutoSearch'

const validationRules = {
    code: { required: true },
    Description: { required: true },
};

function AssignCustomer({ workspace, isEdit, closeModal, getData, assignedCustomer }) {
    const abortConstants = useSignalAbort()
    const [customers, setCustomers] = useState()
    const [formErrors, setFormerrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState({})
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState()

    // useEffect(()=>{
    //     if(assignedCustomer && assignedCustomer?.length !==0){
    //         setSelectedCustomer()
    //     }
    // }, [workspace, assignedCustomer])

    useEffect(() => {
        //function to get all contacts
        const getAllCustomers = async () => {
            try {
                const response = await getCustomers(abortConstants.controllerConfig)
                setCustomers(response?.data?.Customer)
                console.log(response);
            }
            catch (error) {
                handleApiErrors(error, 'Customer', 'customer_response', 'customer_timeout', 'customer_request', setIsLoading)
            }
        }
        getAllCustomers()
    }, [])

    //for creating blog
    const assignCustomer = async (data, abortToken) => {
        console.log(data);
        try {
            const result = await assignCustomerToWorkspace(data, abortToken)
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData(workspace?.WorkSpaceId)
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Assign Customer', 'assign_response', 'assign_timeout', 'assign_request', setIsLoading)
        }
    }

    // Validation function
    const validate = (value) => {
        if (!value || (typeof value === 'string' && value.trim() === '')) {
            setFormerrors({ customer: 'Customer selection is required.' });
            console.log(formErrors);

            return false;
        } else {
            setFormerrors({});
            return true;
        }
    };


    // Form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
        setFormerrors({});

        // Validate before form submission
        if (validate(selectedCustomer?.Name)) {
            const data = {
                "CustomerId": selectedCustomer?.CustomerId,
                "WorkSpaceId": workspace?.WorkSpaceId
            };
            assignCustomer(data, abortConstants.controllerConfig);
            setValidated(true);
        } else {
            setIsSaving(false);
            setValidated(false);
        }
    };
    //To set input values for form
    // const handleChange = (e) => {
    //     // setFormerrors({})
    //     setAssignData({ ...assignData, [e.target.name]: e.target.value },)
    // }

    const handleCustomerClick = (customer) => {
        console.log('Selected suggestion:', customer); // Access both id and name
        console.log(selectedCustomer);
        
    };

    return (
        <div>
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12}>
                        <Form.Group controlId='title'>
                            <Form.Label className='text-left label form-label' >Workspace<span className='asterik'> *</span></Form.Label>
                            <Form.Control readOnly required size='sm' type='type' name='workspace' value={workspace?.WorkSapceName} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12}>
                        <Form.Group controlId='author'>
                            <Form.Label className='text-left label form-label' >Customer Name<span className='asterik'> *</span></Form.Label>
                            {/* for single selection, if want multi selection use MultiAutoSearch */}
                            <AutoSearch title='Search Customer' suggestions={customers}   
                                keyName='Name'
                                handleItemClick={handleCustomerClick}
                                selectedItem={selectedCustomer}
                                setSelectedItem={setSelectedCustomer}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm} />
                            <span className='form-error text ms-1'>{formErrors?.customer}</span>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='mt-3 d-flex justify-content-end' >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                    // disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </div>
    )
}

export default AssignCustomer