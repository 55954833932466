import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Global() {
    return (
        <Container fluid className='mt-5'>
            <Row className='pt-4'>
                <Col xs={12} className="p-0" >
                    <div className="background-image-div">
                        <div className='d-flex flex-column justify-content-center text-center'>
                            <span className="mb-4 about-global title">Our Global Presence</span>
                            <span className="mb-lg-4 about-global sub-title">
                                At RCOR, we are proud to have a robust and dynamic global presence, serving
                                clients and partners across
                            </span>
                            <span className="mb-lg-4 about-global sub-title">
                                continents. Our commitment to innovation, excellence, and collaboration transcends borders,
                                enabling us to deliver top-tier
                            </span>
                            <span className="mb-lg-4 about-global sub-title">
                                products and services to diverse markets around the world.
                            </span>
                            <Row className='pt-4'>
                                <Col xs={12} md={4} className='d-flex align-items-center justify-content-center about-global location-border'>
                                    <span>
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/global-location.png"}
                                            alt="Core value"
                                            className="headline home-img3"
                                        />
                                    </span>&nbsp;&nbsp;
                                    <span className="about-global location-title">United Kingdom</span>
                                </Col>
                                <Col xs={12} md={4} className='d-flex align-items-center justify-content-center about-global location-border'>
                                    <span>
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/global-location.png"}
                                            alt="Core value"
                                            className="headline home-img3"
                                        />
                                    </span>&nbsp;&nbsp;
                                    <span className="about-global location-title">India</span>
                                </Col>
                                <Col xs={12} md={4} className='d-flex align-items-center justify-content-center'>
                                    <span>
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/global-location.png"}
                                            alt="Core value"
                                            className="headline home-img3"
                                        />
                                    </span>&nbsp;&nbsp;
                                    <span className="about-global location-title">United Arab Emirates</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Global