import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Pagination, Row, Spinner, } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getBlog } from '../../AdminPanel/api/blogApi';
import { handleApiErrors } from '../../handleApiErrors';
import { useSignalAbort } from '../../api/useSignalAbort';
import { renderLoading } from '../../HelperFile';

function OurBlogs() {
  const navigate = useNavigate(); // Initialize the navigate function
  const abortConstants = useSignalAbort()
  const [blogs, setBlogs] = useState();
  const [isLoading, setIsLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs?.slice(indexOfFirstBlog, indexOfLastBlog);

  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(blogs.length / blogsPerPage)));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to strip HTML tags
  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  // Function to truncate content
  const truncateContent = (content, length) => {
    return content.length > length ? content.substring(0, length) + '...' : content;
  };

  // Function to get all leads
  const getAllBlogs = async () => {
    setIsLoading(true);
    try {
      const response = await getBlog(abortConstants.controllerConfig);
      console.log(response);
      // Process each blog: decode, strip HTML, truncate
      const processedBlogs = response?.data?.Blogs.map(blog => {
        const decodedContent = decodeURIComponent(blog.Content);
        const plainContent = stripHtml(decodedContent);
        const truncatedContent = truncateContent(plainContent, 150);

        return {
          ...blog,
          TruncatedContent: truncatedContent // Add truncated content to the blog object
        };
      });

      console.log(processedBlogs);
      setBlogs(processedBlogs);
      setIsLoading(false);
    } catch (error) {
      handleApiErrors(error, 'Blogs', 'blog_response', 'blog_timeout', 'blog_request', setIsLoading);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div className='mb-5'>
      {/* <div >
      <Container>
        <div >
          <Row className='headline home-colC mb-5' >
            <Col xs={12} sm={12} md={12} lg={3} className='mt-5 headline home-col'>
              <Card className='card boder-r'>
                <div className='card-image'>
                <img src={process.env.PUBLIC_URL + "/assets/images/Blog1.png"}
                  alt="Catchy Headline"
                  className="d-block w-100 h-100" />
                </div>
                <div className='card-content'>
                <span className="heading-blacksub3">How Business Analytics Can Empower Your Organization </span>
                <span className="heading-blacksub4 mt-2">Imagine you and your friend walking to your local supermarket,
                 craving your favorite chocolate bar...</span></div>            
                <div className='headline home-col card-button'>
                  <Button className='custom readmore-button'
                  >
                    Read More
                  </Button>
                  <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                    alt="Catchy Headline"
                    className="headline home-img3"
                    onClick={() => { window.location.href = '/ReadMore'; }}
                  />
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={3} className='mt-5 headline home-col'>
            </Col>
            <Col xs={12} sm={12} md={12} lg={3} className='mt-5 headline home-col'>
            </Col>
          </Row>
        </div>
      </Container>
    </div> */}
      <Container>
        {blogs ?
          <>{blogs.length !== 0 ?
            <>
              <Row className='blog blog-container' >
                {/* <Col xs={12} sm={12} md={12} lg={4} className='mt-4 px-3 pe-3 headline home-col'>
                  <Card className='card boder-r'>
                    <div className='card-image'>
                      <img src={process.env.PUBLIC_URL + "/assets/images/Blog1.png"}
                        alt="Catchy Headline"
                        className="d-block w-100 h-100" />
                    </div>
                    <div className='card-content'>
                      <span className="heading-blacksub3">How Business Analytics Can Empower Your Organization </span>
                      <span className="heading-blacksub4 mt-2">Imagine you and your friend walking to your local supermarket,
                        craving your favorite chocolate bar...</span></div>
                    <div className='headline home-col card-button'>
                      <Button className='custom readmore-button'
                      >
                        Read More
                      </Button>
                      <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                        alt="Catchy Headline"
                        className="headline home-img3"
                        onClick={() => { window.location.href = '/ReadMore'; }}
                      />
                    </div>
                  </Card>
                </Col> */}
                {currentBlogs?.map((blog) => (
                  <Col key={blog.BlogsId} xs={12} sm={12} md={12} lg={4} className='mt-4 px-3 pe-3 headline home-col'>
                    <Card className='card boder-r'>
                      <div className='card-image'>
                        <img src={process.env.REACT_APP_BASE_URL + blog.Image}
                          alt="photo"
                          className="d-block w-100 h-100" />
                      </div>
                      <div className='card-content'>
                        <span className="heading-blacksub3">{blog.Title} </span>
                        {/* <span className="heading-blacksub4 mt-2" dangerouslySetInnerHTML={{ __html: blog.Content.slice(0, 150) + '...' }} /> */}
                        <span className="heading-blacksub4 mt-2">{blog.TruncatedContent}</span>
                      </div>
                      <div className='headline home-col card-button' onClick={() => { window.location.href = `/ReadMore/${blog.BlogsId}`; }}>
                        <Button className='custom readmore-button'
                        // onClick={() => navigate('/ReadMore')}
                        >
                          Read More
                        </Button>
                        <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                          alt="Catchy Headline"
                          className="headline home-img3"
                        />
                      </div>
                    </Card>
                    {/* <Card className='card boder-r'>
              <img src={process.env.REACT_APP_BASE_URL + blog.Image}
                alt="image"
                className="d-block w-100" height={180}/>
             <div className='mx-3 m-1 mt-3'>
             <span className="heading-blacksub3 ">{blog.Title}</span>
              <span className="heading-blacksub4">
              <div className='mt-1' dangerouslySetInnerHTML={{ __html: blog.Content.slice(0,50) }} />
              </span>
             </div>
              <div className='headline home-col px-3 mb-2'>
                <Button className='custom readmore-button'
                  onClick={() => navigate('/ReadMore')}
                >
                  Read More
                </Button>
                <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                  alt="Arrow Icon"
                  className="headline home-img3"
                  onClick={() => { window.location.href = '/ReadMore'; }}
                />
              </div>
            </Card> */}
                  </Col>
                ))}
              </Row>
              {blogs?.length > blogsPerPage && <Pagination className="justify-content-center mt-4">
                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(blogs?.length / blogsPerPage)} />
              </Pagination>}
            </> : <>
              <span className='d-flex justify-content-center mb-5'>No Blogs to display</span>
            </>
          }</> :
          <>{!renderLoading(isLoading, 'blog') ?
            <div className='d-flex justify-content-center mb-5'><Spinner animation="border" /></div> :
            <span className='d-flex justify-content-center mb-5'>No Blogs to display</span>
          }</>
        }
      </Container>
    </div>

  )
}

export default OurBlogs