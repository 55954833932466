// import React, { useEffect, useState } from 'react'
// import { Table, Row, Col } from 'react-bootstrap';
// import { getContacts } from '../../api/contactApi';
// import { useSignalAbort } from '../../../api/useSignalAbort';
// import { handleApiErrors } from '../../../handleApiErrors';

// function Contacts() {
//     const abortConstants = useSignalAbort()
//     const [contacts, setContacts] = useState()
//     const [isLoading, setIsLoading] = useState({})

//     //function to get all contacts
//     const getAllContacts = async () => {
//         try {
//             const response = await getContacts(abortConstants.controllerConfig)
//             setContacts(response?.data?.ContactUs)
//         }
//         catch (error) {
//             handleApiErrors(error, 'Contact', 'contact_response', 'contact_timeout', 'contact_request', setIsLoading)
//         }
//     }

//     useEffect(() => {
//         getAllContacts()
//     }, [])

//     return (
//         <div>
//             <Row>
//                 <Col xs={12}>
//                     <h5>Contact</h5>
//                 </Col>
//             </Row>
//             <Row className='mt-3'>
//                 <Col xs={12}>
//                     {contacts ?
//                         <>
//                             {contacts.length !== 0 ?
//                                 <Table striped bordered hover>
//                                     <thead>
//                                         <tr >
//                                             <th className='table-style table-heading'>Name</th>
//                                             <th className='table-style table-heading'>Phone</th>
//                                             <th className='table-style table-heading'>Email</th>
//                                             <th className='table-style table-heading'>Company Name</th>
//                                             <th className='table-style table-heading'>Country</th>
//                                             <th className='table-style table-heading'>Source</th>
//                                             <th className='table-style table-heading'>Category</th>
//                                             <th className='table-style table-heading'>Suggestion</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {contacts?.map((item, index) => (
//                                             <tr key={index}>
//                                                 <td className='table-style table-font'>{item.Name}</td>
//                                                 <td className='table-style table-font'>{item.PhoneNumber}</td>
//                                                 <td className='table-style table-font'>{item.EmailId}</td>
//                                                 <td className='table-style table-font'>{item.CompanyName}</td>
//                                                 <td className='table-style table-font'>{item.CountryName}</td>
//                                                 <td className='table-style table-font'>{item.SourceName}</td>
//                                                 <td className='table-style table-font'>{item.CategoryName}</td>
//                                                 <td className='table-style table-font'>{item.Suggestion}</td>
//                                             </tr>
//                                         ))}
//                                     </tbody>

//                                     <tbody>

//                                     </tbody>
//                                 </Table>
//                                 :
//                                 <h4 style={{ textAlign: 'center' }}>No data</h4>
//                             }
//                         </>

//                         :
//                         <></>}

//                 </Col>
//             </Row>
//         </div>
//     )
// }

// export default Contacts

import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Pagination, Spinner } from 'react-bootstrap';
import { getContacts } from '../../api/contactApi';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';

function Contacts() {
    const abortConstants = useSignalAbort();
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const contactsPerPage = 13; // Adjust this number to set how many contacts you want per page

    // Function to get all contacts
    const getAllContacts = async () => {
        setIsLoading(true);
        try {
            const response = await getContacts(abortConstants.controllerConfig);
            setContacts(response?.data?.ContactUs);
            console.log(response);
            setIsLoading(false);
        } catch (error) {
            handleApiErrors(error, 'Contact', 'contact_response', 'contact_timeout', 'contact_request', setIsLoading);
        }
    };

    useEffect(() => {
        getAllContacts();
    }, []);

    // Get current contacts
    const indexOfLastContact = currentPage * contactsPerPage;
    const indexOfFirstContact = indexOfLastContact - contactsPerPage;
    const currentContacts = contacts.slice(indexOfFirstContact, indexOfLastContact);

    // Change page
    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(contacts.length / contactsPerPage)));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h5>Contact</h5>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    {isLoading ? (
                        <div className="d-flex justify-content-center mt-5">
                            <Spinner animation="border" />
                        </div>
                    ) : contacts.length > 0 ? (
                        <>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className="table-style table-heading">Name</th>
                                        <th className="table-style table-heading">Phone</th>
                                        <th className="table-style table-heading">Email</th>
                                        <th className="table-style table-heading">Company Name</th>
                                        <th className="table-style table-heading">Country</th>
                                        <th className="table-style table-heading">Source</th>
                                        <th className="table-style table-heading">Category</th>
                                        <th className="table-style table-heading">Suggestion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentContacts.map((item, index) => (
                                        <tr key={index}>
                                            <td className="table-style table-font">{item.Name}</td>
                                            <td className="table-style table-font">{item.PhoneNumber}</td>
                                            <td className="table-style table-font">{item.EmailId}</td>
                                            <td className="table-style table-font">{item.CompanyName}</td>
                                            <td className="table-style table-font">{item.CountryName}</td>
                                            <td className="table-style table-font">{item.SourceName}</td>
                                            <td className="table-style table-font">{item.CategoryName}</td>
                                            <td className="table-style table-font">{item.Suggestion}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {/* <Pagination className="justify-content-center">
                                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                                {[...Array(Math.ceil(contacts.length / contactsPerPage)).keys()].map(number => (
                                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                                        {number + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(contacts.length / contactsPerPage)} />
                            </Pagination> */}
                                <Pagination className="justify-content-end">
                                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                                <Pagination.Item>{currentPage}</Pagination.Item>
                                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(contacts.length / contactsPerPage)} />
                            </Pagination>
                        </>
                    ) : (
                        <h4 style={{ textAlign: 'center' }}>No data</h4>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default Contacts;
