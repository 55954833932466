import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import {Col, Container} from 'react-bootstrap';
import Subscribe from './Subscribe';
import Browsebytopic from './Browsebytopic';
import ProductUpdates from './ProductUpdates';
import OurBlogs from './OurBlogs';

function Resources() {
  return (
    <>
      <div className='Horizondal-Divider'>
        <Header />
      </div>

      <div>
        <Col xs={12} sm={12} md={12} lg={12} className="headline home-colC">
          <div className="mb-5 mt-5">
            <span className="heading-black">
              RCOR <span className="heading-blue">Resource Center</span>
            </span>
          </div>
        </Col>
        <div >
          <Container>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='headline home-colC mb-5'>
                <video 
                  // src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" 
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                  controls 
                  className="d-block w-50">
                  Your browser does not support the video tag.
                </video>
                {/* <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/t_V52wbRdjQ?si=k5wRnadfw29j0eRR"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="d-block w-50">
                </iframe> */}
              </div>
            </Col>
          </Container>
        </div>
        <div >
        <Browsebytopic/>
          </div>
          <div >
        <ProductUpdates/>
          </div>
      </div>
      <div>
        <Subscribe/>
      </div>
      <div>
        <OurBlogs/>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Resources;
