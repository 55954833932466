//feedback page
import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Button, Col, Spinner, InputGroup } from 'react-bootstrap'
import { IoChevronBack } from "react-icons/io5";
import Header from '../../components/header/Header'
import Footer from '../footer/Footer';
import { useNavigate } from 'react-router-dom';
import * as FeedbackApi from "../../api/feedbackApi"
import * as SourcesApi from "../../api/SourcesApi"
import * as CountryApi from "../../api/countryApi"
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from "../../handleApiErrors";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";

const defaultContactData = {
  Name: '',
  Email: '',
  Phone: '',
  CompanyName: '',
  Country: '',
  Sources: '',
  Suggestions: '',
};

function FeedBack() {
  const navigate = useNavigate(); // Initialize the navigate function
  const abortConstants = useSignalAbort()
  const defaultCountry = useSelector(state => state.geo.geoData)
  const [ContactData, setContactData] = useState(defaultContactData)
  const [Sources, setSources] = useState()
  const [Sourcesselect, setSourcesselect] = useState("")
  const [Country, setCountry] = useState()
  const [Countryselect, setCountryselect] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsLoading] = useState({})
  const [issubmit, setIsSubmit] = useState(false)
  const [validated, setValidated] = useState(false);

  useEffect(()=>{
    const country = Country?.find(item=>item.countryCode === defaultCountry.country_code2)
    console.log(country);
    setSelectedCountry(country)
  }, [Country])

  const Feedbackpage = async (data, abortToken) => {
    try {
      const result = await FeedbackApi.Feedback(data, abortToken);
      console.log(result);
      setIsSubmit(false);
      setContactData(defaultContactData)
      setValidated(false)
      setSourcesselect("")
      setCountryselect("")
    // alert(result.data.message);
    toast.success( result.data.message,{
      autoClose: 5000, // Close the toast after 5 seconds
  });
    } 
    catch (error) {
      setIsSubmit(false);
      console.error(error);
      handleApiErrors(error, "Feedbackpage", "Feedbackpage_response", "Feedbackpage_timeout", "Feedbackpage_request", setIsLoading);
    }
  };
 
  //Sources
  const getSource = async (abortToken) => {
    try {
      const result = await SourcesApi.getSources(abortToken);
      // console.log(result);
      setSources(result?.data?.Source);
      // setSourcesselect(result?.data?.Sources[0].SourcesId)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Sources', 'sources_response', 'sources_timeout', 'sources_request', setIsLoading)
    }
  };
 
   ///getCountry
   const getCountry = async (abortToken) => {
    try {
      const result = await CountryApi.getCountry(abortToken);
      console.log(result?.data?.Country);
      setCountry(result?.data?.Country);
      setCountryselect(result?.data?.Country[0].countryId, abortToken)
    } catch (error) {
      handleApiErrors(error, 'Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
    }
  };

  //form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    setIsSubmit(true);
  
    const form = event.currentTarget; // The form that triggered the event
    let newData = {
      PhoneNumber: "+"+selectedCountry.CallingCode+ContactData.Phone,
      EmailId: ContactData.Email,
      CompanyName: ContactData.CompanyName,
      Suggestion: ContactData.Suggestions,
      SourceId: Number(Sourcesselect),
      Name: ContactData.Name,
      CategoryName: ContactData.CategoryName,
      CountryId: Number(selectedCountry.countryId),
    };
  
    if (form.checkValidity() === false) {
      event.stopPropagation(); // Stops further event propagation if validation fails
      setIsSubmit(false);
      
    } else {
      Feedbackpage(newData, abortConstants.controllerConfig); // Call the function to submit the form data

    }
  
    setValidated(true); // Sets form as validated
  };
  
 
const handleChange = (e) => {
  setContactData({ ...ContactData, [e.target.name]: e.target.value })
}
 
const handleSources = (e) => {
  setSourcesselect(e.target.value);
  console.log(Sourcesselect);
}

const handleCountryselect = (country) => {
  if (country) {
    setSelectedCountry(country);
  }
};

const getAllData = useCallback((abortToken) => {
  getSource(abortToken);
  getCountry(abortToken);
}, []);

useEffect(() => {
  getSource(abortConstants.controllerConfig);
  getCountry(abortConstants.controllerConfig);
  // getAllData(abortConstants.controllerConfig);

  // return () => {
  //   // Cleanup logic if needed
  //   abortConstants.controller.abort();
  // };
}, [getAllData]);
 
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div>
        <div>
          <Header/>
        </div>
        <div  className='back mt-5 '>
      <IoChevronBack className='backicon ' onClick={() => navigate('/')}/>
        <span className='backtext  '>Back</span>
      </div>

        <div className='mt-1 mb-5  text-center'>
                <span className='heading-black'>FeedBack<span className='heading-blue'>  Form</span> </span>
            </div>

            <Container fluid  className='feed-container p-3'>

            <div className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={6} xl={6} sm={12} className="headline home-colC contactform " >
               <Form 
               className="w-100"
               noValidate
               validated={validated}
               onSubmit={handleSubmit} // Pass only one parameter
              >
 
                <Col  xs={12} md={12} lg={12} xl={12} sm={12} >
                <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                 <Form.Control 
                 className='contact-textfields' 
                 required 
                 type="text" 
                 maxLength="120"
                 name='Name'  
                 placeholder="Name" 
                 value={ContactData?.Name} 
                 onChange={handleChange} />
                 <Form.Control.Feedback type="invalid">
        Please provide Name.
      </Form.Control.Feedback>
              </Form.Group>
             
                </Col>
 
                <Col xs={12} md={12} lg={12} xl={12} sm={12}>
  <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
    {/* <Form.Label>Phone Number <span className='asterik'>*</span> </Form.Label> */}
    <InputGroup>
      <Dropdown style={{ height: '55px', marginTop: '18px' }}>
        <Dropdown.Toggle
        className="textfields"
          id="dropdown-basic"
          style={{
            // height: '100%',
            // width: '100%',
            backgroundColor: '#F6F6F6',
            color: 'black',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '10px',
            borderTopRightRadius: 0, borderBottomRightRadius: 0,
          }}
        >
          {selectedCountry && (
            <>
              <img
                src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                alt={selectedCountry.countryName}
                className="country-flag"
                style={{ width: '26px', marginRight: '10px' }}
              />
              +{selectedCountry.CallingCode}
            </>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Country ? (
            <>
              {Country.length !== 0 ? (
                <>
                  {Country.map((item) => (
                    <Dropdown.Item
                      key={item.countryName}
                      name={item.countryName}
                      value={item.countryId}
                      onClick={() => handleCountryselect(item)}
                    >
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                        alt={item.countryName}
                        className="country-flag"
                        style={{ width: '26px', marginRight: '10px' }}
                      />
                      {item.countryName} (+{item.CallingCode})
                    </Dropdown.Item>
                  ))}
                </>
              ) : (
                <h4 style={{ textAlign: "center" }}>No data</h4>
              )}
            </>
          ) : (
            <>
              {renderLoading("country") && (
                <h5 style={{ textAlign: "center" }}>No data</h5>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Form.Control
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        className='textfields'
        required
        type="phone"
        pattern="[0-9]*"
        inputMode="numeric"
        aria-describedby="inputGroupPrepend"
        maxLength={selectedCountry?.MobileNumberlen}
        name='Phone'
        placeholder="Phone number"
        value={ContactData?.Phone}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="invalid">
        Please provide Phone Number.
      </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
</Col>
 
               <Col  xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2 '>
                <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                 <Form.Control 
                 className='contact-textfields' 
                 required 
                 type="email"
                 maxLength="100"
                 name='Email' placeholder="Email" 
                 value={ContactData?.Email} onChange={handleChange} />
                 <Form.Control.Feedback type="invalid">
        Please provide Email ID.
      </Form.Control.Feedback>
                </Form.Group>
                 
               </Col>
 
               
 
                <Col  xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2 '>
                <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                 <Form.Control 
                 className='contact-textfields' 
                 required 
                 type="text" 
                 name='CompanyName' 
                 maxLength="120"
                 placeholder="Company Name"   
                 value={ContactData?.CompanyName} 
                 onChange={handleChange}/>
                 <Form.Control.Feedback type="invalid">
        Please provide Company Name.
      </Form.Control.Feedback>
                </Form.Group>
                 
               </Col>
 
               <Col  xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2  '>
               <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                   
                   <Form.Select
                     className='contact-textfields'
                     required
                     name='CategoryName'
                     placeholder="Category Name"
                     value={ContactData?.CategoryName}
                     onChange={handleChange}
                   >
                     <option value="" disabled selected>Category</option>
                     <option value="NewfeatureRequest">New feature Request</option>
                     <option value="BugsReport">Bugs Report</option>
                     <option value="General">General</option>
                   </Form.Select>
                   <Form.Control.Feedback type="invalid">
        Please Select Category.
      </Form.Control.Feedback>
                 </Form.Group>
                 
               </Col>
 
                <Col  xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2  '>
                <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                <Form.Select
                className='contact-textfields'
                            required
                            name="Sources"
                            value={Sourcesselect}
                            onChange={handleSources}
                          >
                            <option value="" disabled selected>Source</option>
                             {Sources ? (
                          <>
                            {Sources.length !== 0 ? (
                              <>
                                {Sources?.map((item) => (
                                  <option
                                  key={item.SourceId}
                                  // eventKey={item.SourcesName}
                                  name={item.SourceName}
                                  value={item.SourceId}
                                >
                                  {item.SourceName}
                                </option>
                                ))}
                              </>
                            ) : (
                              <span style={{ textAlign: "center" }}>No data</span>
                            )}
                          </>
                        ) : (
                          <>
                            {renderLoading("sources") && (
                              <span style={{ textAlign: "center" }}>No data</span>
                            )} 
                          </>
                        )}
                          </Form.Select>  
                          <Form.Control.Feedback type="invalid">
        Please Select Source.
      </Form.Control.Feedback>  
                   </Form.Group>
                    </Col>
 
                    <Col  xs={12} md={12} lg={12} xl={12} sm={12} className='pt-2  '>
                      <Form.Group className='contactform-textgroup'>
                      <Form.Control 
                      className='contact-textfields' 
                      as="textarea" 
                      required type='text' 
                      name='Suggestions' 
                      maxLength="250"
                      style={{ height: '150px' }} 
                      placeholder="Your Feedback / Suggestions" 
                      value={ContactData?.Suggestions} 
                      onChange={handleChange}  />
                      <Form.Control.Feedback type="invalid">
        Please provide Suggestions.
      </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
 
 
               <div className='  text-center pt-5 pb-4'>
               {!issubmit && <Button type='submit' className='button submit-color mt-4 mb-4'>SUBMIT</Button>}
                    {issubmit &&
                      <Button className='button button-disable d-flex justify-content-center align-items-center mt-4 mb-4' >
                        <span className='me-2'> Submitting....</span>
                        <Spinner animation="border" variant="light" size='sm' />
                      </Button>}
               </div>
             
            </Form>
            </Col>
          </div>
            </Container>

            <div className='empty-div' > </div>
           
<div>
  <Footer/>
</div>
    </div>
  )
}

export default FeedBack