import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spinner, Pagination } from 'react-bootstrap';
import CrudActions from '../../components/CrudActions';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { deleteLead, getLead } from '../../api/leadsApi';
import { useSelector } from 'react-redux';
import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';
import Filter from '../../components/Filter';
import { getUsers } from '../../api/userApi';
import BlogForm from './BlogForm';
import { deleteBlog, getBlog } from '../../api/blogApi';
import ModalPopup from '../../components/ModalPopup';
import { Link } from 'react-router-dom';

const initialFilterValues = {
  Name: '',
  // CompanyName: '',
  // IndustryName: '',
  // PhoneNumber: '',
  // EmailId: '',
  Date: '',
  // SourceName: '',
  Status: ''
}
function AdminBlog() {
  const abortConstants = useSignalAbort();
  const user = useSelector(state => state.adminUser);
  const menu = useSelector(state => state.userMenu);
  const [blogs, setBlogs] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 13; // Adjust this number to set how many leads you want per page
  const [filters, setFilters] = useState(initialFilterValues);
  const [modalShow, setModalShow] = useState(false);
  const [content, setContent] = useState();

  // Function to get all leads
  const getAllBlogs = async () => {
    setIsLoading(true);
    try {
      const response = await getBlog(abortConstants.controllerConfig);
      console.log(response);
        // Decode content of each blog
        const decodedBlogs = response?.data?.Blogs.map(blog => ({
          ...blog,
          Content: decodeURIComponent(blog.Content)
        }));
  
        setBlogs(decodedBlogs);
      setIsLoading(false);
    } catch (error) {
      handleApiErrors(error, 'Users', 'user_response', 'user_timeout', 'user_request', setIsLoading);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  // Function for deleting lead
  const deleteBlogs = async (id) => {
    try {
      const result = await deleteBlog(id, abortConstants.controllerConfig);
      if (result) {
        successToast(result?.data?.message);
        getAllBlogs();
      }
    } catch (error) {
      handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
    }
  };

  const handleFilterChange = (e, columnName) => {
    setFilters({
      ...filters,
      [columnName]: e.target.value
    });
  };

  const clearFilter = (columnName) => {
    setFilters({
      ...filters,
      [columnName]: ''
    });
  };

  // const getFilteredLeads = () => {
  //   return leads.filter(lead => {
  //     // const leadDate = new Date(lead.CreatedDate).toISOString().split('T')[0];
  //     return (
  //       (filters.Name === '' || lead.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
  //       // (filters.CompanyName === '' || lead.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase())) &&
  //       // (filters.IndustryName === '' || lead.IndustryName.toLowerCase().includes(filters.IndustryName.toLowerCase())) &&
  //       // (filters.PhoneNumber === '' || lead.PhoneNumber.includes(filters.PhoneNumber)) &&
  //       // (filters.EmailId === '' || lead.EmailId.toLowerCase().includes(filters.EmailId.toLowerCase())) &&
  //       (filters.Date === '' || convertDateForDisplay(lead.CreatedDate) === convertDateForDisplay(filters.Date)) &&
  //       // (filters.SourceName === '' || lead.SourceName.toLowerCase().includes(filters.SourceName.toLowerCase())) &&
  //       (filters.Status === '' || lead.StatusName.toLowerCase().includes(filters.Status.toLowerCase()))
  //     );
  //   });
  // };

  // Get current leads
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = blogs?.slice(indexOfFirstLead, indexOfLastLead);

  // console.log(leads.filter(item =>convertDateForDisplay(item.CreatedDate) === '15-05-2024'));
  // Change page
  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(blogs?.length / leadsPerPage)));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const displayContent = (item) => {
    setContent(item)
    setModalShow(true)
  };

  // Function to render only the first two lines of the content
  const renderShortContent = (htmlString) => {
    const lines = htmlString.slice(0, 100); // Get the first two lines
    return lines; // Add ellipsis if content is truncated
  };

  return (
    <div>
      <Row>
        <Col xs={6}>
          <h5>Blog Management</h5>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <CrudActions crudNo={1} form={BlogForm} title="New Blog" isEdit={false} getData={getAllBlogs} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          {isLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner animation="border" />
            </div>
          ) : blogs?.length > 0 ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="table-style table-heading">Title
                      {/* <Filter columnName="Name" handleFilterChange={handleFilterChange} /> */}
                    </th>
                    <th className="table-style table-heading">Image</th>
                    <th className="table-style table-heading">Author Name</th>
                    <th className="table-style table-heading">Author Image</th>
                    <th className="table-style table-heading">Content</th>
                    <th className="table-style table-heading">Created Date
                      {/* <Filter columnName="Date" handleFilterChange={handleFilterChange} /> */}
                    </th>
                    <th className="table-style table-heading">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLeads?.map((item, index) => (
                    <tr key={index}>
                      <td className="table-style table-font">{item.Title}</td>
                      <td className="table-style table-font">
                        {item.Image &&
                          <img src={`${process.env.REACT_APP_BASE_URL}${item.Image}`} alt="Image" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                        }
                      </td>
                      <td className="table-style table-font">{item.AuthorName}</td>
                      <td className="table-style table-font">
                        {item.AuthorImage &&
                          <img src={`${process.env.REACT_APP_BASE_URL}${item.AuthorImage}`} alt="Image" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                        }
                      </td>
                      <td className="table-style table-font">
                        <Link onClick={() => displayContent(item?.Content)}>View</Link>
                        {/* <ModalPopup show={modalShow} title='Blog Content' onHide={() => setModalShow(false)}  form={<div dangerouslySetInnerHTML={{ __html: renderShortContent(item?.Content) }} /> }/> */}
                        {/* <div dangerouslySetInnerHTML={{ __html: renderShortContent(item?.Content) }} /> */}
                      </td>
                      <td className="table-style table-font">{convertDateForDisplay(item.CreatedDate)}</td>
                      <td className="table-style table-font">
                        <CrudActions id={item.BlogsId} crudNo={2} form={BlogForm} title="Edit Blog" isEdit={true} getData={getAllBlogs} />
                        <CrudActions id={item.BlogsId} crudNo={5} getData={getAllBlogs} deleteData={deleteBlogs} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="justify-content-end">
                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                <Pagination.Item>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(blogs.length / leadsPerPage)} />
              </Pagination>
            </>
          ) : (
            <h4 style={{ textAlign: 'center' }}>No data</h4>
          )}
        </Col>
      </Row>
      <ModalPopup show={modalShow} title='Blog Content' onHide={() => setModalShow(false)} form={content? <div dangerouslySetInnerHTML={{ __html: content }} />:''} />
    </div>
  );
}

export default AdminBlog;
