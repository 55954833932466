import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import WhoWeAre from './WhoWeAre'
import OurCore from './OurCore'
import OurMission from './OurMission'
import OurStory from './OurStory'
import Why from './Why'
import Global from './Global'

function About() {

    return (
        <div>
            <div className='Horizondal-Divider'>
                <Header />
            </div>
            <div>
                <WhoWeAre />
            </div>
            <div>
                <OurCore />
            </div>
            <div>
                <OurMission />
            </div>
            <div>
                <OurStory />
            </div>
            <div>
                <Why />
            </div>
            <div>
                <Global />
            </div>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>

    )
}

export default About