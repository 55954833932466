import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import FormContact from '../../components/contact/FormContact'

function Contact() {
  return (
    <>
    <div className='Horizondal-Divider'>  
    <Header/>
    </div>
    <div>
      <FormContact/>
      </div>
    <div>
      <Footer/> 
    </div>
    </>
  )
}

export default Contact