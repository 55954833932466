import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Spinner, Pagination } from 'react-bootstrap';
import CrudActions from '../../components/CrudActions';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { useSelector } from 'react-redux';
import { convertDateForDisplay, renderLoading, successToast } from '../../../HelperFile';
import Filter from '../../components/Filter';
import { deleteUsertype, getActions,  getUserType } from '../../api/userApi';
import RoleForm from './RoleForm';
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa';

const initialFilterValues = {
  Name: '',
  // CompanyName: '',
  // IndustryName: '',
  // PhoneNumber: '',
  // EmailId: '',
  Date: '',
  // SourceName: '',
  Status: ''
}
function Roles() {
  const abortConstants = useSignalAbort();
  const user = useSelector(state => state.adminUser);
  const menu = useSelector(state => state.userMenu);
  const [roles, setRoles] = useState();
  const [isNewRole, setIsNewRole] = useState(false)
  const [roleId, setRoleId] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 13; // Adjust this number to set how many leads you want per page
  const [filters, setFilters] = useState(initialFilterValues);

  // Function to get all leads
  const getAllUserType = async () => {
    setIsLoading(true);
    try {
      const response = await getUserType(abortConstants.controllerConfig);
      console.log(response);
      setRoles(response?.data?.UserType);
      setIsLoading(false);
    } catch (error) {
      handleApiErrors(error, 'Roles', 'role_response', 'role_timeout', 'role_request', setIsLoading);
    }
  };

  useEffect(() => {
    getAllUserType();
  }, []);

  // Function for deleting lead
  const deleteRole = async (id) => {
    try {
      const result = await deleteUsertype(id, abortConstants.controllerConfig);
      if (result) {
        successToast(result?.data?.message);
        getAllUserType();
      }
    } catch (error) {
      handleApiErrors(error, 'Delete', 'delete_response', 'delete_timeout', 'delete_request', setIsLoading);
    }
  };

  const handleFilterChange = (e, columnName) => {
    setFilters({
      ...filters,
      [columnName]: e.target.value
    });
  };

  const clearFilter = (columnName) => {
    setFilters({
      ...filters,
      [columnName]: ''
    });
  };

  // const getFilteredLeads = () => {
  //   return leads.filter(lead => {
  //     // const leadDate = new Date(lead.CreatedDate).toISOString().split('T')[0];
  //     return (
  //       (filters.Name === '' || lead.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
  //       // (filters.CompanyName === '' || lead.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase())) &&
  //       // (filters.IndustryName === '' || lead.IndustryName.toLowerCase().includes(filters.IndustryName.toLowerCase())) &&
  //       // (filters.PhoneNumber === '' || lead.PhoneNumber.includes(filters.PhoneNumber)) &&
  //       // (filters.EmailId === '' || lead.EmailId.toLowerCase().includes(filters.EmailId.toLowerCase())) &&
  //       (filters.Date === '' || convertDateForDisplay(lead.CreatedDate) === convertDateForDisplay(filters.Date)) &&
  //       // (filters.SourceName === '' || lead.SourceName.toLowerCase().includes(filters.SourceName.toLowerCase())) &&
  //       (filters.Status === '' || lead.StatusName.toLowerCase().includes(filters.Status.toLowerCase()))
  //     );
  //   });
  // };

  // Get current leads
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = roles?.slice(indexOfFirstLead, indexOfLastLead);

  // console.log(leads.filter(item =>convertDateForDisplay(item.CreatedDate) === '15-05-2024'));
  // Change page
  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(roles?.length / leadsPerPage)));
  };

  const prevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      {!isNewRole &&
        <div>
          <Row>
            <Col xs={6}>
              <h5>Role Management</h5>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <FaPlus className="create-icon" onClick={() => { setRoleId(0); setIsNewRole(true); setIsEdit(false)}} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              {isLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <Spinner animation="border" />
                </div>
              ) : roles?.length > 0 ? (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="table-style table-heading">Role
                          {/* <Filter columnName="Name" handleFilterChange={handleFilterChange} /> */}
                        </th>
                        <th className="table-style table-heading">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentLeads.map((item, index) => (
                        <tr key={index}>
                          <td className="table-style table-font">{item.usertypename}</td>
                          <td className="table-style table-font">
                          <FaEdit className='edit-icon' onClick={() => {setRoleId(item.usertypeid); setIsEdit(true); setIsNewRole(true); }} />                            
                          <FaTrash className='delete-icon' onClick={()=>deleteRole(item.usertypeid)}/>                          
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination className="justify-content-end">
                    <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                    <Pagination.Item>{currentPage}</Pagination.Item>
                    <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(roles.length / leadsPerPage)} />
                  </Pagination>
                </>
              ) : (
                <h4 style={{ textAlign: 'center' }}>No data</h4>
              )}
            </Col>
          </Row>
        </div>
      }

      {isNewRole && <RoleForm setIsNewRole={setIsNewRole} roleId={roleId} isEdit={isEdit} getData={getAllUserType}/>}
    </>
  );
}

export default Roles;
