import React, { useEffect, useState } from 'react'
import { IoChevronBack } from "react-icons/io5";
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Col, Row, Button, Card, Image, Spinner } from 'react-bootstrap'
import { getBlogById } from '../../AdminPanel/api/blogApi';
import { useSignalAbort } from '../../api/useSignalAbort';
import { handleApiErrors } from '../../handleApiErrors';
import { formatDate, renderLoading } from '../../HelperFile';

function BlogReadmore() {
  const navigate = useNavigate();
  const { id } = useParams()
  const abortConstants = useSignalAbort()
  const [blogs, setBlogs] = useState()
  const [isLoading, setIsLoading] = useState({})

  useEffect(() => {
    const getBlog = async (blogId) => {
      try {
        const result = await getBlogById(blogId, abortConstants.controllerConfig)
        setBlogs({ ...result?.data?.Blogs, Content: decodeURIComponent(result?.data?.Blogs?.Content) })
        // setAuthorPhoto(blog?.AuthorImage? `${process.env.REACT_APP_BASE_URL}${blog?.AuthorImage}` :"")
      }
      catch (error) {
        handleApiErrors(error, 'Get Blog', 'getBlog_response', 'getBlog_timeout', 'getBlog_request', setIsLoading)
      }
    }
    getBlog(id)
  }, [id])

  return (
    <div>
      <div className='Horizondal-Divider'>
        <Header />
      </div>
      <div>
        <Container fluid >
          <div className='back mt-5 common-icon' onClick={() => navigate('/blog')}>
            <IoChevronBack className='backicon' />
            <span className='backtext'>Back</span>
          </div>
          <div >
            {blogs ?
              <Container >
                <Row  >
                  <Col xs={12} sm={12} md={12} lg={12} className="image-container ">
                    <div className="mt-lg-5 mb-3">
                      <span className="heading-black">
                        {blogs?.Title}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row  className='px-lg-5 pe-lg-5'>
                  <Col xs={12} sm={12} md={12} lg={12} className="headline home-colL" >
                    <div >
                      <Image src={`${process.env.REACT_APP_BASE_URL}${blogs?.AuthorImage}`}
                        alt="image"
                        className="headline home-img4" roundedCircle />
                    </div>&nbsp;&nbsp;
                    <div>
                      <div className='heading-green1'>{blogs?.AuthorName}</div>
                      <div className="heading-blacksub4">{formatDate(blogs?.CreatedDate)} - 5 min read</div>
                    </div>

                  </Col>
                </Row>
                <Row className='px-lg-5 pe-lg-5'>
                  <Col xs={12} sm={12} md={12} lg={12} >
                    <div className=' mb-5 pt-2 w-100'>
                      <img src={`${process.env.REACT_APP_BASE_URL}${blogs?.Image}`}
                        alt="image"
                        className="d-block w-100" />
                    </div>
                  </Col>
                </Row>
                <Row className='px-lg-5 pe-lg-5'> 
                  <Col xs={12} sm={12} md={12} lg={12} >
                    <div className="">
                      <div className='heading-blacksub4' dangerouslySetInnerHTML={{ __html: blogs?.Content }} />
                      <div className='d-flex flex-column  mt-3'>
                        <span className='blog subtitle-text'>You made it to the end? Thank you for reading!! </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              :
              <>{!renderLoading(isLoading, 'getBlog') ?
                <div className='d-flex justify-content-center mb-5'><Spinner animation="border" /></div> :
                <span className='d-flex justify-content-center mb-5'>No Blogs to display</span>
              }</>
            }
          </div>
        </Container>
      </div>
      <div >
        <Footer />
      </div>
    </div>
  )
}

export default BlogReadmore