import React from 'react'
import { Button, Card, Col, Container, Row, } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function OurBlogs() {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <div >
      <Container>
        <div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className='Heading green-p ' >
              <span className="heading-green">Our Blogs :</span>
            </Col>
          </Row>
        </div>
        <div >
          <Row >
            <Col xs={12} sm={12} md={12} lg={1} className='mt-5 headline home-colC mb-5'>
              <div className='head home-img mb-5'>
                <img src={process.env.PUBLIC_URL + "/assets/images/ArrowPrevious.png"}
                  alt="Catchy Headline"
                  className="headline home-img3" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className='mt-5 headline home-colC mb-5'>
              <Card className='card boder-r'>
                <img src={process.env.PUBLIC_URL + "/assets/images/Blog1.png"}
                  alt="Catchy Headline"
                  className="d-block w-100" />
                <span className="heading-blacksub3 mx-3 m-1 mt-3">Blog 1</span>
                <span className="heading-blacksub4 mx-3 m-1">Lorem ipsum dolor sit amet consectetur. Sit a id tristique at.
                  Massa ipsum cras massa sit. Risus ut ultrices est turpis tellus ...</span>
                <div className='headline home-col px-3 mb-2'>
                  <Button className='custom readmore-button'
                  // onClick={() => navigate('/ReadMore')}
                  >
                    Read More
                  </Button>
                  <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                    alt="Catchy Headline"
                    className="headline home-img3"
                    onClick={() => { window.location.href = '/ReadMore'; }}
                  />
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className='mt-5 headline home-colC mb-5'>
              <Card className='card boder-r'>
                <img src={process.env.PUBLIC_URL + "/assets/images/Blog2.png"}
                  alt="Catchy Headline"
                  className="d-block w-100" />
                <span className="heading-blacksub3 mx-3 m-1 mt-3">Blog 2</span>
                <span className="heading-blacksub4 mx-3 m-1">Lorem ipsum dolor sit amet consectetur. Sit a id tristique at.
                  Massa ipsum cras massa sit. Risus ut ultrices est turpis tellus ...</span>
                <div className='headline home-col px-3 mb-2'>
                  <Button className='custom readmore-button' >
                    Read More
                  </Button>
                  <img src={process.env.PUBLIC_URL + "/assets/images/LeftArrow.png"}
                    alt="Catchy Headline "
                    className="headline home-img3"
                    onClick={() => { window.location.href = '/ReadMore'; }} />
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={1} className='mt-5 headline home-colC mb-5'>
              <div className='head home-img mb-5'>
                <img src={process.env.PUBLIC_URL + "/assets/images/ArrowNext.png"}
                  alt="Catchy Headline"
                  className="headline home-img3" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default OurBlogs

