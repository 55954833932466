import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function MainHome() {

    const navigate = useNavigate()
    const token = useSelector(state => state?.auth?.adminToken)
    const user = useSelector(state => state?.auth?.adminUser)
    const localToken = localStorage.getItem("intelliger_admin_token")
    const menuArray = useSelector(state => state?.auth?.userMenu)

    useEffect(() => {
        if (localToken) {
            const userHome = 'Leads'
            if (userHome) {
                setTimeout(() => {
                    window.location.href = `/${userHome}`
                }, 4000)
            }
            else {
                navigate('/Login')
            }
        }
        else {
            navigate('/Login')
        }
    }, [localToken, menuArray])

    return (
        <div className='d-flex flex-column align-items-center justify-content-center vh-100 login bg-image'>
            {/* Centering the content vertically and horizontally */}
            <Row >
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' width={121} height={45} />
                    <span className='mt-3'>Welcome, {user?.firstname}</span>
                    <span className='mt-3'>Please wait. We are loading your data</span>
                    <img className='mt-3 flip-animation' src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} width={114} height={100} />
                </Col>
            </Row>
        </div>

    )
}

export default MainHome