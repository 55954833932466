import React from 'react';
import { Container, Nav } from 'react-bootstrap';

function MenuBar({ mainContent, setMainContent, setIsMenuOpen }) {

  const handleMenuClick = (content) => {
    setMainContent(content);
    if (window.innerWidth <= 576) { // Check if screen width is less than or equal to 576px (mobile view)
      setIsMenuOpen(false);
    }
  };

  return (
    <div>
      <Container className='mt-lg-5 mb-5 px-lg-5'>
        <Nav defaultActiveKey="/home" className="flex-column">
          <div className='mt-3 mb-3'><span className='heading-blacksub'>Dashboards</span></div>
          <Nav.Link eventKey="link-1" className={` headline menu-text  menu ${mainContent === 1 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(1)}>Home Dashboard</Nav.Link>
          <Nav.Link eventKey="link-2" className={` headline menu-text menu ${mainContent === 2 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(2)}>Sales Dashboard</Nav.Link>
          <Nav.Link eventKey="link-3" className={` headline menu-text menu ${mainContent === 3 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(3)}>Purchase Dashboard</Nav.Link>
          <Nav.Link eventKey="link-4" className={` headline menu-text menu ${mainContent === 4 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(4)}>Comparison Dashboard</Nav.Link>
          <div className='menu divder-line pb-3 pt-3'><span className='heading-blacksub'>Reports</span></div>
          <Nav.Link eventKey="link-5" className={` headline menu-text menu ${mainContent === 5 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(5)}>Day Book</Nav.Link>
          <Nav.Link eventKey="link-6" className={` headline menu-text menu ${mainContent === 6 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(6)}>Cash & Bank</Nav.Link>
          <Nav.Link eventKey="link-7" className={` headline menu-text menu ${mainContent === 7 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(7)}>Payment & Receipt</Nav.Link>
          <Nav.Link eventKey="link-8" className={` headline menu-text menu ${mainContent === 8 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(8)}>Sales & Purchases</Nav.Link>
          <Nav.Link eventKey="link-9" className={` headline menu-text menu ${mainContent === 9 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(9)}>Journal & Ledger</Nav.Link>
          <Nav.Link eventKey="link-10" className={` headline menu-text menu ${mainContent === 10 ? "active-link active-divder" : "font-head divder-line"}`} onClick={() => handleMenuClick(10)}>Inventory Report</Nav.Link>
        </Nav>
      </Container>

    </div>
  );
}

export default MenuBar;
