import { toast } from "react-toastify"

//fuction to change format of date which is set by user
const changeFormat = (date, formatValue) => {
    const newDate = date.format(formatValue.toUpperCase())
    return newDate
}

//to pop up a success toast message
const successToast =(message) =>{
    toast.success(message, {
        autoClose: 3000
    })
}

//function to check loading errors
const renderLoading = (isLoading, item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ) {
      return true
    }
    else {
      return false
    }
  }

    //convert date 2024-02-26 00:00:00  into 26-02-2024
const convertDateForDisplay = (date) => {
    const inputDate = new Date(date);
    // const formattedDate = inputDate.toISOString().split('T')[0];
    const formattedDate = inputDate
      .toLocaleDateString("en-GB")
      .split("/")
      .join("-");
    return formattedDate;
  };
  
  //convert date into yyyy-mm-dd
  const convertDateForApi = (date) => {
      const inputDate = new Date(date);
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Adding leading zero if needed
      const day = inputDate.getDate().toString().padStart(2, '0'); // Adding leading zero if needed
      return `${year}-${month}-${day}`;
  };
  
  //convert date "2024-06-04 12:06:00" into a format like "May 22, 2024"
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }
export {changeFormat, successToast, renderLoading, convertDateForApi, convertDateForDisplay, formatDate}