import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Spinner } from 'react-bootstrap'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'
import { createPromo, getPromoById, updatePromo } from '../../api/promoApi'

const initialValues = {
    code: '',
    Description: '',
}

const validationRules = {
    code: { required: true },
    Description: { required: true },
};

function PromocodeForm({ id, isEdit, closeModal, getData }) {
    const abortConstants = useSignalAbort()
    const [promoData, setPromoData] = useState(initialValues)
    const [formErrors, setFormerrors] = useState({})
    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState({})

    useEffect(() => {
        if (isEdit) {
            const getPromocode = async () => {
                try {
                    const result = await getPromoById(id, abortConstants.controllerConfig)
                    console.log(result);
                    setPromoData(result.data.Discount)
                }
                catch (error) {
                    handleApiErrors(error, 'Get Promo code', 'getpromo_response', 'getpromo_timeout', 'getpromo_request', setIsLoading)
                }
            }
            getPromocode()
        }
    }, [id])

    //for creating blog
    const createPromoCode = async (data, abortToken) => {
        console.log(data);
        try {
            const result = await createPromo(data, abortToken)
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Add Promo Code', 'addpromo_response', 'addpromo_timeout', 'addpromo_request', setIsLoading)
        }
    }

    //for updating blog
    const updatePromoCode = async (id, data, abortToken) => {
        console.log(data);
        try {
            const result = await updatePromo(id, data, abortToken)
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Promo Code', 'editpromo_response', 'editpromo_timeout', 'editpromo_request', setIsLoading)
        }
    }

    //For form validation
    const validate = (values) => {
        const errors = validateForm(values, validationRules);
        console.log(errors);
        setFormerrors(errors)
        if (Object.keys(errors).length === 0) {
            return true;
        }
        else {
            setIsSaving(false)
            return false;
        }
    }

    //form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSaving(true)
        setFormerrors({})
        console.log(promoData);
        const data = {
            Code: promoData.code,
            Description: promoData.Description,
            IsActive: 1,
            StartDate: "2025-06-02",
            EndDate: "2025-06-02"
        }
        if (validate(promoData)) {
            if (isEdit) {
                updatePromoCode(id, data, abortConstants.controllerConfig)
            } else {
                createPromoCode(data, abortConstants.controllerConfig)
            }
        }
        setValidated(true)
    }

    //To set input values for form
    const handleChange = (e) => {
        // setFormerrors({})
        setPromoData({ ...promoData, [e.target.name]: e.target.value },)
    }

    return (
        <div >
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12}>
                        <Form.Group controlId='title'>
                            <Form.Label className='text-left label form-label' >Promo Code<span className='asterik'> *</span></Form.Label>
                            <Form.Control readOnly={isEdit} required size='sm' type='type' name='code' value={promoData?.code} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{formErrors?.Name}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col xs={12}>
                        <Form.Group controlId='author'>
                            <Form.Label className='text-left label form-label' >Promoter Name<span className='asterik'> *</span></Form.Label>
                            <Form.Control maxLength={100} required size='sm' type='type' name='Description' value={promoData?.Description} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='mt-3 d-flex justify-content-end' >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                    // disabled={isSaving}
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button className='button save mx-2' onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </div>
    )
}

export default PromocodeForm