import React, { useState } from 'react'
import { NavDropdown, Navbar } from 'react-bootstrap';
import { FaUserCog } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setLogout } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

function Header() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logOut = () => {
        localStorage.removeItem("intelliger_admin_token")
        // localStorage.clear()
        dispatch(setLogout())
        setTimeout(() => {
            navigate('/Login')
        }, 2000)
    }

    const handleLogoutConfirmation = () => {
        const confirmation = window.confirm("Are you sure you want to logout?");
        if (confirmation) {
            logOut()
        } else {

        }
    };
    return (
        <Navbar className='header px-3 pe-3'>
            <Navbar.Brand>
                <img src={process.env.PUBLIC_URL + `/assets/images/RCORLogo.png`} height={40} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                    <NavDropdown title={(<FaUserCog />)} id="basic-nav-dropdown" drop='start'>
                        <NavDropdown.Item onClick={() => { handleLogoutConfirmation() }}>LogOut</NavDropdown.Item>
                    </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header