import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {Col, Container, Row} from 'react-bootstrap'
import { IoIosArrowDropdown  } from "react-icons/io";

// Define your custom toggle component
const ContextAwareToggle = ({ eventKey, children }) => {
    const decoratedOnClick = useAccordionButton(eventKey);


  return (
    <div onClick={decoratedOnClick} style={{ display: 'inline-block', cursor: 'pointer' }}>
      <IoIosArrowDropdown  className="icon" style={{ marginRight: '8px', color:'#489C8F' }}  />
      <span className="heading-blacksub2" >{children}</span>
    </div>
  );
};

function HomeDashboard() {
  return (
    <div>
      <div className="headline home-colL">
        <span className="headline home-maing">Home Dashboard</span>
      </div>
      <div className="headline home-maing mt-5">
        <img
          src={process.env.PUBLIC_URL + '/assets/images/HomeDashboard.png'}
          alt="Catchy Headline"
          className="d-block w-100 features align-img"
        />
      </div>
      <Container className='container-color2 mb-5'>
      <div className="headline home-colL  mt-5">
        <div>
          <div className="vertical-divider mt-3">
          <span className="heading-blacksub mx-3">Features</span></div>
        </div>
      </div>
      <div className='pt-3 pb-5'>
        <Accordion>
          <Row>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="0">Principal Accounts</ContextAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='heading-blacksub4 px-4'>Monitor the performance of your key financial accounts.</Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="1" >Cash Flow</ContextAwareToggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className='heading-blacksub4 px-4'>Understand your business by assessing the movement of cash & bank transactions. </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="3" >Financial Overview</ContextAwareToggle>
            <Accordion.Collapse eventKey="3">
            <Card.Body className='heading-blacksub4 px-4'>Comprehensive snapshot of your financial standing.</Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="4" >Ratio Analysis</ContextAwareToggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body className='heading-blacksub4 px-4'>Ratio analysis provided for informed decision making</Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="5">Income vs Expense</ContextAwareToggle>
            <Accordion.Collapse eventKey="5">
            <Card.Body className='heading-blacksub4 px-4'>
              Income refers to the revenue generated from the sale of goods or services, as well as any other sources such as investments or interest earned. 
              Expenses encompass all the costs incurred to run the business and produce its goods or services. This includes expenditures on raw materials, labor, rent, utilities, marketing, and administrative overhead.
              </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="6" >Assets vs Liability</ContextAwareToggle>
            <Accordion.Collapse eventKey="6">
            <Card.Body className='heading-blacksub4 px-4'>
            Assets are resources owned or controlled by the company that provide future economic benefits. Examples of assets are property, equipment, inventory, cash, etc. 
            Liability represents the company's obligations or debts to external parties. This includes loans, accounts payable, accrued expenses, and other financial obligations.
            </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="7" >Highlights</ContextAwareToggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body className='heading-blacksub4 px-4'>Pinpoint critical movements within your business, making it easier to spot big moves and take decisive action.</Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="8">Payables & Receivables</ContextAwareToggle>
            <Accordion.Collapse eventKey="8">
            <Card.Body className='heading-blacksub4 px-4'>Payables track your outstanding debts to suppliers & Receivables monitors outstanding payments from customers. </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="9" >Stock in hand</ContextAwareToggle>
            <Accordion.Collapse eventKey="9">
            <Card.Body className='heading-blacksub4 px-4'>This widget provides input on your stock and value of your stock based on category or Product Groups that you’ve created</Card.Body>
          </Accordion.Collapse>
          </Col>
          </Row>
      </Accordion>
        </div>
        </Container>
    </div>
  );
}

export default HomeDashboard;
