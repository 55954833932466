import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
axios.defaults.mode='cors'

const API_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = () => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    withCredentials: false,
  });

   // Add an interceptor to capture and handle response errors
//    instance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       if (error.response) {
//         if (token && error.response.status === 401) {
//           console.log(error);
//           navigateToPage()
//         } 

//       } else if (error.request) {
//         console.error('Server error:', error);

//       } else {
//         // console.error('Error:', error.message);
//       }
//       return Promise.reject(error);
//     });

  return instance;
};

export default axiosInstance;
