// import React, { useState, useEffect } from 'react';
// import { Table } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
// import Collapse from 'react-bootstrap/Collapse';

// function PermittedActions({ subMenu, handleChange }) {

//     let actionArray = []
//     let actionName = []

//     useEffect(() => {
//         let menu = [...new Map(subMenu?.map(item =>
//             [item['subMenu_id'], item])).values()].sort((a, b) => a.subMenu_order - b.subMenu_order)

//         subMenu?.forEach(element => {
//             (!actionName.includes(element['actions_name'])) && actionName.push(element['actions_name'])
//         });

//         menu?.forEach(item => {
//             let v_item = subMenu.find(element => (element.subMenu_id === item.subMenu_id && element.actions_name === 'View'))
//             let c_item = subMenu.find(element => (element.subMenu_id === item.subMenu_id && element.actions_name === 'Create'))
//             let e_item = subMenu.find(element => (element.subMenu_id === item.subMenu_id && element.actions_name === 'Edit'))
//             let d_item = subMenu.find(element => (element.subMenu_id === item.subMenu_id && element.actions_name === 'Delete'))
//             actionArray.push({
//                 "menu": item.subMenu_name,
//                 "view_id": v_item ? v_item.actions_id : null,
//                 "create_id": c_item ? c_item.actions_id : null,
//                 "edit_id": e_item ? e_item.actions_id : null,
//                 "delete_id": d_item ? d_item.actions_id : null
//             })
//         })

//         console.log(actionName, actionArray);

//     }, [subMenu])

//     return (
//         <>{actionArray?.length !==0 && actionName?.length !== 0 &&
//             <Table bordered hover>
//             <thead>
//                 <tr>
//                     <th className="table-style table-heading"></th>
//                     <th className="table-style table-heading">{actionName?.includes('View') ? 'View' : ''}</th>
//                     <th className="table-style table-heading">{actionName?.includes('Create') ? 'Create' : ''}</th>
//                     <th className="table-style table-heading">{actionName?.includes('Edit') ? 'Edit' : ''}</th>
//                     <th className="table-style table-heading">{actionName?.includes('Delete') ? 'Delete' : ''}</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {actionArray?.map((item, index) => (
//                     <tr key={index}>
//                         <td className="table-style table-font">{item.menu}</td>
//                     </tr>
//                 ))}

//             </tbody>
//         </Table>
//         }</>
//     );
// }

// export default PermittedActions;

import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

function PermittedActions({ subMenu, handleChange, editActions, isEdit }) {
  const [actionArray, setActionArray] = useState([]);
  const [actionName, setActionName] = useState([]);

  useEffect(() => {
    let tempActionArray = [];
    let tempActionName = [];

    let menu = [
      ...new Map(
        subMenu?.map((item) => [item['subMenu_id'], item])
      ).values(),
    ].sort((a, b) => a.subMenu_order - b.subMenu_order);

    subMenu?.forEach((element) => {
      if (!tempActionName.includes(element['actions_name'])) {
        tempActionName.push(element['actions_name']);
      }
    });

    menu?.forEach((item) => {
      let v_item = subMenu.find(
        (element) =>
          element.subMenu_id === item.subMenu_id &&
          element.actions_name.replace(/\s+/g, '') === 'View'
      );
      let c_item = subMenu.find(
        (element) =>
          element.subMenu_id === item.subMenu_id &&
          element.actions_name.replace(/\s+/g, '') === 'Create'
      );
      let e_item = subMenu.find(
        (element) =>
          element.subMenu_id === item.subMenu_id &&
          element.actions_name.replace(/\s+/g, '') === 'Edit'
      );
      let d_item = subMenu.find(
        (element) =>
          element.subMenu_id === item.subMenu_id &&
          element.actions_name.replace(/\s+/g, '') === 'Delete'
      );
      tempActionArray.push({
        menu: item.subMenu_name,
        view_id: v_item ? v_item.actions_id : null,
        create_id: c_item ? c_item.actions_id : null,
        edit_id: e_item ? e_item.actions_id : null,
        delete_id: d_item ? d_item.actions_id : null,
      });
    });

    setActionArray(tempActionArray);
    setActionName(tempActionName);
  }, [subMenu]);

  return (
    <>
      {actionArray.length !== 0 && actionName.length !== 0 && (
        <Table bordered hover>
          <thead>
            <tr>
              <th className="table-style table-heading"></th>
              {actionName.includes('View') && (
                <th className="table-style table-heading">View</th>
              )}
              {actionName.includes('Create') && (
                <th className="table-style table-heading">Create</th>
              )}
              {actionName.includes('Edit') && (
                <th className="table-style table-heading">Edit</th>
              )}
              {actionName.includes('Delete') && (
                <th className="table-style table-heading">Delete</th>
              )}
            </tr>
          </thead>
          <tbody>
            {actionArray.map((item, index) => (
              <tr key={index}>
                <td className="table-style table-font-left">{item.menu}</td>
                {actionName.includes('View') && (
                  <td className="table-style table-font">
                    {item.view_id ? (
                      <input
                        type="checkbox"
                        checked={editActions?.includes(item.view_id)}
                        onChange={(e) => handleChange(e, item.view_id)}
                      />
                    ) : null}
                  </td>
                )}
                {actionName.includes('Create') && (
                  <td className="table-style table-font">
                    {item.create_id ? (
                      <input
                        type="checkbox"
                        checked={editActions?.includes(item.create_id)}
                        onChange={(e) => handleChange(e, item.create_id)}
                      />
                    ) : null}
                  </td>
                )}
                {actionName.includes('Edit') && (
                  <td className="table-style table-font">
                    {item.edit_id ? (
                      <input
                        type="checkbox"
                        checked={editActions?.includes(item.edit_id)}
                        onChange={(e) => handleChange(e, item.edit_id)}
                      />
                    ) : null}
                  </td>
                )}
                {actionName.includes('Delete') && (
                  <td className="table-style table-font">
                    {item.delete_id ? (
                      <input
                        type="checkbox"
                        checked={editActions?.includes(item.delete_id)}
                        onChange={(e) => handleChange(e, item.delete_id)}
                      />
                    ) : null}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default PermittedActions;
