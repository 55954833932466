import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import MenuBar from './MenuBar'
import PriceDemo from './PriceDemo'
import { Col, Container, Row } from 'react-bootstrap'
import HomeDashboard from '../../components/features/HomeDashboard'
import PurchaseDashboard from '../../components/features/PurchaseDashboard';
import ComparisonDashboard from '../../components/features/ComparisonDashboard';
import DayBook from '../../components/features/DayBook';
import CashAndBank from '../../components/features/CashAndBank';
import PaymentAndReceipt from '../../components/features/PaymentAndReceipt';
import SalesAndPurchases from '../../components/features/SalesAndPurchases';
import JournalAndLedger from '../../components/features/JournalAndLedger';
import InventoryReport from '../../components/features/InventoryReport';
import SalesDashboard from '../../components/features/SalesDashboard'
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

function Features() {
  const [maincontent, setMainContent] = useState(1)
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  // Function to determine initial menu state based on window width
  const handleResize = () => {
    if (window.innerWidth > 576) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  };

  // Initialize state and add resize event listener
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeMenu = (num) => {
    switch (num) {
      case 1:
        return <HomeDashboard />
      case 2:
        return <SalesDashboard />
      case 3:
        return <PurchaseDashboard />
      case 4:
        return <ComparisonDashboard />
      case 5:
        return <DayBook />
      case 6:
        return <CashAndBank />
      case 7:
        return <PaymentAndReceipt />
      case 8:
        return <SalesAndPurchases />
      case 9:
        return <JournalAndLedger />
      case 10:
        return <InventoryReport />
      default:
        <HomeDashboard />
    }
  }

  const changeMenuName = (value) => {
    switch (value) {
      case 1:
        return 'Home Dashboard';
      case 2:
        return 'Sales Dashboard';
      case 3:
        return 'Purchase Dashboard';
      case 4:
        return 'Comparison Dashboard';
      case 5:
        return 'Day Book';
      case 6:
        return 'Cash and Bank';
      case 7:
        return 'Payment and Receipt';
      case 8:
        return 'Sales and Purchases';
      case 9:
        return 'Journal and Ledger';
      case 10:
        return 'Inventory Report';
      default:
        return 'Home Dashboard';
    }
  };

  return (
    <>
      <div className='Horizondal-Divider'>
        <Header />
      </div>
      <div>
        <Col xs={12} sm={12} md={12} lg={12} className="headline home-colC">
          <div className="m-4">
            <span className="heading-black">
              {" "}
              A Comprehensive Exploration of the <span className="heading-blue">features</span>
            </span>
          </div>
        </Col>

        <Container>
          <Row>
            <Col xs={12} sm={6} md={4} lg={4}>
              <div className="menu-card title-button">
                {changeMenuName(maincontent)}
                <span onClick={() => setIsMenuOpen((preState) => !preState)}>{isMenuOpen ? <FaAngleUp /> : <FaAngleDown />}</span>
              </div>
              {isMenuOpen && <div className='menu-card style'>
                <MenuBar mainContent={maincontent} setMainContent={setMainContent} setIsMenuOpen={setIsMenuOpen}/>
              </div>}
            </Col >

            <Col xs={12} sm={6} md={8} lg={8} className="p-5">
              {changeMenu(maincontent)}
            </Col>
          </Row>

        </Container>
      </div>
      <div>
        <PriceDemo />
      </div>
      <div>
        <Footer />
      </div>
    </>
  )
}

export default Features