import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { handleApiErrors } from '../../../handleApiErrors';
import { successToast } from '../../../HelperFile';
import { updateSubscription } from '../../api/customerApi';
import { useSignalAbort } from '../../../api/useSignalAbort';

function ExtendForm({subscriptionDetails, getData, closeModal, updateTable}) {
    console.log(subscriptionDetails);
    const abortConstants = useSignalAbort()
    const [validated, setValidated] =useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState({});
    const [days, setDays] = useState()

      //for updating duration
      const updateDuration = async (id, data, abortToken) => {
        console.log(data);
        try {
            const result = await updateSubscription(id, data, abortToken)
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData(subscriptionDetails.CustomerId)
                updateTable()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Duration', 'duration_response', 'duration_timeout', 'duration_request', setIsLoading)
        }
    }

    const handleSubmit =(e)=>{
        e.preventDefault()
        setIsSaving(true)
        const form = e.currentTarget; // The form that triggered the event
        const data ={
            CustomerId: subscriptionDetails.CustomerId,
            PlanId: subscriptionDetails.PlanId,
            Duration: Number(days),
            IsGenerateNewKey:0
        }
        if (form.checkValidity() === false) {
            e.stopPropagation(); // Stops further event propagation if validation fails
            setIsSaving(false);     
          } else {
            updateDuration(subscriptionDetails.SubscriptionId, data, abortConstants.controllerConfig)
        }
        setValidated(true)
    }

    return (
        <div >
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12}>
                            <Form.Label className='text-left label form-label' >Number of days to be extended<span className='asterik'> *</span></Form.Label>
                    </Col>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='name'>
                            <Form.Control maxLength={5} required size='sm' type='number' name='Name' value={days} onChange={(e)=>setDays(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Days is required.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                    <Button
                        type={!isSaving ? 'submit' : null}
                        className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                        size='sm'
                    >
                        {isSaving ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <span className='me-2'>Saving...</span>
                                <Spinner animation="border" variant="light" size='sm' />
                            </div>
                        ) : (
                            "Extend"
                        )}
                    </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ExtendForm