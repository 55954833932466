// import axiosInstance from "./axiosInstance";
import axiosInstanceAdmin from "./axiosInstanceAdmin";

let api = axiosInstanceAdmin()


export const Demo = async(data, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.post(`/Leads`, data, config)
  }
  