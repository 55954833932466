import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_BASE_URL

function Dashboard({ worksapceid, viewid, setIsDashboard, setWorkspaceId, setViewId}) {
    const token = localStorage.getItem("intelliger_admin_token")
    const location = useLocation();
    // const { worksapceid, viewid } = location.state || {};
    const [url, setUrl] = useState()
    // console.log(token, API_URL, worksapceid, viewid);

    useEffect(() => {
        if (token && API_URL && worksapceid && viewid) {
            getUrl()
        }
    }, [token, API_URL, worksapceid, viewid])

    const getUrl = async () => {
        console.log(worksapceid, viewid);

        try {
            const response = await axios.get(`${API_URL}/Zoho/GetViewURL/${worksapceid}/${viewid}`, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            console.log(response);
            setUrl(response?.data?.data)
        }
        catch (error) {
            // apiCallProgress(false)
            if (error.response?.data?.message) {
                toast.error(error?.response?.data?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
            }
            else {
                toast.error(error?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
            }
        }
    }

    // Utility function to check if URL is valid
    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    return (
        <div>
            <div className='d-flex justify-content-end mb-1'>
                <Button className='button save' onClick={() => {setIsDashboard(false); setWorkspaceId(null); setViewId(null)}}>Back</Button>
            </div>
            <div>
                {url ?
                    <>{isValidUrl(url) ?
                        <iframe
                            src={url}
                            title="Analytics"
                            width="100%"
                            height="800"
                            style={{ border: 'none' }}
                        /> :
                        <>
                            <div className="d-flex justify-content-center mt-5">
                                <span>{url}</span>
                            </div>
                        </>}
                    </>
                    :
                    <div className="d-flex justify-content-center mt-5">
                        <Spinner animation="border" />
                    </div>
                }
            </div>
        </div>
    )
}

export default Dashboard