import React from 'react'
import { Card, Col, Container, Row, } from 'react-bootstrap';

function Browsebytopic() {
  return (
    <div className='container-color2'>
    <Container >
      <div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='mt-5' >
          <span className="heading-green">Browse by topic :</span>
        </Col>
      </Row>
      </div>
      <div>
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={4} className='mt-5 headline home-colC mb-5'>
          <Card className='card boder-r'>
            <video 
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4" 
              controls 
              className="d-block w-100">
              Your browser does not support the video tag.
            </video>
            <span className="heading-blacksub3 m-3">Topic Name 1</span>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} className='mt-5 headline home-colC mb-5'>
          <Card className='card boder-r'>
            <video 
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
              controls 
              className="d-block w-100">
              Your browser does not support the video tag.
            </video>
            <span className="heading-blacksub3 m-3">Topic Name 2</span>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} className='mt-5 headline home-colC mb-5'>
          <Card className='card boder-r'>
            <video 
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4" 
              controls 
              className="d-block w-100">
              Your browser does not support the video tag.
            </video>
            <span className="heading-blacksub3 m-3">Topic Name 4</span>
          </Card>
        </Col>
        </Row>
        <Row className="justify-content-center pb-5">
        <Col xs={12} sm={12} md={12} lg={4} className='mt-5 headline home-colC mb-5'>
          <Card className='card boder-r'>
            <video 
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
              controls 
              className="d-block w-100">
              Your browser does not support the video tag.
            </video>
            <span className="heading-blacksub3 m-3">Topic Name 3</span>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} className='mt-5 headline home-colC mb-5'>
          <Card className='card boder-r'>
            <video 
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4" 
              controls 
              className="d-block w-100">
              Your browser does not support the video tag.
            </video>
            <span className="heading-blacksub3 m-3">Topic Name 4</span>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} className='mt-5 headline home-colC mb-5'>
          <Card className='card boder-r'>
            <video 
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
              controls 
              className="d-block w-100">
              Your browser does not support the video tag.
            </video>
            <span className="heading-blacksub3 m-3">Topic Name 5</span>
          </Card>
        </Col>
        </Row>
      </div>
    </Container>
  </div>
  )
}

export default Browsebytopic