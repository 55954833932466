import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Accordion } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getSources } from '../../../api/SourcesApi'
import { useSignalAbort } from '../../../api/useSignalAbort'
import { createLead, createLeadNote, deleteLeadNote, getIndustry, getLeadById, getLeadNoteByCriteria, getstatus, updateLead, updateLeadNote } from '../../api/leadsApi'
import { getUsers } from '../../api/userApi'
import dayjs from 'dayjs'
import { changeFormat, successToast } from '../../../HelperFile'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { IoIosTime } from "react-icons/io";
import { handleApiErrors } from '../../../handleApiErrors'
import ConvertForm from './ConvertForm'

function DetailForm({ id, isEdit, closeModal, getData }) {
    const abortConstants = useSignalAbort()
    const user = useSelector(state => state?.auth?.adminUser)
    const [isConvert, setIsConvert] = useState(false)
    const [leadData, setLeadData] = useState()
    const [formErrors, setFormerrors] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [noteList, setNoteList] = useState()
    const [note, setNote] = useState("")
    const [updateItem, setUpdateItem] = useState();
    const [statusSelect, setStatusSelect] = useState("");
    const [userSelect, setUserSelect] = useState("");
    const [userList, setUserList] = useState()
    const [statusList, setStatusList] = useState()
    const [sourceList, setSourceList] = useState()
    const [industryList, setIndustryList] = useState()
    const [isLoading, setIsLoading] = useState({})


    //function to get lead note by criteria
    const getNoteByCriteria = async (id) => {
        try {
            const result = await getLeadNoteByCriteria(id, abortConstants.controllerConfig)
            setNoteList(result?.data?.LeadNote)
            console.log(result);
        }
        catch (error) {
            console.log(error);
        }
    }

    //function to get list of owners
    const getAllOwners = async () => {
        try {
            const result = await getUsers(abortConstants.controllerConfig)
            setUserList(result?.data?.User)
            setUserSelect(result?.data?.User[0]?.userid)
        }
        catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getNoteByCriteria(id)
        getAllOwners()
    }, [])

    useEffect(() => {
        if (isEdit) {
            const getLead = async () => {
                try {
                    const result = await getLeadById(id, abortConstants.controllerConfig)
                    setLeadData(result?.data?.Leads);
                    console.log(result);
                }
                catch (error) {
                    console.log(error);
                }
            }
            getLead()
        }
    }, [id])

    //For form validation
    //   const validate = (values) => {
    //     const errors = validateForm(values, validationRules);
    //     console.log(errors);
    //     setFormerrors(errors)
    //     if (Object.keys(errors).length === 0) {
    //       return true;
    //     }
    //     else {
    //       return false;
    //     }
    //   }

    //for creating lead
    const createLeadNotes = async (data, abortToken) => {
        console.log(data);
        try {
            setIsSaving(false)
            const result = await createLeadNote(data, abortToken)
            if (result) {
                successToast(result?.data?.message)
                // closeModal()
                getNoteByCriteria(id)
                setNote('')
            }
            // getData()
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Add Note', 'addnote_response', 'addnote_timeout', 'addnote_request', setIsLoading)
        }
    }

    //for updating lead
    const updateLeadNotes = async (noteId, data, abortToken) => {
        console.log(data);
        try {
            const result = await updateLeadNote(noteId, data, abortToken)
            setIsSaving(false)
            if (result) {
                successToast(result?.data?.message)
                // closeModal()
                getNoteByCriteria(id)
                setNote('')
                setUpdateItem(null)
            }
        }
        catch (error) {
            //   setIsSaving(false)
            handleApiErrors(error, 'Update Note', 'editnote_response', 'editnote_timeout', 'editnote_request', setIsLoading)
        }
    }

    //for deleteing lead
    const deleteLeadNotes = async (noteId) => {
        console.log(id);
        try {
            const result = await deleteLeadNote(noteId, abortConstants.controllerConfig)
            console.log(result);
            if (result) {
                successToast(result?.data?.message)
                getNoteByCriteria(id)
            }
        }
        catch (error) {
            handleApiErrors(error, 'Delete Note', 'deletenote_response', 'deletenote_timeout', 'deletenote_request', setIsLoading)
        }
    }

    //To set input values for add branch 
    const handleChange = (e) => {
        // setFormerrors({})
        setLeadData({ ...leadData, [e.target.name]: e.target.value },)
    }

    //form submission
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            Id: id,
            Description: note,
            CreatedBy: user?.userid
        }
        console.log(data);
        if (updateItem) {
            updateLeadNotes(updateItem.NotesId, data, abortConstants.controllerConfig)
        } else {
            createLeadNotes(data, abortConstants.controllerConfig)
        }

    }

    return (
        <>{isConvert ?
            <ConvertForm id={id} closeModal={closeModal} setIsConvert={setIsConvert} leadsData={leadData} getData={getData} />
            :
            <div>
                <Row className='d-flex justify-content-end mb-2'>
                    <Col xs={12} className='d-flex justify-content-between align-items-center'>
                        <span className='modal-title'>Lead Details</span>
                        <Button className='button save' onClick={() => setIsConvert(true)}>
                            Convert
                        </Button>
                    </Col>
                </Row>
                <div className='form leadform'>
                    <Row>
                        {/* Customer Details */}
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Name</span></Col>
                                <Col xs={12}><span>{leadData?.Name}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Phone</span></Col>
                                <Col xs={12}><span>{leadData?.PhoneNumber}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Company Name</span></Col>
                                <Col xs={12}><span>{leadData?.CompanyName}</span></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Email ID</span></Col>
                                <Col xs={12}><span>{leadData?.EmailId}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Company Size</span></Col>
                                <Col xs={12}><span>{leadData?.CompanySize}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Website</span></Col>
                                <Col xs={12}><span>{leadData?.Website}</span></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Preferred Date</span></Col>
                                <Col xs={12}><span>{changeFormat(dayjs(leadData?.PreferredDate), 'yyyy-mm-dd')}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Source</span></Col>
                                <Col xs={12}><span>{leadData?.SourceName}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Industry</span></Col>
                                <Col xs={12}><span>{leadData?.IndustryName}</span></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Annual Revenue</span></Col>
                                <Col xs={12}><span>{leadData?.AnnualRevenue}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Lead Status</span></Col>
                                <Col xs={12}><span>{leadData?.StatusName}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Reason</span></Col>
                                <Col xs={12}><span></span></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Lead Owner</span></Col>
                                <Col xs={12}><span>{leadData?.LeadOwnerName}</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={8}>
                            <Row>
                                <Col xs={12}><span className='label form-label'>Description</span></Col>
                                <Col xs={12}><span>{leadData?.Description}</span></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Notes</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <Form className='form leadform mt-2' onSubmit={handleSubmit}>
                                                <Row >
                                                    <Col xs={9}>
                                                        <Form.Group controlId="note">
                                                            <Form.Control required size='sm' type='type' name='Note' value={note} onChange={(e) => setNote(e.target.value)} placeholder='Add Note' />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={3} className='d-flex justify-content-between'>
                                                        <Button type="submit" className='button save'>
                                                            {updateItem ? "Edit" : " Add"}
                                                        </Button>
                                                        {updateItem &&
                                                            <Button className='button save' onClick={() => { setNote(''); setUpdateItem(null) }}>
                                                                Cancel
                                                            </Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Form>
                                            {noteList?.map((item, index) => (
                                                <Row className='lead-note background mt-3'>
                                                    <Col xs={10} className='d-flex flex-column'>
                                                        <span><span> {index + 1} . </span><span>{item.Description}</span></span>
                                                        <span className='px-2 title subtitle'><IoIosTime className='icon timer-icon' />{item.createddate}  by {item.CreatedByName}</span>
                                                    </Col>
                                                    <Col xs={2} className='d-flex justify-content-end'>
                                                        <FaEdit className='me-3 edit-icon title subtitle' onClick={() => { setUpdateItem(item); setNote(item.Description) }} />
                                                        <FaTrash className='delete-icon title subtitle' onClick={() => deleteLeadNotes(item.NotesId)} />
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </div>
            </div>
        }</>
    )
}

export default DetailForm