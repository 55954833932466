import React, { useState,useEffect }from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

function Header({ title }) {
  const {pathname} = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setActiveLink(pathname.substring(1));
}, [pathname]);

  return (
    <Navbar expand="lg" className="bg-white navbar-custom ">
      <Container>
        <Navbar.Brand href="https://www.rcor.co" className="ms-0 ps-0 ps-lg-5 ms-lg-5">
          <img src={process.env.PUBLIC_URL + `/assets/images/RCORLogo.png`} alt='logo' height={50}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto pe-0 pe-lg-5">
            {/* <Nav.Link
              onClick={() => navigate("/")}
              className={`me-5 ${(activeLink === ""||activeLink==='Demo'||activeLink==='Feedback' )? "nav active-link" : "nav font-head"}`}>Home </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/about")}
              className={`me-5 ${activeLink === "about" ? "nav active-link" : "nav font-head"}`}>About us </Nav.Link>
            <Nav.Link
              onClick={() =>  navigate("/features")}
              className={`me-5 ${activeLink === "features" ? "nav active-link" : "nav font-head"}`}>Features</Nav.Link>
               <Nav.Link
              onClick={() =>  navigate("/blog")}
              className={`me-5 ${activeLink === "blog" ? "nav active-link" : "nav font-head"}`}>Blog</Nav.Link> */}
            {/* <Nav.Link 
              onClick={() =>  navigate("/pricing")}
              className={`me-5 ${activeLink === "pricing" ? "nav active-link" : "nav font-head"}`}>Pricing</Nav.Link>
            <Nav.Link  
              onClick={() =>  navigate("/casestudy")}
              className={`me-5 ${activeLink === "casestudy" ? "nav active-link" : "nav font-head"}`}>Case Study</Nav.Link> */}
            {/* <Nav.Link 
              onClick={() =>  navigate("/resources")}
              className={`me-5 ${activeLink === "resources" ? "nav active-link" : "nav font-head"}`}>Resources</Nav.Link> */}
            {/* <Nav.Link onClick={() =>  navigate("/contact")}
              className={`${activeLink === "contact" ? "nav active-link" : "nav font-head"}`}>Contact</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

      // <Navbar expand="lg" className="bg-white">
    //   <Container className="pe-lg-5 navbar navbar-custom ">
    //     <Navbar.Brand href="#home">
    //       <img src={process.env.PUBLIC_URL + `/assets/images/RCORLogo.png`} alt='logo' height={50} />
    //     </Navbar.Brand>
    //     <Navbar.Toggle />
    //     <Navbar.Collapse className="justify-content-end">
    //       <Nav className="ms-auto">
    //         <Nav.Link
    //           onClick={() => navigate("/")}
    //           className={`me-5 ${(activeLink === "" || activeLink === 'Demo' || activeLink === 'Feedback') ? "nav active-link" : "nav font-head"}`}
    //         >Home
    //         </Nav.Link>
    //         <Nav.Link
    //           onClick={() => navigate("/features")}
    //           className={`me-5 ${activeLink === "features" ? "nav active-link" : "nav font-head"}`}>
    //           Features
    //         </Nav.Link>
    //         <Nav.Link
    //           onClick={() => navigate("/blog")}
    //           className={`me-5 ${activeLink === "blog" ? "nav active-link" : "nav font-head"}`}>
    //           Blog
    //         </Nav.Link>
    //         {/* <Nav.Link 
    //           onClick={() =>  navigate("/pricing")}
    //           className={`me-5 ${activeLink === "pricing" ? "nav active-link" : "nav font-head"}`}>Pricing</Nav.Link>
    //         <Nav.Link  
    //           onClick={() =>  navigate("/casestudy")}
    //           className={`me-5 ${activeLink === "casestudy" ? "nav active-link" : "nav font-head"}`}>Case Study</Nav.Link> */}
    //         {/* <Nav.Link 
    //           onClick={() =>  navigate("/resources")}
    //           className={`me-5 ${activeLink === "resources" ? "nav active-link" : "nav font-head"}`}>Resources</Nav.Link> */}
    //         <Nav.Link onClick={() => navigate("/contact")}
    //           className={`${activeLink === "contact" ? "nav active-link" : "nav font-head"}`}>
    //           Contact
    //         </Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar >
  );
}

export default Header;
