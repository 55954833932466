import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {Col, Container, Row} from 'react-bootstrap'
import { IoIosArrowDropdown  } from "react-icons/io";

// Define your custom toggle component
const ContextAwareToggle = ({ eventKey, children }) => {
    const decoratedOnClick = useAccordionButton(eventKey);


  return (
    <div onClick={decoratedOnClick} style={{ display: 'inline-block', cursor: 'pointer' }}>
      <IoIosArrowDropdown  className="icon" style={{ marginRight: '8px', color:'#489C8F' }}  />
      <span className="heading-blacksub2" >{children}</span>
    </div>
  );
};

function SalesDashboard() {
  return (
    <div>
        <div className='headline home-colL'>
            <span className='headline home-maing' >
                Sales Dashboard
            </span>
        </div>   
        <div className='headline home-maing mt-5'>
            <img src={process.env.PUBLIC_URL + "/assets/images/SalesDashboard.png"} 
            alt="Catchy Headline"
            className="d-block w-100 "/>
        </div> 
        <Container className='container-color2 mb-5'>
      <div className="headline home-colL  mt-5">
        <div>
          <div className="vertical-divider mt-3">
          <span className="heading-blacksub mx-3">Features</span></div>
        </div>
      </div>
      <div className='pt-3 pb-5'>
        <Accordion>
          <Row>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="0">Sales vs Profit</ContextAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='heading-blacksub4 px-4'>Compares your sales revenue to the Profits associated with those sales. </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="1" >Top Products</ContextAwareToggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className='heading-blacksub4 px-4'>Identifies your most popular products based on sales volume, revenue, or profits. </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="2">Top Customers</ContextAwareToggle>
            <Accordion.Collapse eventKey="2">
            <Card.Body className='heading-blacksub4 px-4'>Highlights your most valuable customers, based on sales volume, revenue, or profits.</Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="3" >Sales Return</ContextAwareToggle>
            <Accordion.Collapse eventKey="3">
            <Card.Body className='heading-blacksub4 px-4'>Sometimes products get returned, they are analyzed month-wise, helping you to pinpoint and understand why it happened.</Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
          <ContextAwareToggle eventKey="4" >Sales vs Collection</ContextAwareToggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body className='heading-blacksub4 px-4'>Tracks the difference between your sales and the actual cash you receive from customers. </Card.Body>
          </Accordion.Collapse>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className='mt-4'>
            <ContextAwareToggle eventKey="5">Sales Type</ContextAwareToggle>
            <Accordion.Collapse eventKey="5">
            <Card.Body className='heading-blacksub4 px-4'>This categorizes your sales based on the number of sales channels. </Card.Body>
          </Accordion.Collapse>
          </Col>
          
          </Row>
      </Accordion>
        </div>
        </Container>
    </div>
  )
}

export default SalesDashboard