import React, { useEffect, useState } from 'react'
import { Table, Row, Col, Spinner, Pagination } from 'react-bootstrap';
import { getFeedback } from '../../api/contactApi';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';

function Feedbacks() {
    const abortConstants = useSignalAbort()
    const [feedback, setFeedback] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const feedbackPerPage = 13; // Adjust this number to set how many leads you want per page

    //function to get all feedbacks
    const getFeedbacks = async () => {
        try {
            const response = await getFeedback(abortConstants.controllerConfig)
            setFeedback(response?.data?.Feedback)
            setIsLoading(false);
            console.log(response);
        }
        catch (error) {
            handleApiErrors(error, 'Feedback', 'feedback_response', 'feedback_timeout', 'feedback_request', setIsLoading)
        }
    }

    // Get current feedbacks
    const indexOfLastFeedback = currentPage * feedbackPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbackPerPage;
    const currentFeedback = feedback?.slice(indexOfFirstFeedback, indexOfLastFeedback);

    // Change page
    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(feedback?.length / feedbackPerPage)));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };



    useEffect(() => {
        getFeedbacks()
    }, [])

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h5>Feedback</h5>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    {isLoading ? (
                        <div className="d-flex justify-content-center mt-5">
                            <Spinner animation="border" />
                        </div>
                    ) : feedback?.length > 0 ? (
                        <>
                            <Table striped bordered hover>
                                <thead>
                                    <tr >
                                        <th className='table-style table-heading'>Name</th>
                                        <th className='table-style table-heading'>Phone</th>
                                        <th className='table-style table-heading'>Email</th>
                                        <th className='table-style table-heading'>Company Name</th>
                                        <th className='table-style table-heading'>Country</th>
                                        <th className='table-style table-heading'>Source</th>
                                        <th className='table-style table-heading'>Category</th>
                                        <th className='table-style table-heading'>Suggestion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentFeedback?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='table-style table-font'>{item.Name}</td>
                                            <td className='table-style table-font'>{item.PhoneNumber}</td>
                                            <td className='table-style table-font'>{item.EmailId}</td>
                                            <td className='table-style table-font'>{item.CompanyName}</td>
                                            <td className='table-style table-font'>{item.CountryName}</td>
                                            <td className='table-style table-font'>{item.SourceName}</td>
                                            <td className='table-style table-font'>{item.CategoryName}</td>
                                            <td className='table-style table-font'>{item.Suggestion}</td>
                                        </tr>
                                    ))}
                                </tbody>

                                <tbody>

                                </tbody>
                            </Table>
                            <Pagination className="justify-content-end">
                                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                                <Pagination.Item>{currentPage}</Pagination.Item>
                                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(feedback?.length / feedbackPerPage)} />
                            </Pagination>
                        </>
                    ) : (
                        <h4 style={{ textAlign: 'center' }}>No data</h4>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default Feedbacks