import React from 'react'
import {Container} from 'react-bootstrap'

function InventoryReport() {
  return (
    <div>
    <div className="headline home-colL">
      <span className="headline home-maing">Inventory Report</span>
    </div>
    <div className="headline home-maing mt-5">
      <img
        src={process.env.PUBLIC_URL + '/assets/images/InventoryReport.png'}
        alt="Catchy Headline"
        className="d-block w-100 features align-img"
      />
    </div>
    <Container className='mt-5' >
      <div>
        <span className="heading-blacksub3">Acts like a real-time stock list, showing exactly what inventory you have on hand and helps to avoid stockouts.</span>
      </div>
      </Container>
  </div>
  )
}

export default InventoryReport