import axiosInstance from "./axiosInstance";

let api = axiosInstance()


export const getDefaultCountry = async(abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/Country/Default`, config)
  }