import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'

function OurStory() {
    return (
        <Container fluid className='mt-5 container-color2'>
            <Row className='home client-div pb-5'>
                <Row>
                    <Col xs={12}>
                        <div className='about-story headline'>
                            <span className="heading-black about-story title">
                                {" "}
                                Our Story
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12} sm={12} md={12} lg={6} className='d-flex justify-content-end' >
                        <Image src={process.env.PUBLIC_URL + "/assets/images/story.png"} rounded height={300} className='w-100' />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} >
                        <div className='headline home-subcontant about-card content'>
                            The hunger for small-scale businesses to embrace
                            technological efficiency gave rise to RCOR in 2020. We have
                            provided data-intelligent solutions to industries ranging from
                            Accounting & Finance, Healthcare, Industrial & Manufacturing,
                            Retail, and Real Estate. We continue to help a myriad of
                            businesses incorporate process automation, smart data
                            extraction, and data processing to help unleash their full potential.
                        </div>
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}

export default OurStory