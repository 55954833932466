import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { handleApiErrors } from '../../../handleApiErrors';
import { successToast } from '../../../HelperFile';
import { updateSubscription } from '../../api/customerApi';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { getPlans } from '../../api/leadsApi';

function UpgradeForm({ subscriptionDetails, getData, closeModal, updateTable }) {
    console.log(subscriptionDetails);
    const abortConstants = useSignalAbort()
    const [validated, setValidated] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState({});
    const [days, setDays] = useState()
    const [planSelect, setPlanSelect] = useState('')
    const [planList, setPlanList] = useState()
    const [selectedPlan, setSelectedPlan] = useState()

    //for getting plans
    const getPlan = async (abortToken) => {
        try {
            const result = await getPlans(abortToken)
            console.log(result);
            const plan = result?.data?.SubscriptionPlan?.filter(item => item.Name !== 'Free')
            setPlanList(plan)
        }
        catch (error) {
            handleApiErrors(error, 'Plans', 'plan_response', 'plan_timeout', 'plan_request', setIsLoading)
        }
    }

    useEffect(()=>{
        getPlan(abortConstants.controllerConfig)
    }, [subscriptionDetails])

    //for updating duration
    const updatePlan = async (id, data, abortToken) => {
        console.log(data);
        try {
            const result = await updateSubscription(id, data, abortToken)
            console.log(result);
            if (result) {
                setIsSaving(false)
                successToast(result?.data?.message)
                closeModal()
                getData(subscriptionDetails.CustomerId)
                updateTable()
            }
        }
        catch (error) {
            setIsSaving(false)
            handleApiErrors(error, 'Update Duration', 'duration_response', 'duration_timeout', 'duration_request', setIsLoading)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsSaving(true)
        setValidated(true)
        const form = e.currentTarget; // The form that triggered the event
        const data = {
            CustomerId: subscriptionDetails.CustomerId,
            PlanId: Number(planSelect),
            Duration: Number(days),
            IsGenerateNewKey:0
        }
        if (form.checkValidity() === false) {
            e.stopPropagation(); // Stops further event propagation if validation fails
            setIsSaving(false);     
          } else {
            updatePlan(subscriptionDetails.SubscriptionId, data, abortConstants.controllerConfig)    
          }
    }

    const handlePlanChange = (e) => {
        const plan = planList.find(item => item.PlanId === Number(e.target.value))
        setPlanSelect(e.target.value)
        setSelectedPlan(plan)
    }

    return (
        <div >
            <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='plan'>
                            <Form.Label className='text-left label form-label' >Plan<span className='asterik'> *</span></Form.Label>
                            <Form.Select required value={planSelect} onChange={handlePlanChange}>
                                <option value="" disabled>Select</option>
                                {planList?.map(item => (
                                    <option key={item.PlanId} value={item.PlanId}>{item.Name}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">Select Plan</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} >
                        <Form.Group controlId='days'>
                            <Form.Label className='text-left label form-label' >Duration<span className='asterik'> *</span></Form.Label>
                            <InputGroup>
                                <Form.Control required maxLength={5} size='sm' type='number' name='Duration' value={days} onChange={(e) => setDays(e.target.value)} />
                                <InputGroup.Text>{selectedPlan?.BillingCycle}</InputGroup.Text>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">Duration required.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='mt-2 d-flex justify-content-end'>
                        <Button
                            type={!isSaving ? 'submit' : null}
                            className={`${isSaving ? 'button-loading disable' : 'button save'}`}
                            size='sm'
                        >
                            {isSaving ? (
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className='me-2'>Saving...</span>
                                    <Spinner animation="border" variant="light" size='sm' />
                                </div>
                            ) : (
                                "Upgrade"
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default UpgradeForm