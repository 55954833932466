import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Button, Row, Col, Spinner, Card, InputGroup } from 'react-bootstrap'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaGlobe } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { LiaLinkedin } from "react-icons/lia";
import { RiYoutubeLine } from "react-icons/ri";
import { FiTwitter } from "react-icons/fi";
import * as contactApi from "../../api/ContactApi"
import * as SourcesApi from "../../api/SourcesApi"
import * as CountryApi from "../../api/countryApi"
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from "../../handleApiErrors";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";

const defaultContactData = {
  Name: '',
  Email: '',
  Phone: '',
  CompanyName: '',
  Country: '',
  Sources: '',
  Suggestions: '',
};

function FormContact() {
  const abortConstants = useSignalAbort()
  const defaultCountry = useSelector(state => state.geo.geoData)
  const [ContactData, setContactData] = useState(defaultContactData)
  const [Sources, setSources] = useState()
  const [Sourcesselect, setSourcesselect] = useState("")
  const [Country, setCountry] = useState()
  const [Countryselect, setCountryselect] = useState("")
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsLoading] = useState({})
  const [issubmit, setIsSubmit] = useState(false)
  const [validated, setValidated] = useState(false);

  useEffect(()=>{
    const country = Country?.find(item=>item.countryCode === defaultCountry.country_code2)
    console.log(country);
    setSelectedCountry(country)
  }, [Country])
  const DropUsMessage = async (data, abortToken) => {
    try {
      const result = await contactApi.DropUsMessage(data, abortToken);
      console.log(result);
      setIsSubmit(false);
      setContactData(defaultContactData)
      setValidated(false)
      setSourcesselect("")
      setCountryselect("")
      // alert(result.data.message);
      toast.success(result.data.message, {
        autoClose: 5000, // Close the toast after 5 seconds
      });
    }
    catch (error) {
      setIsSubmit(false);
      console.error(error);
      handleApiErrors(error, "DropUsMessage", "DropUsMessage_response", "DropUsMessage_timeout", "DropUsMessage_request", setIsLoading);
    }
  };

  //Sources
  const getSource = async (abortToken) => {
    try {
      const result = await SourcesApi.getSources(abortToken);
      // console.log(result);
      setSources(result?.data?.Source);
      // setSourcesselect(result?.data?.Sources[0].SourcesId)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Sources', 'sources_response', 'sources_timeout', 'sources_request', setIsLoading)
    }
  };
  ///getCountry
  const getCountry = async (abortToken) => {
    try {
      const result = await CountryApi.getCountry(abortToken);
      console.log(result?.data?.Country);
      setCountry(result?.data?.Country);
      setCountryselect(result?.data?.Country[0].countryId, abortToken)
    } catch (error) {
      handleApiErrors(error, 'Country', 'country_response', 'country_timeout', 'country_request', setIsLoading)
    }
  };
  //form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    setIsSubmit(true);

    const form = event.currentTarget; // The form that triggered the event
    let newData = {
      PhoneNumber: "+"+selectedCountry.CallingCode+ContactData.Phone,
      EmailId: ContactData.Email,
      CompanyName: ContactData.CompanyName,
      Suggestion: ContactData.Suggestions,
      SourceId: Number(Sourcesselect),
      Name: ContactData.Name,
      CategoryName: ContactData.CategoryName,
      CountryId: Number(selectedCountry.countryId),
    };
    
console.log(newData);
    if (form.checkValidity() === false) {
      event.stopPropagation(); // Stops further event propagation if validation fails
      setIsSubmit(false);

    } else {
      DropUsMessage(newData, abortConstants.controllerConfig); // Call the function to submit the form data

    }

    setValidated(true); // Sets form as validated
  };


  const handleChange = (e) => {
    setContactData({ ...ContactData, [e.target.name]: e.target.value })
  }

  const handleSources = (e) => {
    setSourcesselect(e.target.value);
    console.log(Sourcesselect);
  }
  
  const handleCountryselect = (country) => {
    if (country) {
      setSelectedCountry(country);
    }
  };


  const getAllData = useCallback((abortToken) => {
    getSource(abortToken);
    getCountry(abortToken);
  }, []);

  useEffect(() => {
    getSource(abortConstants.controllerConfig);
    getCountry(abortConstants.controllerConfig);
    // getAllData(abortConstants.controllerConfig);

    // return () => {
    //   // Cleanup logic if needed
    //   abortConstants.controller.abort();
    // };
  }, [getAllData]);

  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`]) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div>

      <div className='mt-5 mb-5  text-center'>
        <span className='heading-black'>Get in<span className='heading-blue'> Touch</span> </span>
      </div>

      <Container fluid>

        <Row>

          <Col xs={12} md={12} lg={5} xl={5} sm={12} className='headline home-colC mt-5'>

            <div className='contact-location'>
              <div>
                <h5 className='tick-heading'>Our Locations:</h5>
              </div>

              <div className='mt-4'>
                <p className='contact-word1'><FaLocationDot className='contact-logo me-4' />RCOR Solutions LLC</p>
                <p className='contact-words ms-4 ps-3'>The Meydan Hotel,</p>
                <p className='contact-words ms-4 ps-3'> Grandstand, 6th floor,</p>
                <p className='contact-words ms-4 ps-3'> Meydan Road, Nad Al Sheba,</p>
                <p className='contact-words ms-4 ps-3'>Dubai, U.A.E</p>
              </div>

              <div className='mt-4'>
                <p className='contact-word1'><FaLocationDot className='contact-logo me-4' />RCOR Technologies Private Ltd</p>
                <p className='contact-words ms-4 ps-3'>1st Floor,VCB Complex</p>
                <p className='contact-words ms-4 ps-3'>Calvary Road,Poothole.P.O,Thrissur</p>
                <p className='contact-words ms-4 ps-3'>Kerala,India</p>
              </div>

              <div className='mt-4'>
                <p className='common-words'><FaPhoneAlt className='contact-logo me-4' />+91 9995857958</p>
              </div>

              <div className='mt-4'>
                <p className='common-words'><IoMail className='contact-logo me-4' />info@rcor.co</p>
              </div>

              <div className='mt-4'>
                <FaGlobe className='contact-logo me-4' />
                <a href="https://www.rcor.co/" className='common-words text-dark'>https://www.rcor.co/</a>
              </div>

              <div>
                <h6 className='mt-5'>
                  Our Social Medias :
                </h6>


                <div className='logos mt-3'>
  <a href="https://www.instagram.com/rcor.co/" target="_blank" rel="noopener noreferrer" className='contact-logo me-2'>
    <FaInstagram />
  </a>
  <a href="https://www.linkedin.com/company/rcorco/" target="_blank" rel="noopener noreferrer" className='contact-logo me-2 ms-2'>
    <LiaLinkedin />
  </a>
  <a href="https://www.facebook.com/RCOR4U" target="_blank" rel="noopener noreferrer" className='contact-logo me-2 ms-2'>
    <AiOutlineFacebook />
  </a>
  <a href="http://www.youtube.com/@RCOR4u" target="_blank" rel="noopener noreferrer" className='contact-logo me-2 ms-2'>
    <RiYoutubeLine />
  </a>
  <a href="https://x.com/rcor_co" target="_blank" rel="noopener noreferrer" className='contact-logo me-2 ms-2'>
    <FiTwitter />
  </a>
</div>

              </div>

            </div>

          </Col>

          <Col xs={12} md={12} lg={7} xl={7} sm={12} className='mt-5' >
            <div className="d-flex justify-content-center">
            <Col xs={12} md={12} lg={8} xl={8} sm={12} className="headline home-colC contactform" >

              <Form
                className="w-100"
                noValidate
                validated={validated}
                onSubmit={handleSubmit} // Pass only one parameter
              >

                <Col xs={12} md={12} lg={12} xl={12} sm={12} >
                  <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                    <Form.Control
                      className='contact-textfields'
                      required
                      type="text"
                      maxLength="120"
                      name='Name'
                      placeholder="Name"
                      value={ContactData?.Name}
                      onChange={handleChange} />
                      <Form.Control.Feedback type="invalid">
                          Please provide Name.
                        </Form.Control.Feedback>
                  </Form.Group>

                </Col>

                <Col xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2 '>
                <Form.Group className='textgroup' controlId="floatingTextarea">
                        <InputGroup>
                         <Dropdown style={{height:'55px', marginTop:'18px'}}>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="textfields"
                              style={{
                                // height: '55px',
                                // width: '100%',
                                backgroundColor: '#F6F6F6',
                                color: 'black',
                                border: 'none',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: '10px',
                                borderTopRightRadius: 0, borderBottomRightRadius: 0,
                                // borderTopLeftRadius:'50px', borderBottomLeftRadius:'50px'
                              }}
                            >
                              {selectedCountry && (
                                <>
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${selectedCountry.CountryFlag}`}
                                    alt={selectedCountry.countryName}
                                    className="country-flag"
                                    style={{ width: '26px', marginRight: '10px' }}
                                  />
                                  +{selectedCountry.CallingCode}
                                </>
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {Country ? (
                                <>
                                  {Country.length !== 0 ? (
                                    <>
                                      {Country.map((item) => (
                                        <Dropdown.Item
                                          key={item.countryName}
                                          name={item.countryName}
                                          value={item.countryId}
                                          onClick={() => handleCountryselect(item)}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_BASE_URL}${item.CountryFlag}`}
                                            alt={item.countryName}
                                            className="country-flag"
                                            style={{ width: '20px', marginRight: '10px' }}
                                          />
                                          {item.countryName} (+{item.CallingCode})
                                        </Dropdown.Item>
                                      ))}
                                    </>
                                  ) : (
                                    <h4 style={{ textAlign: "center" }}>No data</h4>
                                  )}
                                </>
                              ) : (
                                <>
                                  {renderLoading("country") && (
                                    <h5 style={{ textAlign: "center" }}>No data</h5>
                                  )}
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                          <Form.Control
                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            className='textfields'
                            required
                            type="phone"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            aria-describedby="inputGroupPrepend"
                            maxLength={selectedCountry?.MobileNumberlen}
                            name='Phone'
                            placeholder="Phone number"
                            value={ContactData?.Phone}
                            onChange={handleChange} />
                 <Form.Control.Feedback type="invalid">
                          Please provide Phone Number .
                        </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                </Col>

                <Col xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2 '>
                  <Form.Group controlId="floatingTextarea">
                    <Form.Control
                      className='contact-textfields'
                      required
                      type="email"
                      maxLength="100"
                      name='Email' placeholder="Email"
                      value={ContactData?.Email} onChange={handleChange} />
                      <Form.Control.Feedback type="invalid">
                          Please provide Email Id.
                        </Form.Control.Feedback>
                  </Form.Group>

                </Col>



                <Col xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2 '>
                  <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                    <Form.Control
                      className='contact-textfields'
                      required
                      type="text"
                      name='CompanyName'
                      maxLength="120"
                      placeholder="Company Name"
                      value={ContactData?.CompanyName}
                      onChange={handleChange} />
                      <Form.Control.Feedback type="invalid">
                          Please provide Company Name.
                        </Form.Control.Feedback>
                  </Form.Group>

                </Col>

                <Col xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2  '>
                  <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                   
                    <Form.Select
                      className='contact-textfields'
                      required
                      name='CategoryName'
                      placeholder="Category Name"
                      value={ContactData?.CategoryName}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>Category</option>
                      <option value="GeneralInquiry">General Inquiry</option>
                      <option value="Support">Support</option>
                      <option value="Pricing">Pricing</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                          Please Select Category Name .
                        </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={12} lg={12} xl={12} sm={12} className=' pt-2  '>
                  <Form.Group className='contactform-textgroup' controlId="floatingTextarea">
                    <Form.Select
                      className='contact-textfields'
                      required
                      name="Sources"
                      value={Sourcesselect}
                      onChange={handleSources}
                      placeholder="How did you hear about us ?"
                    >
                      <option value="" disabled selected>Source</option>
                      {Sources ? (
                        <>
                          {Sources.length !== 0 ? (
                            <>
                              {Sources?.map((item) => (
                                <option
                                  key={item.SourceId}
                                  // eventKey={item.SourcesName}
                                  name={item.SourceName}
                                  value={item.SourceId}
                                >
                                  {item.SourceName}
                                </option>
                              ))}
                            </>
                          ) : (
                            <span style={{ textAlign: "center" }}>No data</span>
                          )}
                        </>
                      ) : (
                        <>
                          {renderLoading("sources") && (
                            <span style={{ textAlign: "center" }}>No data</span>
                          )}
                        </>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                          Please Select Source .
                        </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={12} lg={12} xl={12} sm={12} className='pt-2  '>
                  <Form.Group className='contactform-textgroup'>
                    <Form.Control
                      className='contact-textfields'
                      as="textarea"
                      required type='text'
                      name='Suggestions'
                      maxLength="250"
                      style={{ height: '150px' }}
                      placeholder="Your Feedback / Suggestions"
                      value={ContactData?.Suggestions}
                      onChange={handleChange} />
                       <Form.Control.Feedback type="invalid">
                          Please Add Suggestions .
                        </Form.Control.Feedback>
                  </Form.Group>
                </Col>


                <div className='  text-center pt-5 pb-4'>
                  {!issubmit && <Button type='submit' className='button submit-color mt-4 mb-4'>SUBMIT</Button>}
                  {issubmit &&
                    <Button className='button button-disable d-flex justify-content-center align-items-center mt-4 mb-4' >
                      <span className='me-2'> Submitting....</span>
                      <Spinner animation="border" variant="light" size='sm' />
                    </Button>}
                </div>

              </Form>
              </Col>
            </div>
          </Col>
        </Row>

      </Container>

      <div className='empty-div p-5' > </div>


    </div>
  )
}

export default FormContact