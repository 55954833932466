import React, { useEffect, useState } from 'react'
import { Col, Form, Button, Row, Spinner } from 'react-bootstrap'
import { getSources } from '../../../api/SourcesApi'
import { useSignalAbort } from '../../../api/useSignalAbort'
import dayjs from 'dayjs'
import { changeFormat, successToast } from '../../../HelperFile'
import { handleApiErrors } from '../../../handleApiErrors'
import { validateForm } from '../../../handleValidation'
import { createBlog, getBlogById, updateBlog } from '../../api/blogApi'
import UploadImage from './UploadImage'
import RichTextEditor from './RichTextEditor'

const initialValues = {
  Title: '',
  AuthorName: '',
}

const validationRules = {
  Title: { required: true },
  Content: { required: true },
  AuthorName: { required: true }
};

function BlogForm({ id, isEdit, closeModal, getData }) {
  const abortConstants = useSignalAbort()
  const [blogData, setBlogData] = useState(initialValues)
  const [blogImage, setBlogImage] = useState();
  const [blogImageBase64, setBlogImageBase64] = useState("");
  const [authorPhoto, setAuthorPhoto] = useState();
  const [authorPhotoBase64, setAuthorPhotoBase64] = useState("");
  const [content, setContent] = useState('')
  const [formErrors, setFormerrors] = useState({})
  const [validated, setValidated] = useState(false);
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState({})

  useEffect(() => {
    if (isEdit) {
      const getBlog = async () => {
        try {
          const result = await getBlogById(id, abortConstants.controllerConfig)
          const blog = result?.data?.Blogs
          console.log(blog);
          setBlogData(blog)
          setContent(decodeURIComponent(blog?.Content))
          setBlogImage(blog?.Image ? `${process.env.REACT_APP_BASE_URL}${blog?.Image}` : "")
          setAuthorPhoto(blog?.AuthorImage ? `${process.env.REACT_APP_BASE_URL}${blog?.AuthorImage}` : "")
        }
        catch (error) {
          handleApiErrors(error, 'Get Blog', 'getBlog_response', 'getBlog_timeout', 'getBlog_request', setIsLoading)
        }
      }
      getBlog()
    }
  }, [id])

  //for creating blog
  const createBlogs = async (data, abortToken) => {
    console.log(data);
    try {
      const result = await createBlog(data, abortToken)
      if (result) {
        setIsSaving(false)
        successToast(result?.data?.message)
        closeModal()
        getData()
      }
    }
    catch (error) {
      setIsSaving(false)
      handleApiErrors(error, 'Add Blog', 'addblog_response', 'addblog_timeout', 'addblog_request', setIsLoading)
    }
  }

  //for updating blog
  const updateBlogs = async (id, data, abortToken) => {
    console.log(data);
    try {
      const result = await updateBlog(id, data, abortToken)
      console.log(result);
      if (result) {
        setIsSaving(false)
        successToast(result?.data?.message)
        closeModal()
        getData()
      }
    }
    catch (error) {
      setIsSaving(false)
      handleApiErrors(error, 'Update Blog', 'editblog_response', 'editblog_timeout', 'editblog_request', setIsLoading)
    }
  }

  //For form validation
  const validate = (values) => {
    const errors = validateForm(values, validationRules);
    console.log(errors);
    setFormerrors(errors)
    if (Object.keys(errors).length === 0) {
      return true;
    }
    else {
      setIsSaving(false)
      return false;
    }
  }

  //form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSaving(true)
    setFormerrors({})
    console.log(blogData);
    const data = {
      ...blogData,
      Content: encodeURIComponent(content),
      ...(blogImageBase64 && { Image: blogImageBase64 }),
      ...(authorPhotoBase64 && { AuthorImage: authorPhotoBase64 }),
    };
    console.log(data);
    if (validate(data)) {
      if (isEdit) {
        updateBlogs(id, data, abortConstants.controllerConfig)
      } else {
        createBlogs(data, abortConstants.controllerConfig)
      }
    }
    setValidated(true)
  }

  //To set input values for form
  const handleChange = (e) => {
    // setFormerrors({})
    setBlogData({ ...blogData, [e.target.name]: e.target.value },)
  }

  const handleRichTextbox = (value) => {
    // console.log(encodeURIComponent(value));
    setContent(value)
  }

  return (
    <div >
      <Form noValidate validated={validated} className='form leadform' onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group controlId='title'>
              <Form.Label className='text-left label form-label' >Title<span className='asterik'> *</span></Form.Label>
              <Form.Control maxLength={100} required size='sm' type='type' name='Title' value={blogData?.Title} onChange={handleChange} />
              <Form.Control.Feedback type="invalid">{formErrors?.Name}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId='blogImage'>
              <Form.Label className='text-left label form-label' >Blog Image<span className='asterik'> *</span></Form.Label>
              <UploadImage selectedImage={blogImage} setSelectedImage={setBlogImage} setSelectedImageBase64={setBlogImageBase64} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId='author'>
              <Form.Label className='text-left label form-label' >Author Name<span className='asterik'> *</span></Form.Label>
              <Form.Control maxLength={100} required size='sm' type='type' name='AuthorName' value={blogData?.AuthorName} onChange={handleChange} />
              <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId='authorImage'>
              <Form.Label className='text-left label form-label' >Author Photo<span className='asterik'> *</span></Form.Label>
              <UploadImage selectedImage={authorPhoto} setSelectedImage={setAuthorPhoto} setSelectedImageBase64={setAuthorPhotoBase64} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId='content'>
              <Form.Label className='text-left label form-label' >Content<span className='asterik'> *</span></Form.Label>
              <RichTextEditor value={content} handleRichTextbox={handleRichTextbox} />
            </Form.Group>
          </Col>
        </Row>
        <div className='mt-3 d-flex justify-content-end' >
          <Button
            type={!isSaving ? 'submit' : null}
            className={`${isSaving ? 'button-loading disable' : 'button save'}`}
          // disabled={isSaving}
          >
            {isSaving ? (
              <div className="d-flex justify-content-center align-items-center">
                <span className='me-2'>Saving...</span>
                <Spinner animation="border" variant="light" size='sm' />
              </div>
            ) : (
              "Submit"
            )}
          </Button>
          <Button className='button save mx-2' onClick={() => closeModal(false)}>
            Cancel
          </Button>
        </div>

      </Form>
    </div>
  )
}

export default BlogForm