import React, { useEffect, useState } from 'react'
import { Table, Row, Col, Spinner, Pagination, Badge } from 'react-bootstrap';
import { getContacts } from '../../api/contactApi';
import { useSignalAbort } from '../../../api/useSignalAbort';
import { handleApiErrors } from '../../../handleApiErrors';
import { getCustomers } from '../../api/customerApi';
import { convertDateForDisplay } from '../../../HelperFile';
import CrudActions from '../../components/CrudActions';
import DetailForm from './DetailForm';
import CustomerForm from './CustomerForm';
import Filter from '../../components/Filter';

const initialFilterValues = {
    Name: '',
    Status: '',
    Date: '',
}

function Customer() {
    const abortConstants = useSignalAbort()
    const [customers, setCustomers] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const customerPerPage = 13; // Adjust this number to set how many customer you want per page
    const [filters, setFilters] = useState(initialFilterValues);

    //function to get all contacts
    const getAllCustomers = async () => {
        try {
            const response = await getCustomers(abortConstants.controllerConfig)
            const updatedData = response?.data?.Customer.sort((a,b)=> new Date(b.CreatedDate) - new Date(a.CreatedDate))
            setCustomers(updatedData)
            setIsLoading(false)
            console.log(response);
        }
        catch (error) {
            setIsLoading(false)
            handleApiErrors(error, 'Customer', 'customer_response', 'customer_timeout', 'customer_request', setIsLoading)
        }
    }

    //filtering function
    const handleFilterChange = (e, columnName) => {
        setFilters({
            ...filters,
            [columnName]: e.target.value
        });
    }
    const clearFilter = (columnName) => {
        setFilters({
            ...filters,
            [columnName]: ''
        });
    };

    const getFilteredCustomer = () => {
        return customers?.filter(customer => {
            return (
                (filters.Name === '' || customer.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
                (filters.Date === '' || convertDateForDisplay(customer.CreatedDate) === convertDateForDisplay(filters.Date)) &&
                (filters.Status === '' || customer.SubscriptionStatus.toLowerCase().includes(filters.Status.toLowerCase()))
            );
        });
    };

    // Get current feedbacks
    const indexOfLastCustomer = currentPage * customerPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customerPerPage;
    const currentCustomer = getFilteredCustomer()?.slice(indexOfFirstCustomer, indexOfLastCustomer);
    // const currentLeads = getFilteredLeads().slice(indexOfFirstLead, indexOfLastLead);

    // Change page
    const nextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(customers?.length / customerPerPage)));
    };

    const prevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    useEffect(() => {
        getAllCustomers()
    }, [])

    //create badge for subscription status
    const getBadge = (value) => {
        let color
        if (value === 'Free') {
            color = 'warning'
        } else if (value === 'Expired') {
            color = 'danger'
        } else if (value === 'Subscribed') {
            color = 'primary'
        }
        return <Badge pill bg={color}>{value}</Badge>
    }
    //for loading circular progress
    const renderLoading = (item) => {
        if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`]) {
            return true
        }
        else {
            return false
        }
    }


    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h5>Customers</h5>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    {isLoading ? (
                        <div className="d-flex justify-content-center mt-5">
                            <Spinner animation="border" />
                        </div>
                    ) : customers?.length > 0 ? (
                        <>
                            <Table striped bordered hover>
                                <thead>
                                    <tr >
                                        <th className='table-style table-heading'>Name
                                            <Filter columnName="Name" handleFilterChange={handleFilterChange} />
                                        </th>
                                        <th className='table-style table-heading'>Phone</th>
                                        <th className='table-style table-heading'>Email</th>
                                        <th className='table-style table-heading'>Company Name</th>
                                        <th className='table-style table-heading'>Industry</th>
                                        <th className='table-style table-heading'>Subdomain</th>
                                        <th className='table-style table-heading'>Subscription Status
                                            <Filter columnName="Status" handleFilterChange={handleFilterChange} />
                                        </th>
                                        <th className='table-style table-heading'>CreatedDate
                                            <Filter columnName="Date" handleFilterChange={handleFilterChange} />
                                        </th>
                                        <th className='table-style table-heading'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentCustomer?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='table-style table-font'>{item.Name}</td>
                                            <td className='table-style table-font'>{item.PhoneNumber}</td>
                                            <td className='table-style table-font'>{item.EmailId}</td>
                                            <td className='table-style table-font'>{item.CompanyName}</td>
                                            <td className='table-style table-font'>{item.IndustryName}</td>
                                            <td className='table-style table-font'>{item.SubDomain}</td>
                                            <td className='table-style table-font'>{getBadge(item.SubscriptionStatus)}</td>
                                            <td className='table-style table-font'>{convertDateForDisplay(item.CreatedDate)}</td>
                                            <td className="table-style table-font">
                                                <CrudActions id={item.CustomerId} crudNo={2} form={CustomerForm} title="Edit Customer" isEdit={true} getData={getAllCustomers} />
                                                <CrudActions id={item.CustomerId} crudNo={4} form={DetailForm} title="Details" isEdit={true} getData={getAllCustomers} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                                <tbody>

                                </tbody>
                            </Table>
                            <Pagination className="justify-content-end">
                                <Pagination.Prev onClick={prevPage} disabled={currentPage === 1} />
                                <Pagination.Item>{currentPage}</Pagination.Item>
                                <Pagination.Next onClick={nextPage} disabled={currentPage === Math.ceil(customers?.length / customerPerPage)} />
                            </Pagination>
                        </>
                    ) : (
                        <h4 style={{ textAlign: 'center' }}>No data</h4>
                    )}
                </Col>
            </Row>
            {/* <Row className='mt-3'>
                <Col xs={12}>
                    {customers ?
                        <>
                            {customers.length !== 0 ?
                                <Table striped bordered hover>
                                    <thead>
                                        <tr >
                                            <th className='table-style table-heading'>Name</th>
                                            <th className='table-style table-heading'>Phone</th>
                                            <th className='table-style table-heading'>Email</th>
                                            <th className='table-style table-heading'>Company Name</th>
                                            <th className='table-style table-heading'>Industry</th>
                                            <th className='table-style table-heading'>Subdomain</th>
                                            <th className='table-style table-heading'>CreatedDate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customers?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='table-style table-font'>{item.Name}</td>
                                                <td className='table-style table-font'>{item.PhoneNumber}</td>
                                                <td className='table-style table-font'>{item.EmailId}</td>
                                                <td className='table-style table-font'>{item.CompanyName}</td>
                                                <td className='table-style table-font'>{item.IndustryName}</td>
                                                <td className='table-style table-font'>{item.SubDomain}</td>
                                                <td className='table-style table-font'>{(new Date(item.CreatedDate)).toISOString().split('T')[0]}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    <tbody>

                                    </tbody>
                                </Table>
                                :
                                <h4 style={{ textAlign: 'center' }}>No data</h4>
                            }
                        </>

                        :
                        <></>}

                </Col>
            </Row> */}
        </div >
    )
}

export default Customer