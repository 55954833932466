import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import {Col} from 'react-bootstrap';
import OurBlogs from './OurBlogs';
 
function Blog() {
  return (
    <>
      <div className='Horizondal-Divider'>
        <Header />
      </div>
 
      <div>
        <Col xs={12} sm={12} md={12} lg={12} className="headline home-colC">
          <div className="mb-5 mt-5">
            <span className="heading-black">
              RCOR <span className="heading-blue">Blogs</span>
            </span>
          </div>
        </Col>
       </div>
       
      <div>
        <OurBlogs/>
      </div>
      <div >
        <Footer />
      </div>
    </>
  );
}
 
export default Blog;