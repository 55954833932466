import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import { HiUsers } from "react-icons/hi";
import { AiTwotoneContainer } from "react-icons/ai";
import { MdMessage } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { IoIosContacts } from "react-icons/io";
import { MdFeedback } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu } from '../api/authApi';
import { setMenu } from '../../redux/slices/authSlice';
import { useSignalAbort } from '../../api/useSignalAbort';
import { handleApiErrors } from '../../handleApiErrors';

function SideMenu() {
    const abortConstants = useSignalAbort()
    const token = useSelector(state => state?.auth?.adminToken)
    const localToken = localStorage.getItem("intelliger_admin_token")
    const user = useSelector(state => state?.auth?.adminUser)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [mainMenu, setMainMenu] = useState()
    const [subMenu, setSubMenu] = useState()
    const [menuArray, setMenuArray] = useState()
    const [isLoading, setIsLoading] = useState({});

    const getAllMenu = async (userTypeId, userToken, abortToken) => {
        try {
            const response = await getMenu(userTypeId, userToken, abortToken)
            if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
                setMenuArray(response.data.SubMenu)
                dispatch(setMenu({ userMenu: response.data.SubMenu }))
            }
        }
        catch (error) {
            if (error?.response?.status === 403) {
                console.log(error);
                window.location.href = '/logout';
            }
            else{
                handleApiErrors(error, 'Submenu', 'menu_response', 'menu_timeout', 'menu_request', setIsLoading)
            }
        }
    }

    useEffect(() => {
        if (localToken) {
            getAllMenu(user?.usertypeid, localToken, abortConstants.controllerConfig)
        }
    }, [localToken])

    useEffect(() => {
        setMainMenu([...new Map(menuArray?.map(item =>
            [item['menu_id'], item])).values()].sort((a, b) => a.menu_order - b.menu_order));
        setSubMenu([...new Map(menuArray?.map(item =>
            [item['subMenu_id'], item])).values()].sort((a, b) => a.subMenu_order - b.subMenu_order));
        // menuArray?.forEach(item => {
        //     const stateName = "isOpenMenu" + item.menu_id;
        //     initialMenuStates[stateName] = true;
        // });
    }, [menuArray])

    return (
        <Nav defaultActiveKey="/home" className="flex-column side-menu px-3">
            {mainMenu?.map((menu) => (
                <React.Fragment key={menu.menu_id}>
                    <div key={menu.menu_id}>{menu.menu_name}</div>
                    {subMenu?.filter(element => element.menu_id === menu.menu_id).sort((a, b) => a.subMenu_order - b.subMenu_order).map((submenu, index) => (
                        <Nav.Link key={index} onClick={() => navigate(`/${submenu.subMenu_route}`)}>{submenu.subMenu_name}</Nav.Link>
                    ))}
                </React.Fragment>
            ))}
        </Nav>
    )
}

export default SideMenu